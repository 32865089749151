import { useDebounce } from "@react-hook/debounce";
import { HeaderRootComp } from "ag-grid-community";
import gql from "graphql-tag";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { Tooltip } from "react-tippy";
import { useGameVersions } from "../context/game-versions-context";
import { cloudinaryUrl } from "../services/common";
import AccordionBodyContent from "./common/AcordionBodyContent";
import GameVersionTile from "./common/GameVersionTile";
import TwwAccordionTitle from "./common/TwwAccordionTitle";
import GenericButton from "./generic-button";

const MIN_VERSION_SEARCH_LENGTH = 2;

const sortFactions = (a, b) => {
  if (a.name_group === b.name_group) {
    return 0;
  } else {
    return a.name_group > b.name_group ? 1 : -1;
  }
};

const isMainFaction = (faction) =>
  faction.key.includes("mp_custom_battles_only") ||
  faction.key === faction.subculture.subculture.replace("sc_", "");

const FactionsGroupTilesTWW2 = ({ factions, version, onClick, selected }) => {
  let groups = _.groupBy(factions, (f) => f.subculture.name);

  return _.map(groups, (g, k) => {
    const groupName = g[0].subculture.name;
    return (
      <Fragment key={k}>
        <h3
          style={{ margin: "16px 0px 4px", width: "100%", textAlign: "center" }}
        >
          {groupName}
        </h3>
        <div className="flex-row">
          {g
            .sort((a, b) => (isMainFaction(a) ? -1 : isMainFaction(b) ? 1 : 0))
            .map((f) => (
              <FactionTile
                key={f.key}
                version={version}
                faction={f}
                onClick={(_) => onClick(f)}
                selected={selected && selected.key === f.key}
                isMain={isMainFaction(f)}
              />
            ))}
        </div>
      </Fragment>
    );
  });
};

const FactionsGroupTilesTWW3 = ({ factions, version, onClick, selected }) => (
  <div className="flex-row">
    {factions.map((f) => (
      <FactionTile
        key={f.key}
        version={version}
        faction={f}
        onClick={(_) => onClick(f)}
        selected={selected && selected.key === f.key}
        isMain={isMainFaction(f)}
      />
    ))}
  </div>
);

const FactionTile = ({ version, faction, onClick, selected, isMain }) => {
  const hoverFrameUrl = selected
    ? `${cloudinaryUrl([
        "h_128",
        "w_128",
      ])}/api/${version}/ui/skins/default/build_city_chain_frame.webp`
    : `${cloudinaryUrl([
        "h_128",
        "w_128",
      ])}/api/${version}/ui/skins/default/build_city_chain_frame.webp`;

  const backFrameUrl = selected
    ? `${cloudinaryUrl([
        "h_128",
        "w_128",
      ])}/api/${version}/ui/skins/default/building_frame_plain.webp`
    : `${cloudinaryUrl([
        "h_128",
        "w_128",
      ])}/api/${version}/ui/skins/default/fe_frame_glow.webp`;

  return (
    <div
      style={{
        height: "128px",
        width: "128px",
        textAlign: "center",
        position: "relative",
        cursor: "pointer",
        justifyContent: "flex-start",
      }}
      className="flex-column selectable_tile"
      onClick={onClick}
    >
      {backFrameUrl && (
        <img
          alt={"selectable_tile_background"}
          src={backFrameUrl}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            height: "128px",
            width: "128px",
          }}
        />
      )}
      {hoverFrameUrl && (
        <img
          className="selectable_tile_hover"
          alt={"selectable_tile_hover"}
          src={hoverFrameUrl}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            height: "128px",
            width: "128px",
          }}
        />
      )}
      <img
        alt={`${faction.screen_name}`}
        style={{ position: "relative", marginTop: "16px" }}
        src={`${cloudinaryUrl([
          "w_64",
          "f_auto",
          "q_auto:low",
        ])}/api/${version}/${faction.flags_url}/mon_64.webp`}
      />
      <p
        style={{
          position: "relative",
          margin: "4px 8px",
          fontWeight: isMain && version.game === "TWW2" ? "600" : "default",
        }}
      >
        {version.game === "TWW2"
          ? faction.screen_name
          : faction.subculture.name}
      </p>
    </div>
  );
};

const UnitsGroupsTiles = ({ units, version, onClick, selected }) => {
  const getUnitGroup = (u) =>
    u.custom_battle_permissions[0]?.general_unit
      ? "Lords"
      : u.custom_battle_permissions[0]?.campaign_exclusive
      ? "Extended Roster"
      : u.ui_unit_group?.parent_group.onscreen_name;

  let groups = _.groupBy(units, (u) => getUnitGroup(u));
  groups = _.sortBy(groups, [
    (o) =>
      o[0].custom_battle_permissions[0]?.general_unit
        ? 1
        : o[0].custom_battle_permissions[0]?.campaign_exclusive
        ? 9999
        : o[0].ui_unit_group?.parent_group.order,
  ]);

  return _.map(groups, (g, k) => {
    const groupName = getUnitGroup(g[0]);
    // const groupName = g[0].caste;
    return (
      <Fragment key={k}>
        <h3
          style={{ margin: "16px 0px 4px", width: "100%", textAlign: "center" }}
        >
          {groupName}
        </h3>
        <div className="flex-row">
          {/* {_.sortBy(g, [u => u.tier, u => u.ror, u => u.multiplayer_cost]).map(u => */}
          {_.sortBy(g, [(u) => u.multiplayer_cost]).map((u) => (
            <UnitTile
              key={u.unit}
              unit={u}
              version={version}
              selected={selected.indexOf(u.unit) !== -1}
              onClick={(_) => onClick(u)}
            />
          ))}
        </div>
      </Fragment>
    );
  });
};

const UnitTile = ({ version, unit, onClick, selected }) => {
  const unit_card =
    unit.custom_battle_permissions[0]?.general_portrait
      .toLowerCase()
      .replace("portholes", "units")
      .replace(".png", ".webp") ||
    `ui/units/icons/${unit.land_unit.variant?.unit_card_url || unit.unit}.webp`;
  const hoverFrameUrl = selected
    ? `${cloudinaryUrl()}/api/${version}/ui/skins/default/unit_card_selected_hover.webp`
    : `${cloudinaryUrl()}/api/${version}/ui/skins/default/unit_card_hover.webp`;

  const isCat = (unit, cat) =>
    unit.unit_sets.some((s) => s.special_category === cat);

  let categoryHolder = `${cloudinaryUrl()}/api/${version}/ui/skins/default/unit_card_semicircle.webp`;
  if (
    unit.caste.toLowerCase() === "hero" ||
    unit.caste.toLowerCase() === "lord"
  ) {
    categoryHolder = `${cloudinaryUrl()}/api/${version}/ui/skins/default/unit_card_semicircle_hero.webp`;
  }
  if (isCat(unit, "renown")) {
    categoryHolder = `${cloudinaryUrl()}/api/${version}/ui/skins/default/unit_card_semicircle_renown.webp`;
  }
  if (isCat(unit, "elector_counts")) {
    categoryHolder = `${cloudinaryUrl()}/api/${version}/ui/skins/default/unit_card_semicircle_elector.webp`;
  }
  if (isCat(unit, "tech_lab")) {
    categoryHolder = `${cloudinaryUrl()}/api/${version}/ui/skins/default/unit_card_semicircle_tech_lab.webp`;
  }
  if (isCat(unit, "crafted")) {
    categoryHolder = `${cloudinaryUrl()}/api/${version}/ui/skins/default/unit_card_semicircle_crafted.webp`;
  }
  if (isCat(unit, "blessed_spawning")) {
    categoryHolder = `${cloudinaryUrl()}/api/${version}/ui/skins/default/unit_card_semicircle_spawning.webp`;
  }

  const catIcon =
    unit.ui_unit_group &&
    `${cloudinaryUrl()}/api/${version}/ui/common ui/unit_category_icons/${
      unit.ui_unit_group.icon
    }.webp`;

  return (
    <Tooltip
      position="top"
      arrow="true"
      html={
        <div style={{ fontFamily: '"Open Sans", sans-serif' }}>
          <p style={{ fontWeight: "bold" }}>
            {unit.land_unit.onscreen_name +
              (unit.mount_name ? ` on ${unit.mount_name}` : "")}
          </p>
        </div>
      }
    >
      <div
        className="selectable_tile"
        style={{
          height: "130px",
          width: "60px",
          textAlign: "center",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <img
          height={130}
          alt={`${unit.land_unit.onscreen_name}`}
          src={`${cloudinaryUrl()}/api/${version}/${unit_card.replace(
            ".png",
            ".webp"
          )}`}
        />
        <img
          alt={"selectable_tile_overlay"}
          src={`${cloudinaryUrl()}/api/${version}/ui/skins/default/unit_card_frame_plain.webp`}
          style={{ position: "absolute", top: "0", left: "0" }}
        />
        <img
          alt={"unit_tile_category"}
          src={categoryHolder}
          style={{ position: "absolute", bottom: "0", left: "2px" }}
        />
        <img
          alt={"unit_tile_category_icon"}
          src={catIcon}
          style={{ position: "absolute", bottom: "0", left: "19px" }}
        />
        {selected ? (
          <img
            alt={"selectable_tile_selected"}
            src={`${cloudinaryUrl()}/api/${version}/ui/skins/default/unit_card_selected.webp`}
            style={{ position: "absolute", top: "0", left: "0" }}
          />
        ) : null}
        <img
          className="selectable_tile_hover"
          alt={"selectable_tile_hover"}
          src={hoverFrameUrl}
          style={{ position: "absolute", top: "0", left: "0" }}
        />
      </div>
    </Tooltip>
  );
};

const UnitFinder = ({ onConfirm, initialVersion, initialFaction }) => {
  const { versions } = useGameVersions();

  const [version, setVersion] = useState(null);
  const [faction, setFaction] = useState(null);
  const [units, setUnits] = useState(null);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [expanded, setExpanded] = useState(
    initialFaction ? 2 : initialVersion ? 1 : 0
  ); // 0 = versions, 1 = factions, 2 = units
  const [versionUnitsSearch, setVersionUnitsSearch] = useDebounce("", 400);
  const [factionUnitsSearch, setFactionUnitsSearch] = useDebounce("", 100);

  useEffect(() => {
    if (initialVersion && initialVersion != version?.id) {
      const versionObject = versions.find((v) => v.id === initialVersion);
      selectVersion(versionObject);
    }

    if (version && initialFaction && initialFaction.key != faction?.key) {
      selectFaction(initialFaction);
    }
  }, [initialVersion, initialFaction, version]);

  const [
    loadFactions,
    {
      loading: factionsLoading,
      data: factionsData,
      refetch: refetchFactions,
      called: factionsCalled,
    },
  ] = useLazyQuery(factionsQuery);
  const [
    loadFactionUnits,
    {
      loading: factionUnitsLoading,
      data: factionUnitsData,
      refetch: refetchFactionUnits,
      called: factionUnitsCalled,
    },
  ] = useLazyQuery(factionUnitsQuery);
  const [
    loadVersionUnits,
    {
      loading: versionUnitsLoading,
      data: versionUnitsData,
      refetch: refetchVersionUnits,
      called: versionUnitsCalled,
    },
  ] = useLazyQuery(versionUnitsQuery);

  const selectVersion = (newVersion) => {
    setVersion(newVersion);
    setFaction(null);
    setUnits(null);
    setSelectedUnits([]);
    setExpanded(1);
    const variables = { tww_version: newVersion.id };
    if (!factionsCalled) {
      loadFactions({ variables });
    } else {
      refetchFactions(variables);
    }
  };

  const selectFaction = (newFaction) => {
    setFaction(newFaction);
    setUnits(null);
    setSelectedUnits([]);
    setExpanded(2);
    const variables = {
      tww_version: version.id,
      faction: newFaction.key,
    };
    if (!factionUnitsCalled) {
      loadFactionUnits({ variables });
    } else {
      refetchFactionUnits(variables);
    }
  };

  const selectUnit = (unit) => {
    setSelectedUnits((prev) => {
      const match = prev.find((u) => u.unit === unit.unit);
      if (match) {
        return prev.filter((u) => u.unit !== unit.unit);
      } else {
        return [...prev, unit];
      }
    });
  };

  const addSelectedUnits = (close, e) => {
    onConfirm(version.id, selectedUnits, close, e);
    setSelectedUnits([]);
  };

  useEffect(() => {
    // 3 is pretty arbitrary but it's to ensure we don't request all the units by typing just a letter
    if (
      version &&
      versionUnitsSearch &&
      versionUnitsSearch.length >= MIN_VERSION_SEARCH_LENGTH
    ) {
      const variables = {
        tww_version: version.id,
        query: versionUnitsSearch,
      };
      if (!versionUnitsCalled) {
        loadVersionUnits({ variables });
      } else {
        refetchVersionUnits(variables);
      }
    }
  }, [
    versionUnitsSearch,
    loadVersionUnits,
    refetchVersionUnits,
    versionUnitsCalled,
    version,
  ]);

  useEffect(() => {
    // Clear units if user clears the search
    if (!versionUnitsSearch) {
      setUnits(null);
    }
  }, [versionUnitsSearch]);

  useEffect(() => {
    if (versionUnitsSearch && !versionUnitsLoading && versionUnitsData) {
      setUnits(versionUnitsData.tww.units);
    }
  }, [versionUnitsSearch, versionUnitsLoading, versionUnitsData]);

  useEffect(() => {
    if (faction && !factionUnitsLoading && factionUnitsData) {
      setUnits(factionUnitsData.tww.faction.units);
    }
  }, [faction, factionUnitsLoading, factionUnitsData]);

  useEffect(() => {
    if (initialVersion || initialFaction) {
      return;
    }

    switch (expanded) {
      case 0: // Versions
        setVersion(null);
        setFaction(null);
        setVersionUnitsSearch("");
        setFactionUnitsSearch("");
        setUnits(null);
        break;
      case 1: // Factions
        setFaction(null);
        setVersionUnitsSearch("");
        setFactionUnitsSearch("");
        setUnits(null);
        break;
      case 2: // Units
        setVersionUnitsSearch("");
        break;
      default:
        break;
    }
  }, [expanded, setVersionUnitsSearch, setFactionUnitsSearch]);

  const filteredUnits =
    units && factionUnitsSearch
      ? units.filter((u) =>
          u.land_unit.onscreen_name
            .toLowerCase()
            .includes(factionUnitsSearch.toLowerCase())
        )
      : units;

  const FactionsGroupTilesComponent =
    version?.game === "TWW2" ? FactionsGroupTilesTWW2 : FactionsGroupTilesTWW3;

  return (
    <>
      <div
        style={{
          height: "100%",
          overflow: "auto",
        }}
      >
        <div uuid={"versions"}>
          <TwwAccordionTitle
            title={version ? "Change Version" : "Select Version"}
            expanded={expanded === 0}
            accordionChanged={() => setExpanded(0)}
          />
          <div
            style={{
              display: expanded === 0 ? "block" : "none",
            }}
          >
            <h3
              style={{
                margin: "16px 0px 4px",
                width: "100%",
                textAlign: "center",
              }}
            >
              Core Game
            </h3>
            <div className="flex-row">
              {versions
                .filter((o) => /^\d+/.test(o.id))
                .map((o) => (
                  <GameVersionTile
                    key={o.id}
                    {...o}
                    onClick={(e) => selectVersion(o, e)}
                    selected={version && version.id === o.id}
                  />
                ))}
            </div>
            <h3
              style={{
                margin: "16px 0px 4px",
                width: "100%",
                textAlign: "center",
              }}
            >
              Mods WH3
            </h3>
            <div className="flex-row">
              {versions
                .filter((o) => !/^\d+/.test(o.id) && o.id.includes("_wh3"))
                .map((o) => (
                  <GameVersionTile
                    key={o.id}
                    {...o}
                    onClick={(e) => selectVersion(o, e)}
                    selected={version && version.id === o.id}
                  />
                ))}
            </div>
            <h3
              style={{
                margin: "16px 0px 4px",
                width: "100%",
                textAlign: "center",
              }}
            >
              Mods WH2
            </h3>
            <div className="flex-row">
              {versions
                .filter((o) => !/^\d+/.test(o.id) && !o.id.includes("_wh3"))
                .map((o) => (
                  <GameVersionTile
                    key={o.id}
                    {...o}
                    onClick={(e) => selectVersion(o, e)}
                    selected={version && version.id === o.id}
                  />
                ))}
            </div>
          </div>
        </div>
        {version ? (
          <div uuid={"factions"}>
            <TwwAccordionTitle
              title={faction ? "Change Faction" : "Select Faction"}
              value={`${version.game} - ${version.name}`}
              expanded={expanded === 1}
              accordionChanged={() => setExpanded(1)}
            />
            {!faction ? (
              <div className={"units-query"}>
                <input
                  className={"units-query"}
                  id={"version-units-query"}
                  type="text"
                  onChange={(event) =>
                    setVersionUnitsSearch(event.target.value)
                  }
                  placeholder="Find units by name and/or mount name..."
                />
                {versionUnitsLoading ? "Loading available units..." : null}
                {units ? (
                  <UnitsGroupsTiles
                    units={units}
                    version={version.id}
                    onClick={(unit) => selectUnit(unit)}
                    selected={selectedUnits.map((u) => u.unit)}
                  />
                ) : null}
              </div>
            ) : null}
            {!versionUnitsSearch ? (
              <div style={{ display: expanded === 1 ? "block" : "none" }}>
                {factionsLoading ? "Loading available factions..." : null}
                {!factionsLoading && factionsData ? (
                  <AccordionBodyContent className="flex-row">
                    <FactionsGroupTilesComponent
                      factions={factionsData.tww.factions
                        .slice(0)
                        .sort(sortFactions)}
                      version={version.id}
                      onClick={(f) => selectFaction(f)}
                      selected={faction}
                    />
                  </AccordionBodyContent>
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}
        {faction && (
          <div uuid={"units"}>
            <TwwAccordionTitle
              title="Select Unit(s)"
              value={faction.screen_name}
              expanded={expanded === 2}
              accordionChanged={() => setExpanded(2)}
            />
            <div style={{ display: expanded === 2 ? "block" : "none" }}>
              <div className={"units-query"}>
                <input
                  className={"units-query"}
                  id={"faction-units-query"}
                  type="text"
                  onChange={(event) =>
                    setFactionUnitsSearch(event.target.value)
                  }
                  placeholder="Filter faction's units by name..."
                />
              </div>
              {factionUnitsLoading ? "Loading available units..." : null}
              {filteredUnits ? (
                <AccordionBodyContent
                  className="flex-column"
                  style={{ paddingBottom: "80px" }}
                >
                  <UnitsGroupsTiles
                    units={filteredUnits}
                    version={version.id}
                    onClick={(unit) => selectUnit(unit)}
                    selected={selectedUnits.map((u) => u.unit)}
                  />
                </AccordionBodyContent>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {selectedUnits.length > 0 && (
        <div key="confirm_button" className="flex-row confirm-button">
          <SelectionButton>
            <GenericButton
              title={`Add ${selectedUnits.length} Selected`}
              onClick={(e) => addSelectedUnits(false, e)}
            />
          </SelectionButton>
          <SelectionButton>
            <GenericButton
              title={`Add ${selectedUnits.length} Selected and Close`}
              onClick={(e) => addSelectedUnits(true, e)}
            />
          </SelectionButton>
        </div>
      )}
    </>
  );
};

const SelectionButton = ({ children }) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: 335,
          height: 27,
          boxShadow: "0 0 12px 9px rgb(254, 255, 247)",
          position: "absolute",
          left: 15,
          top: 17,
          borderRadius: 15,
        }}
      ></div>
      {children}
    </div>
  );
};

const factionsQuery = gql`
  query FactionsQuery($tww_version: String!) {
    tww(tww_version: $tww_version) {
      tww_version
      factions(include_non_mp: false) {
        key
        subculture {
          subculture
          name
        }
        screen_name
        screen_adjective
        is_rebel
        mp_available
        flags_path
        flags_url
        name_group
      }
    }
  }
`;

const factionUnitsQuery = gql`
  query factionUnitsQuery($tww_version: String!, $faction: String!) {
    tww(tww_version: $tww_version) {
      tww_version
      faction(id: $faction) {
        units(groupHeroesAndLords: true) {
          unit
          mount_name
          tier
          caste
          multiplayer_cost
          unit_sets {
            special_category
          }
          custom_battle_permissions {
            general_portrait
            general_unit
            campaign_exclusive
          }
          land_unit {
            onscreen_name
            variant {
              unit_card_url
            }
          }
          ui_unit_group {
            icon
            key
            name
            tooltip
            parent_group {
              key
              onscreen_name
              icon
              order
            }
          }
        }
      }
    }
  }
`;

const versionUnitsQuery = gql`
  query versionUnitsQuery($tww_version: String!, $query: String) {
    tww(tww_version: $tww_version) {
      tww_version
      units(
        offset: 0
        size: 100
        includeQb: true
        includeSummoned: true
        includeBosses: true
        includeSouthenRealms: true
        includeKislev: true
        query: $query
      ) {
        unit
        mount_name
        tier
        caste
        multiplayer_cost
        unit_sets {
          special_category
        }
        custom_battle_permissions {
          general_portrait
          general_unit
        }
        land_unit {
          onscreen_name
          variant {
            unit_card_url
          }
        }
        ui_unit_group {
          icon
          key
          name
          tooltip
          parent_group {
            key
            onscreen_name
            icon
            order
          }
        }
      }
    }
  }
`;

export default UnitFinder;
