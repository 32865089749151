import gql from "graphql-tag";
import ContactPhaseFragment from "./contactphase-fragment";
import ProjectileFragment from "./projectile-fragment";
import ExplosionFragment from "./explosion-fragment";

export default gql`
  query AbilitiesQuery($tww_version: String!, $offset: Int!, $size: Int!) {
    tww(tww_version: $tww_version) {
      tww_version
      abilities(offset: $offset, size: $size) {
        key
        supercedes_ability
        requires_effect_enabling
        icon_name
        is_unit_upgrade
        is_hidden_in_ui
        name
        tooltip
        uniqueness
        overpower_option {
          icon_name
          key
          name
          tooltip
        }
        type {
          key
          icon
          onscreen_name
        }
        unit_special_ability {
          key
          active_time
          recharge_time
          num_uses
          effect_range
          affect_self
          num_effected_friendly_units
          num_effected_enemy_units
          initial_recharge
          target_friends
          target_enemies
          target_ground
          target_intercept_range
          assume_specific_behaviour
          clear_current_order
          wind_up_time
          passive
          invalid_targets
          invalid_usages
          activated_projectile {
            ...projectile
          }
          bombardment {
            arrival_window
            bombardment_key
            num_projectiles
            radius_spread
            start_time
            launch_source
            launch_height
            launch_height_underground
            projectile_type {
              ...projectile
            }
          }
          spawned_unit {
            key
            short_description_text
            onscreen_name
            variant {
              variant
              name
              unit
              unit_card_url
            }
          }
          wind_up_stance
          mana_cost
          min_range
          vortex {
            vortex_key
            change_max_angle
            damage
            damage_ap
            duration
            expansion_speed
            goal_radius
            infinite_height
            move_change_freq
            movement_speed
            start_radius
            ignition_amount
            is_magical
            detonation_force
            launch_source
            building_collision
            height_off_ground
            delay
            num_vortexs
            contact_effect {
              ...contact_phase
            }
          }
          miscast_chance
          miscast_explosion {
            ...explosion
          }
          # ai_usage
          additional_melee_cp
          additional_missile_cp
          parent_ability {
            key
          }
        }
        phases {
          ...contact_phase
        }
        special_ability_groups {
          ability_group
          icon_path
          special_edition_mask
          sort_order
          is_naval
          colour_r
          colour_g
          colour_b
          button_name
        }
      }
    }
  }
  ${ContactPhaseFragment}
  ${ProjectileFragment}
  ${ExplosionFragment}
`;
