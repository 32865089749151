import React, { Fragment } from "react";
import { additionalIcons } from "../services/common";
import TooltipDesc from "../components/tooltipdesc";
import _ from "lodash";

export default (field) => [
  {
    type: ["integerColumn"],
    headerName: "Ammunition",
    field: `${field}.ammo`,
    headerComponentParams: {
      icon: "ui/skins/default/icon_stat_ammo.webp",
    },
  },
  {
    type: ["integerColumn"],
    field: `${field}.projectile.range`,
    headerName: "Range",
    headerComponentParams: {
      icon: "ui/skins/default/icon_stat_range.webp",
    },
  },
  {
    groupId: "missile_group",
    openByDefault: localStorage.getItem("missile_group") === "true",
    headerGroupComponent: "ColumnGroupHeaderRenderer",
    children: [
      {
        headerName: "Missile Damage (avg. over 10s)",
        field: `${field}.damage`,
        headerComponentParams: {
          icon: "ui/skins/default/icon_stat_ranged_damage.webp",
        },
        cellClass: (params) => {
          const compoundStatsClass =
            params.data["delta_" + params.colDef.field] === undefined
              ? null
              : params.data["delta_" + params.colDef.field] > 0
              ? "increased"
              : params.data["delta_" + params.colDef.field] < 0
              ? "reduced"
              : null;
          let relatedStatsClass =
            params.data[`delta_${field}.base_damage`] === undefined
              ? null
              : "adjusted";
          relatedStatsClass =
            relatedStatsClass ||
            (params.data[`delta_${field}.ap_damage`] === undefined
              ? null
              : "adjusted");
          relatedStatsClass =
            relatedStatsClass ||
            (params.data[`delta_${field}.explosion.base_damage`] === undefined
              ? null
              : "adjusted");
          relatedStatsClass =
            relatedStatsClass ||
            (params.data[`delta_${field}.explosion.ap_damage`] === undefined
              ? null
              : "adjusted");
          relatedStatsClass =
            relatedStatsClass ||
            (params.data[`delta_${field}.shots_per_volley`] === undefined
              ? null
              : "adjusted");
          relatedStatsClass =
            relatedStatsClass ||
            (params.data[`delta_${field}.projectile_number`] === undefined
              ? null
              : "adjusted");
          relatedStatsClass =
            relatedStatsClass ||
            (params.data[`delta_${field}.reload_time`] === undefined
              ? null
              : "adjusted");
          return compoundStatsClass || relatedStatsClass || "";
        },
        cellRenderer: "MissileContactPhaseRenderer",
        minWidth: 88,
        width: 88,
        maxWidth: 88,
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Projectile Base Damage",
        field: `${field}.projectile.base_damage`,
        headerComponentParams: {
          icon: "ui/skins/default/icon_stat_ranged_damage.webp",
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Projectile Armour-Piercing Damage",
        field: `${field}.projectile.ap_damage`,
        headerComponentParams: {
          icon: "ui/skins/default/modifier_icon_armour_piercing_ranged.webp",
        },
      },
      {
        columnGroupShow: "open",
        type: ["integer"],
        headerName: "Projectile AP/TotalDmg Ratio",
        field: `${field}.projectile.ap_ratio`,
        headerComponentParams: {
          fa: "percent",
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Projectile Bonus vs. Infantry",
        field: `${field}.projectile.bonus_v_infantry`,
        headerComponentParams: {
          icon: "ui/skins/default/modifier_icon_bonus_vs_infantry.webp",
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Projectile Bonus vs. Large",
        field: `${field}.projectile.bonus_v_large`,
        headerComponentParams: {
          icon: "ui/skins/default/modifier_icon_bonus_vs_large.webp",
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Projectile Explosion Base Damage",
        field: `${field}.projectile.explosion.base_damage`,
        headerComponentParams: {
          icon: "ui/skins/default/icon_stat_ranged_damage.webp",
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Projectile Explosion Armour-Piercing Damage",
        field: `${field}.projectile.explosion.ap_damage`,
        headerComponentParams: {
          icon: "ui/skins/default/modifier_icon_armour_piercing_ranged.webp",
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Projectile Explosion Detonation Radius",
        field: `${field}.projectile.explosion.detonation_radius`,
        headerComponentParams: {
          fa: "street-view",
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Shots per Volley",
        valueGetter: (params) =>
          params.data[`${field}`]?.projectile.shots_per_volley,
        headerComponentParams: {
          icon: "ui/skins/default/icon_status_firing_24px.webp",
          headerTooltip: (props) => (
            <Fragment>
              <p>
                Missile units fires a Volley that contains X number of Shots
                that each contain Y number of Projectiles.
              </p>
            </Fragment>
          ),
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Projectile Number",
        field: `${field}.projectile.projectile_number`,
        headerComponentParams: {
          icon: "ui/skins/default/icon_status_firing_24px.webp",
          headerTooltip: (props) => (
            <Fragment>
              <p>
                Missile units fires a Volley that contains X number of Shots
                that each contain Y number of Projectiles.
              </p>
            </Fragment>
          ),
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Projectile Category",
        field: `${field}.projectile.category`,
        headerComponentParams: {
          icon: additionalIcons[6],
          tooltipDesc: (props) => {
            return (
              <TooltipDesc title="Projectile Category">
                <p>{props.value}</p>
                <p>
                  Some categories like artillery and misc ignore shields.
                  Artillery also has a separate morale penalty.
                </p>
              </TooltipDesc>
            );
          },
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Reload Time",
        field: `${field}.reload_time`,
        synonyms: ["reload"],
        valueFormatter: (params) =>
          params.data["delta_" + params.colDef.field] === undefined
            ? params.value + "s"
            : params.value +
              " (" +
              params.data["delta_" + params.colDef.field] +
              "s)",
        headerComponentParams: {
          icon: "ui/skins/default/icon_cooldown.webp",
        },
        maxWidth: 52,
        width: 52,
        minWidth: 52,
      },
      {
        columnGroupShow: "open",
        type: ["magicAttack"],
        headerName: "Projectile Is Magical",
        field: `${field}.projectile.is_magical`,
      },
      {
        columnGroupShow: "open",
        type: ["flamingAttack"],
        headerName: "Projectile is Flaming",
        field: `${field}.projectile.ignition_amount`,
      },
      {
        columnGroupShow: "open",
        type: ["phaseColumn"],
        headerName: "Projectile Phase",
        field: `${field}.projectile.phase`,
      },
      {
        type: ["phaseColumn"],
        columnGroupShow: "open",
        headerName: "Projectile Overhead Phase",
        field: `${field}.projectile.overhead_phase`,
      },
      {
        type: ["magicAttack"],
        columnGroupShow: "open",
        headerName: "Projectile Explosion Is Magical",
        field: `${field}.projectile.explosion.is_magical`,
      },
      {
        type: ["flamingAttack"],
        columnGroupShow: "open",
        headerName: "Projectile Explosion is Flaming",
        field: `${field}.projectile.explosion.ignition_amount`,
      },
      {
        type: ["phaseColumn"],
        columnGroupShow: "open",
        headerName: "Projectile Explosion Phase",
        field: `${field}.projectile.explosion.phase`,
      },
    ],
  },
  {
    groupId: "extra_missile_group",
    openByDefault: localStorage.getItem("extra_missile_group") === "true",
    headerGroupComponent: "ColumnGroupHeaderRenderer",
    children: [
      {
        columnGroupShow: "closed",
        headerName: "Additional Missile Damage Stats (expand to view)",
        valueGetter: (params) => null,
        headerComponentParams: {
          icon: "ui/skins/default/button_edicts_active_1.webp",
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Accuracy",
        field: "accuracy",
        headerComponentParams: {
          icon: additionalIcons[0],
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Marksmanship Bonus",
        field: `${field}.projectile.marksmanship_bonus`,
        headerComponentParams: {
          icon: additionalIcons[1],
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Total Accuracy (Accuracy + Marksmanship)",
        field: `${field}.total_accuracy`,
        headerComponentParams: {
          icon: additionalIcons[2],
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Calibration Distance",
        field: `${field}.projectile.calibration_distance`,
        headerComponentParams: {
          icon: additionalIcons[3],
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Calibration Area",
        field: `${field}.projectile.calibration_area`,
        headerComponentParams: {
          icon: additionalIcons[4],
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Projectile Collision Radius",
        field: `${field}.projectile.collision_radius`,
        headerComponentParams: {
          icon: additionalIcons[5],
          headerTooltip: (props) => (
            <p>
              I believe this refers to the size of the projectile itself,
              impacting how easy it is for the project to collide with other
              entities.
            </p>
          ),
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Projectile Penetration Entity Size Cap",
        field: `${field}.projectile.penetration_entity_size_cap`,
        headerComponentParams: {
          icon: additionalIcons[6],
          tooltipDesc: (props) => {
            return (
              <TooltipDesc title="Projectile Penetration Entity Size Cap">
                <p>
                  Size of entity that will stop this projectile immediately.
                </p>
              </TooltipDesc>
            );
          },
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Projectile Penetration Max Penetration",
        field: `${field}.projectile.penetration_max_penetration`,
        headerComponentParams: {
          icon: additionalIcons[7],
          tooltipDesc: (props) => {
            return (
              <TooltipDesc title="Projectile Penetration Max Penetration">
                <p>
                  Penetration stat. A higher number means this projectile passes
                  through more penetration points before being stopped. Check
                  the battle entities table to see how many penetration points a
                  single entity has.
                </p>
              </TooltipDesc>
            );
          },
        },
      },
      {
        columnGroupShow: "open",
        type: ["integerColumn"],
        headerName: "Projectile Burst Shot Delay",
        field: `${field}.projectile.burst_shot_delay`,
        headerComponentParams: {
          icon: additionalIcons[9],
        },
      },
    ],
  },
];
