import { default as React } from "react";
import TooltipDesc from "../components/tooltipdesc";
import { specialAbilityGroupsComparator } from "../services/twwstats-services";

const PhaseDirectDamageTooltip = (props) => (
  <TooltipDesc title={props.colDef.headerName}>
    {props.data.dominant_phase?.damage_amount ? (
      <p>
        Each entity of the affected unit(s) has a{" "}
        {props.data.dominant_phase.damage_chance * 100}% chance of taking{" "}
        {props.data.dominant_phase.damage_amount} damages every{" "}
        {props.data.dominant_phase.hp_change_frequency}s
        {props.data.dominant_phase.duration > 0
          ? ` during ${props.data.dominant_phase.duration}s (${props.data.dominant_phase.ticks} times in total)`
          : ""}
        . The check is made against every entity in the unit but a maximum of{" "}
        {props.data.dominant_phase.max_damaged_entities}{" "}
        {props.data.dominant_phase.max_damaged_entities > 1
          ? "entities"
          : "entity"}
        /unit will receive damage.
      </p>
    ) : props.data.dominant_phase?.heal_amount ? (
      <p>
        Each entity in the affected unit(s) will heal for{" "}
        {props.data.dominant_phase.heal_amount} HP every{" "}
        {props.data.dominant_phase.hp_change_frequency}s
        {props.data.dominant_phase.duration > 0
          ? ` during ${props.data.dominant_phase.duration}s (${props.data.dominant_phase.ticks} times in total)`
          : ""}
        .
      </p>
    ) : null}
  </TooltipDesc>
);

export default function createColumnDefs() {
  let colDefs = [
    {
      headerName: "Name",
      field: "name",
      pinned: "left",
      maxWidth: 150,
      width: 150,
      minWidth: 150,
    },
    {
      groupId: "ab_info",
      openByDefault: localStorage.getItem("ab_info") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          headerName: "Info",
          field: "icon_name",
          synonyms: ["icon"],
          cellRenderer: "AbilityIconRenderer",
          minWidth: 50,
          width: 50,
          maxWidth: 50,
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Supercedes Ability",
          field: "supercedes_ability",
          synonyms: ["supercedes"],
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Requires Enabling",
          field: "requires_effect_enabling",
          synonyms: ["enabling"],
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Unit Upgrade",
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Hidden in UI",
          field: "is_hidden_in_ui",
          synonyms: ["hidden"],
        },
        {
          columnGroupShow: "open",
          headerName: "Assume Specific Behaviour",
          field: "special_ability.assume_specific_behaviour",
          synonyms: ["specific_behavior"],
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Clear Current Order",
          field: "special_ability.clear_current_order",
        },
        {
          columnGroupShow: "open",
          headerName: "Wind Up Stance",
          field: "special_ability.wind_up_stance",
        },
      ],
    },
    {
      headerName: "Lore",
      field: "special_ability_groups",
      synonyms: ["school", "school_of_magic", "group", "ability_group"],
      cellRenderer: "AbilityGroupIconRenderer",
      minWidth: 84,
      width: 84,
      maxWidth: 84,
      getQuickFilterText: (params) =>
        params.value ? params.value.map((g) => g.button_name) : null,
      comparator: specialAbilityGroupsComparator,
    },
    {
      headerName: "Type",
      field: "type_key",
      cellRenderer: "AbilityTypeRenderer",
    },
    {
      type: ["booleanColumn"],
      headerName: "Passive",
      field: "special_ability.passive",
      synonyms: ["is_passive"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_reset.webp",
      },
    },
    {
      type: ["integerColumn"],
      headerName: "Area of Effect",
      field: "special_ability.effect_range",
      synonyms: ["aoe", "effect_range"],
      headerComponentParams: {
        icon: "ui/skins/default/help_page_drag.webp",
      },
    },
    {
      groupId: "ab_targets",
      openByDefault: localStorage.getItem("ab_targets") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          type: ["booleanColumn"],
          headerName: "Target",
          field: "special_ability.target",
          headerComponentParams: {
            icon: "ui/skins/default/icon_spells_valid.webp",
          },
          cellRenderer: "SpellTargetsIconsRenderer",
          minWidth: 66,
          width: 66,
          maxWidth: 66,
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Target Self",
          field: "special_ability.affect_self",
          synonyms: ["affect_self", "self"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_ability_target_self.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Target Ally",
          field: "special_ability.target_friends",
          synonyms: [
            "target_friends",
            "target_allies",
            "target_friend",
            "friend",
            "friends",
            "ally",
            "allies",
          ],
          headerComponentParams: {
            icon: "ui/skins/default/faction_icon_flag_ally.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Target Enemy",
          field: "special_ability.target_enemies",
          synonyms: ["target_enemies", "enemy", "enemies"],
          headerComponentParams: {
            icon: "ui/skins/default/faction_icon_flag_enemy.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Target Ground",
          field: "special_ability.target_ground",
          synonyms: ["ground"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_target.webp",
          },
        },
      ],
    },
    {
      groupId: "ab_info_more",
      openByDefault: localStorage.getItem("ab_info_more") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          type: ["integerColumn"],
          headerName: "Number of Affected Units",
          field: "special_ability.num_targets",
          synonyms: ["effected_units", "affected_units"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_public_order_neutral.webp",
          },
        },
        {
          type: ["integerColumn"],
          columnGroupShow: "open",
          headerName: "Number of Affected Friendly Units",
          field: "special_ability.num_effected_friendly_units",
          synonyms: [
            "effected_friendly_units",
            "affected_friendly_units",
            "effected_friends",
            "affected_friends",
          ],
          headerComponentParams: {
            icon: "ui/skins/default/icon_public_order_positive.webp",
          },
        },
        {
          type: ["integerColumn"],
          columnGroupShow: "open",
          headerName: "Number of Affected Enemy Units",
          synonyms: [
            "effected_enemy_units",
            "affected_enemy_units",
            "effected_enemy",
            "affected_enemy",
          ],
          field: "special_ability.num_effected_enemy_units",
          headerComponentParams: {
            icon: "ui/skins/default/icon_public_order_negative.webp",
          },
        },
      ],
    },
    {
      type: ["integerColumn"],
      headerName: "Mana Cost",
      field: "special_ability.mana_cost",
      synonyms: ["mana", "cost"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_mana.webp",
      },
    },
    {
      type: ["integerColumn"],
      headerName: "Casting Time",
      field: "special_ability.wind_up_time",
      synonyms: ["wind_up_time", "casting"],
      headerComponentParams: {
        // icon: 'ui/skins/default/icon_lore_spell_toggle.webp',
        icon: "ui/skins/default/wh_main_wizard_schematic.webp",
      },
    },
    // {
    //   headerName: 'overpower_option',
    //   field: 'overpower_option',
    //   valueFormatter: params => params.value && params.value.key,
    //   headerComponentParams:{
    //     icon: 'ui/skins/default/icon_spell_browser_charge2.webp'
    //   }
    // },
    {
      type: ["integerColumn"],
      headerName: "Active Time",
      field: "special_ability.active_time",
      synonyms: ["time", "duration"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_ability_stage3.webp",
        // icon: "ui/campaign%20ui/effect_bundles/turns.webp",
      },
    },
    {
      groupId: "ab_recharge_time",
      openByDefault: localStorage.getItem("ab_recharge_time") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          // Ability start the battle on recharge
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Initial Recharge",
          field: "special_ability.initial_recharge",
          headerComponentParams: {
            icon: "ui/skins/default/icon_hourglass.webp",
          },
        },
        {
          type: ["integerColumn"],
          headerName: "Recharge Time",
          field: "special_ability.recharge_time",
          synonyms: ["recharge"],
          headerComponentParams: {
            // icon: 'ui/campaign%20ui/effect_bundles/magic_cooldown.webp'
            icon: "ui/skins/default/icon_cooldown.webp",
          },
        },
      ],
    },

    {
      type: ["integerColumn"],
      headerName: "Number of Uses",
      field: "special_ability.num_uses",
      synonyms: ["num_uses", "uses"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_pop_surplus.webp",
      },
    },
    {
      headerName: "Overcast",
      field: "overcast_level",
      headerComponentParams: {
        icon: "ui/skins/default/icon_spell_browser_charge2.webp",
      },
      cellRenderer: "OvercastRenderer",
    },
    // {
    //   headerName: 'special_ability.key',
    //   field: 'special_ability.key',
    // },
    {
      groupId: "ab_range",
      openByDefault: localStorage.getItem("ab_range") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Min Casting Range",
          field: "special_ability.min_range",
          synonyms: ["min_range"],
          headerComponentParams: {
            icon: "ui/campaign%20ui/skills/icon_stat_range.webp",
          },
        },
        {
          type: ["integerColumn"],
          headerName: "Max Casting Range",
          field: "special_ability.target_intercept_range",
          synonyms: ["range", "max_range"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_range.webp",
          },
        },
      ],
    },
    // {
    //   headerName: 'special_ability.ai_usage',
    //   field: 'special_ability.ai_usage',
    // },
    // {
    //   headerName: 'special_ability.additional_melee_cp',
    //   field: 'special_ability.additional_melee_cp',
    // },
    // {
    //   headerName: 'special_ability.additional_missile_cp',
    //   field: 'special_ability.additional_missile_cp',
    // },
    {
      groupId: "ab_bombardment",
      openByDefault: localStorage.getItem("ab_bombardment") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          type: ["booleanColumn"],
          headerName: "Bombardment",
          field: "special_ability.bombardment",
          headerComponentParams: {
            icon: "ui/battle%20ui/ability_icons/icon_spell_bombardment.webp",
          },
        },
        {
          type: ["integerColumn"],
          columnGroupShow: "open",
          headerName: "Bombardment Number of Projectiles",
          field: "special_ability.bombardment.num_projectiles",
          synonyms: ["bombardment_projectiles"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_ammo.webp",
          },
        },
        {
          type: ["integerColumn"],
          columnGroupShow: "open",
          headerName: "Bombardment Arrival Window",
          field: "special_ability.bombardment.arrival_window",
          synonyms: ["arrival_window"],
          headerComponentParams: {
            icon: "ui/skins/default/unit_effect_negative.webp",
          },
        },
        // {
        //   columnGroupShow: 'open',
        //   headerName: 'bombardment_key',
        //   field: 'special_ability.bombardment.bombardment_key',
        // },

        {
          type: ["integerColumn"],
          columnGroupShow: "open",
          headerName: "Bombardment Radius Spread",
          field: "special_ability.bombardment.radius_spread",
          synonyms: ["radius_spread"],
          headerComponentParams: {
            icon: "ui/skins/default/help_page_drag.webp",
          },
        },
        {
          columnGroupShow: "open",
          groupId: "ab_bombardment_more",
          openByDefault: localStorage.getItem("ab_bombardment_more") === "true",
          headerGroupComponent: "ColumnGroupHeaderRenderer",
          children: [
            {
              type: ["integerColumn"],
              headerName: "Bombardment Start Time",
              field: "special_ability.bombardment.start_time",
            },
            {
              columnGroupShow: "open",
              headerName: "Bombardment Launch Source",
              field: "special_ability.bombardment.launch_source",
              synonyms: ["launch_source"],
              minWidth: 150,
              width: 150,
              maxWidth: 150,
            },
            {
              type: ["integerColumn"],
              columnGroupShow: "open",
              headerName: "Bombardment Launch Height",
              field: "special_ability.bombardment.launch_height",
              synonyms: ["launch_height"],
            },
            {
              type: ["integerColumn"],
              columnGroupShow: "open",
              headerName: "Bombardment Launch Height Underground",
              field: "special_ability.bombardment.launch_height_underground",
              synonyms: ["launch_height_underground"],
            },
          ],
        },
      ],
    },
    {
      groupId: "ab_projectile",
      openByDefault: localStorage.getItem("ab_projectile") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          type: ["booleanColumn"],
          headerName: "Projectile / Bombardment Projectile",
          field: "special_ability.projectile",
          synonyms: ["bombardment_projectile", "projectile"],
          headerComponentParams: {
            icon: "ui/battle%20ui/ability_icons/icon_spell_magic_missile.webp",
          },
        },
        {
          columnGroupShow: "open",
          headerName: "Projectile Range",
          field: "special_ability.projectile.range",
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_range.webp",
          },
        },
        {
          columnGroupShow: "open",
          headerName: "Projectile Base Damage",
          field: "special_ability.projectile.base_damage",
          synonyms: ["projectile_damage"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_ranged_damage.webp",
          },
        },
        {
          columnGroupShow: "open",
          headerName: "Projectile Armour-Piercing Damage",
          field: "special_ability.projectile.ap_damage",
          synonyms: ["projectile_ap"],
          headerComponentParams: {
            icon: "ui/skins/default/modifier_icon_armour_piercing_ranged.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Projectile Bonus vs. Infantry",
          field: `special_ability.projectile.bonus_v_infantry`,
          headerComponentParams: {
            icon: "ui/skins/default/modifier_icon_bonus_vs_infantry.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Projectile Bonus vs. Large",
          field: `special_ability.projectile.bonus_v_large`,
          headerComponentParams: {
            icon: "ui/skins/default/modifier_icon_bonus_vs_large.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["projectileBase"],
          headerName: "Projectile Explosion Base Damage",
          field: "special_ability.projectile.explosion.base_damage",
        },
        {
          columnGroupShow: "open",
          type: ["projectileAp"],
          headerName: "Projectile Explosion Armour-Piercing Damage",
          field: "special_ability.projectile.explosion.ap_damage",
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Projectile Explosion Detonation Radius",
          field: `special_ability.projectile.explosion.detonation_radius`,
          headerComponentParams: {
            fa: "street-view",
          },
        },
        {
          columnGroupShow: "open",
          headerName: "Projectile Shots per Volley",
          valueGetter: (params) =>
            params.data.special_ability?.projectile?.shots_per_volley,
          synonyms: ["shots_per_volley"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_status_firing_24px.webp",
          },
        },
        {
          columnGroupShow: "open",
          headerName: "Projectile Number",
          field: "special_ability.projectile.projectile_number",
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_ammo.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["magicAttack"],
          headerName: "Projectile Is Magical",
          field: "special_ability.projectile.is_magical",
        },
        {
          columnGroupShow: "open",
          type: ["flamingAttack"],
          headerName: "Projectile is Flaming",
          field: "special_ability.projectile.ignition_amount",
        },
        {
          columnGroupShow: "open",
          type: ["magicAttack"],
          headerName: "Projectile Explosion Is Magical",
          field: "special_ability.projectile.explosion.is_magical",
        },
        {
          columnGroupShow: "open",
          type: ["flamingAttack"],
          headerName: "Projectile Explosion Is Flaming",
          field: "special_ability.projectile.explosion.ignition_amount",
        },
        {
          columnGroupShow: "open",
          type: ["phaseColumn"],
          headerName: "Projectile Explosion Contact Phase",
          field: "special_ability.projectile.explosion.contact_phase_effect",
        },
        {
          columnGroupShow: "open",
          groupId: "ab_projectile_more",
          openByDefault: localStorage.getItem("ab_projectile_more") === "true",
          headerGroupComponent: "ColumnGroupHeaderRenderer",
          children: [
            // NOT USED IN DB SO FAR
            // {
            //   type: ['phaseColumn'],
            //   headerName: 'Projectile Contact Phase',
            //   field: 'special_ability.projectile.contact_stat_effect',
            // },
            // NOT USED IN DB SO FAR
            // {
            //   columnGroupShow: 'open',
            //   type: ['phaseColumn'],
            //   headerName: 'Projectile Overhead Contact Phase',
            //   field: 'special_ability.projectile.overhead_stat_effect',
            // },
            {
              headerName: "Projectile Marksmanship Bonus",
              field: "special_ability.projectile.marksmanship_bonus",
            },
            {
              columnGroupShow: "open",
              headerName: "Projectile Collision Radius",
              field: "special_ability.projectile.collision_radius",
              headerComponentParams: {
                icon: "ui/skins/default/help_page_drag.webp",
              },
            },
            {
              columnGroupShow: "open",
              headerName: "Projectile Burst Shot Delay",
              field: "special_ability.projectile.burst_shot_delay",
              headerComponentParams: {
                icon: "ui/skins/default/icon_new_content_free.webp",
              },
            },
          ],
        },
      ],
    },
    {
      groupId: "ab_vortex",
      openByDefault: localStorage.getItem("ab_vortex") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          type: ["booleanColumn"],
          headerName: "Vortex",
          field: "special_ability.vortex",
          headerComponentParams: {
            icon: "ui/battle%20ui/ability_icons/icon_spell_vortex.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Vortex Delay",
          field: "special_ability.vortex.delay",
          headerComponentParams: {
            icon: "ui/skins/default/icon_new_content_free.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Number of Vortexs",
          field: "special_ability.vortex.num_vortexs",
          synonyms: [
            "vortex_number",
            "vortex_num",
            "vortexs_number",
            "vortexs_num",
          ],
          headerComponentParams: {
            icon: "ui/skins/default/icon_pop_surplus.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Vortex Detonation Force",
          field: "special_ability.vortex.detonation_force",
          headerComponentParams: {
            icon: "ui/skins/default/rank_dspl_char_stats.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Vortex Duration",
          field: "special_ability.vortex.duration",
          headerComponentParams: {
            icon: "ui/skins/default/icon_ability_stage3.webp",
            // icon: "ui/campaign%20ui/effect_bundles/turns.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Vortex Movement Speed",
          field: "special_ability.vortex.movement_speed",
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_speed.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Vortex Start Radius",
          field: "special_ability.vortex.start_radius",
          headerComponentParams: {
            icon: "ui/skins/default/help_page_drag.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Vortex Expansion Speed",
          field: "special_ability.vortex.expansion_speed",
          headerComponentParams: {
            icon: "ui/skins/default/icon_rampage_unit_card.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Vortex Goal Radius",
          field: "special_ability.vortex.goal_radius",
          headerComponentParams: {
            icon: "ui/skins/default/help_page_drag.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Vortex Base Damage",
          field: "special_ability.vortex.damage",
          synonyms: ["vortex_damage"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_stat_damage.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Vortex Armour-Piercing Damage",
          field: "special_ability.vortex.damage_ap",
          headerComponentParams: {
            icon: "ui/skins/default/modifier_icon_armour_piercing.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["magicAttack"],
          headerName: "Vortex Is Magical",
          field: "special_ability.vortex.is_magical",
        },
        {
          columnGroupShow: "open",
          type: ["flamingAttack"],
          headerName: "Vortex Is Flaming",
          field: "special_ability.vortex.ignition_amount",
        },
        {
          columnGroupShow: "open",
          type: ["phaseColumn"],
          headerName: "Vortex Phase",
          field: "special_ability.vortex.phase",
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Vortex Height Off Ground",
          field: "special_ability.vortex.height_off_ground",
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Vortex Infinite Height",
          field: "special_ability.vortex.infinite_height",
          headerComponentParams: {
            icon: "ui/skins/default/icon_compare.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Vortex Move Change Freq",
          field: "special_ability.vortex.move_change_freq",
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Vortex Change Max Angle",
          field: "special_ability.vortex.change_max_angle",
        },
        {
          columnGroupShow: "open",
          headerName: "Vortex Launch Source",
          field: "special_ability.vortex.launch_source",
        },
        {
          columnGroupShow: "open",
          headerName: "Vortex Building Collision",
          field: "special_ability.vortex.building_collision",
        },
      ],
    },
    {
      groupId: "ab_phase",
      openByDefault: localStorage.getItem("ab_phase") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          type: ["phasesColumn"],
          headerName: "Phases",
          field: "phases",
          synonyms: ["phase"],
        },
        {
          columnGroupShow: "open",
          headerName: "Phase Effect Type",
          field: "dominant_phase.effect_type",
          cellRenderer: "PhaseEffectTypeRenderer",
          headerComponentParams: {
            icon: "ui/skins/default/unit_effect_both.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Phase Duration",
          field: "dominant_phase.duration",
          headerComponentParams: {
            icon: "ui/skins/default/icon_ability_stage3.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Phase HP Change Frequency",
          field: "dominant_phase.hp_change_frequency",
          synonyms: ["hp_change_frequency"],
          headerComponentParams: {
            fa: "heartbeat",
            tooltipDesc: PhaseDirectDamageTooltip,
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Phase Damage Amount",
          field: "dominant_phase.damage_amount",
          synonyms: ["damage_amount"],
          headerComponentParams: {
            icon: "ui/battle%20ui/ability_icons/icon_spell_direct_damage.webp",
            tooltipDesc: PhaseDirectDamageTooltip,
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn", "percentColumn"],
          headerName: "Phase Damage Chance",
          field: "dominant_phase.damage_chance",
          synonyms: ["damage_chance"],
          headerComponentParams: {
            icon: "ui/battle%20ui/ability_icons/icon_spell_direct_damage.webp",
            icon_text_right: "%",
            tooltipDesc: PhaseDirectDamageTooltip,
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Phase Max Damaged Entities ",
          field: "dominant_phase.max_damaged_entities",
          synonyms: ["max_damaged_entities"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_public_order_neutral.webp",
            tooltipDesc: PhaseDirectDamageTooltip,
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Phase Heal Amount",
          field: "dominant_phase.heal_amount",
          synonyms: ["heal_amount"],
          headerComponentParams: {
            icon: "ui/battle%20ui/ability_icons/wh2_main_unit_contact_healing_light.webp",
            tooltipDesc: PhaseDirectDamageTooltip,
          },
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Phase Resurrect",
          field: "dominant_phase.resurrect",
          synonyms: ["resurrect"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_raise_dead_small.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Phase Unbreakable",
          synonyms: ["Unbreakable"],
          field: "dominant_phase.unbreakable",
          headerComponentParams: {
            icon: "ui/battle%20ui/ability_icons/unbreakable.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Phase Can't Move",
          field: "dominant_phase.cant_move",
          synonyms: ["can_t_move", "immobilize", "immobile"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_pause.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["booleanColumn"],
          headerName: "Phase Freeze Fatigue",
          field: "dominant_phase.freeze_fatigue",
          synonyms: ["freeze_fatigue"],
          headerComponentParams: {
            icon: "ui/battle%20ui/ability_icons/fatigue_immune.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Phase Fatigue Ratio Change",
          field: "dominant_phase.fatigue_change_ratio",
          synonyms: ["fatigue_ratio_change"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_status_fatigue_24px.webp",
            icon_text_right: "%",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Phase Ability Recharge Change",
          field: "dominant_phase.ability_recharge_change",
          synonyms: ["ability_recharge_change"],
          headerComponentParams: {
            icon: "ui/campaign%20ui/effect_bundles/magic_cooldown_character.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Phase Mana Regen Mod",
          field: "dominant_phase.mana_regen_mod",
          synonyms: ["mana_regen_mod"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_magic_replenish_rate.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["integerColumn"],
          headerName: "Phase Mana Max Depletion Mod",
          field: "dominant_phase.mana_max_depletion_mod",
          synonyms: ["mana_max_depletion_mod"],
          headerComponentParams: {
            icon: "ui/skins/default/icon_magic_pool.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["magicAttack"],
          headerName: "Phase Is Magical",
          field: "dominant_phase.imbue_magical",
        },
        {
          columnGroupShow: "open",
          type: ["flamingAttack"],
          headerName: "Phase Is Flaming",
          field: "dominant_phase.imbue_ignition",
        },
      ],
    },
    {
      type: ["UnitCardColumn"],
      headerName: "Spawned Unit",
      field: "special_ability.spawned_unit.unit_card",
      synonyms: ["unit"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_mancount.webp",
      },
    },
    {
      type: ["integerColumn", "percentColumn"],
      headerName: "Miscast Chance",
      field: "special_ability.miscast_chance",
      synonyms: ["miscast"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_attrition.webp",
        icon_text_right: "%",
      },
      minWidth: 50,
      width: 50,
      maxWidth: 50,
    },
    {
      groupId: "ab_miscast_explosion",
      openByDefault: localStorage.getItem("ab_miscast_explosion") === "true",
      headerGroupComponent: "ColumnGroupHeaderRenderer",
      children: [
        {
          type: ["booleanColumn"],
          headerName: "Miscast Explosion",
          field: "special_ability.miscast_explosion",
          headerComponentParams: {
            icon: "ui/skins/default/icon_attrition.webp",
          },
        },
        {
          columnGroupShow: "open",
          type: ["projectileBase"],
          headerName: "Miscast Explosion Base Damage",
          field: "special_ability.miscast_explosion.detonation_damage",
        },
        {
          columnGroupShow: "open",
          type: ["projectileAp"],
          headerName: "Miscast Explosion Armour-Piercing Damage",
          field: "special_ability.miscast_explosion.detonation_damage_ap",
        },
        {
          columnGroupShow: "open",
          type: ["magicAttack"],
          headerName: "Miscast Explosion Is Magical",
          field: "special_ability.miscast_explosion.is_magical",
        },
        // {
        //   columnGroupShow: 'open',
        //   type: ['flamingAttack'],
        //   headerName: 'Miscast Explosion Is Flaming',
        //   field: 'special_ability.miscast_explosion.ignition_amount'
        // },
        {
          columnGroupShow: "open",
          type: ["phaseColumn"],
          headerName: "Miscast Explosion Phase",
          field: "special_ability.miscast_explosion.phase",
        },
      ],
    },
    {
      type: ["integerColumn"],
      headerName: "Cost (Multiplayer)",
      field: "special_ability.mp_cost",
      synonyms: ["cost_multiplayer", "cost_mp"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_treasury.webp",
      },
    },
    // These are "duplicated" spells mostly in the vampire lore. Likely an old technique they were using tha no longer applies.
    // {
    //   headerName: 'special_ability.parent_ability',
    //   field: 'special_ability.parent_ability.key',
    //   minWidth: 300,
    //   width: 300,
    //   maxWidth: 300
    // },
    {
      type: ["keyColumn"],
      field: "key",
    },
  ];

  return colDefs;
}
