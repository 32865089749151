import gql from "graphql-tag";
import AbilityTooltipFragment from "./ability-tooltip-fragment";

export default gql`
  query AbilityQuery($tww_version: String!, $id: String!) {
    tww(tww_version: $tww_version) {
      tww_version
      ability(id: $id) {
        ...ability
      }
    }
  }
  ${AbilityTooltipFragment}
`;
