import React from "react";
import styled from "styled-components";

const TwwTooltip = ({ className, children }) => (
  <Root className={className}>
    <TopLeft />
    <Top />
    <TopRight />
    <Left />
    <Right />
    <BottomLeft />
    <Bottom />
    <BottomRight />
    {children}
  </Root>
);

const Root = styled.div`
  position: relative;
  padding: 8px;
  display: flex;
  flex-direction: column;
  -moz-box-shadow: -2px 2px 5px 3px #333;
  -webkit-box-shadow: -2px 2px 5px 3px #333;
  box-shadow: -2px 2px 5px 3px #333;
  background: rgba(0, 0, 0, 0.2);
`;
const Frame = styled.div`
  position: absolute;
  height: 8px;
  width: 8px;
`;
const TopLeft = styled(Frame)`
  background-image: url("${process.env
    .PUBLIC_URL}/images/tww/tooltip_frame_top_left.webp");
  top: 0;
  left: 0;
`;
const Top = styled(Frame)`
  background-image: url("${process.env
    .PUBLIC_URL}/images/tww/tooltip_frame_top.webp");
  top: 0;
  left: 8px;
  width: calc(100% - 16px);
  background-repeat: repeat;
`;
const TopRight = styled(Frame)`
  background-image: url("${process.env
    .PUBLIC_URL}/images/tww/tooltip_frame_top_right.webp");
  top: 0;
  right: 0;
`;
const Left = styled(Frame)`
  background-image: url("./images/tww/tooltip_frame_left.webp");
  left: 0;
  top: 8px;
  height: calc(100% - 16px);
  background-repeat: repeat;
`;
const Right = styled(Frame)`
  background-image: url("./images/tww/tooltip_frame_right.webp");
  right: 0;
  top: 8px;
  height: calc(100% - 16px);
  background-repeat: repeat;
`;
const BottomLeft = styled(Frame)`
  background-image: url("./images/tww/tooltip_frame_bottom_left.webp");
  bottom: 0;
  left: 0;
`;
const Bottom = styled(Frame)`
  background-image: url("./images/tww/tooltip_frame_bottom.webp");
  bottom: 0;
  left: 8px;
  width: calc(100% - 16px);
  background-repeat: repeat;
`;
const BottomRight = styled(Frame)`
  background-image: url("./images/tww/tooltip_frame_bottom_right.webp");
  bottom: 0;
  right: 0;
  background-position: -248px -248px;
`;

const Title = styled.div`
  height: 35px;
  margin-left: -4px;
  position: relative;
  width: 100%;
  padding-left: 36px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  font-family: serif;
  color: rgba(255, 248, 215);
  font-size: 18px;
  background-image: url("${process.env.PUBLIC_URL}/images/tww/tooltip_title_${({
    rarity,
  }) => (rarity === "unique" ? "legendary" : rarity)}.webp");
  box-sizing: border-box;
`;

const FlavorText = styled.div`
  padding: 0 24px;
  color: rgb(173, 173, 173);
  font-style: italic;
  font-size: 13px;
  font-weight: 400;
`;

export default TwwTooltip;

export { Title, FlavorText };
