import Phase from "./phase";

import { RoundFloat, applyFatigue } from "../services/common";
import {
  globalSettings,
} from "../services/twwstats-services";

export default function MeleeWeapon(data, tww_version, fatigue, cuc, unitSize) {
  if (!data) {
    return null;
  }

  const stats_scaling = cuc.unit_stat_to_size_scaling_values
    ?.filter(s => s.size === globalSettings().unit_size)
    .reduce((result, row) => ({...result, [row.stat]: unitSize === 1 ? row.single_entity_value : row.multi_entity_value}), {});

  // Metadata
  this.tww_version = tww_version;

  this.ignition_amount = data.ignition_amount ? true : false;
  this.is_magical = data.is_magical;
  this.building_damage = data.buiding_damage;
  this.base_damage = Math.round(data.damage * (stats_scaling?.stat_melee_damage_base ?? 1));
  this.ap_damage = Math.round(applyFatigue(
    fatigue,
    "stat_melee_damage_ap",
    data.ap_damage * (stats_scaling?.stat_melee_damage_ap ?? 1),
    cuc
  ));
  this.ap_ratio = RoundFloat(
    this.ap_damage / (this.base_damage + this.ap_damage)
  );
  this.bonus_v_large = data.bonus_v_large;
  this.bonus_v_infantry = data.bonus_v_infantry;
  // Max target size that splash attacks are used against.
  // SOURCE: https://forums.totalwar.com/discussion/189956/seems-to-be-impossible-to-increase-a-units-splash-damage
  this.splash_attack_target_size = data.splash_attack_target_size;
  this.splash_attack_max_attacks = data.splash_attack_max_attacks;
  // Affect the physical force of the splash attack (how much targets are thrown around)
  // SOURCE: https://forums.totalwar.com/discussion/189956/seems-to-be-impossible-to-increase-a-units-splash-damage
  this.splash_attack_power_multiplier = RoundFloat(
    data.splash_attack_power_multiplier
  );
  // this.first_strike = data.first_strike;
  this.weapon_length = data.weapon_length;
  this.collision_attack_max_targets = data.collision_attack_max_targets;
  this.collision_attack_max_targets_cooldown =
    data.collision_attack_max_targets_cooldown;
  this.melee_attack_interval = RoundFloat(data.melee_attack_interval);
  Object.defineProperty(this, "damage", {
    get: () => this.base_damage + this.ap_damage,
    enumerable: true,
  });

  // PRIMARY MELEE WEAPON CONTACT PHASE
  //
  this.phase =
    data.contact_phase && new Phase(data.contact_phase, tww_version, cuc);
}
