import React, { Fragment } from "react";
import { Tooltip } from "react-tippy";
import { cloudinaryUrl } from "../services/common";

export default ({
  special_category,
  style,
  desc,
  name,
  tww_version,
  icon,
  showName,
}) => {
  let circleImg = `unit_cat_holder_round.webp`;
  switch (special_category) {
    case "renown":
      circleImg = `unit_cat_holder_round_renown.webp`;
      break;
    case "elector_counts":
      circleImg = `unit_cat_holder_round_elector.webp`;
      break;
    case "crafted":
      circleImg = `unit_cat_holder_round_crafted.webp`;
      break;
    case "tech_lab":
      circleImg = `unit_cat_holder_round_tech_lab.webp`;
      break;
    case "blessed_spawning":
      circleImg = `unit_cat_holder_round_spawning.webp`;
      break;
    default:
      break;
  }

  const catHolderClass = special_category ? "cat_holder_renown" : "cat_holder";

  return (
    <Tooltip
      position="top"
      arrow="true"
      style={{ display: "flex", ...style }}
      html={
        <div
          style={{
            fontFamily: '"Open Sans", sans-serif',
          }}
        >
          <span style={{ fontWeight: "bold" }}>{name}</span>
          {desc ? <p>{desc}</p> : null}
        </div>
      }
    >
      <Fragment>
        <span
          className={catHolderClass}
          style={{
            width: "46px",
            height: "34px",
            textAlign: "center",
            position: "relative",
            lineHeight: "0px",
            alignSelf: "center",
          }}
        >
          <img
            className={catHolderClass}
            alt={name}
            src={
              cloudinaryUrl() +
              "/api/" +
              tww_version +
              "/ui/skins/default/" +
              circleImg.replace(".png", ".webp")
            }
          />
          {icon ? (
            <img
              className={"cat_icon"}
              alt={name}
              src={
                cloudinaryUrl() +
                "/api/" +
                tww_version +
                "/ui/common ui/unit_category_icons/" +
                icon +
                ".webp"
              }
            />
          ) : null}
        </span>
        {showName && (
          <span
            style={{ alignSelf: "center", fontSize: "16px", marginLeft: "2px" }}
          >
            {name}
          </span>
        )}
      </Fragment>
    </Tooltip>
  );
};
