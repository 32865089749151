import React, { Fragment } from 'react';
import windowSize from 'react-window-size';

class Ad extends React.Component {
  constructor(props){
    super(props);

    const {requiredWidth, requiredHeight, hidePortrait, windowWidth, windowHeight} = props

    this.state = {
      show: (!requiredWidth || windowWidth >= requiredWidth)
            && (!requiredHeight || windowHeight >= requiredHeight)
            && (!hidePortrait || windowHeight / windowWidth < 1)
    }
  }

  componentDidMount() {
    if (this.state.show) {
      window.location.hostname === process.env.REACT_APP_TWWSTATS_ADSENSE_HOSTNAME && (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }

  render() {
    if (!this.state.show) { return null; }

    return (
      <Fragment>
        {window.location.hostname === process.env.REACT_APP_TWWSTATS_ADSENSE_HOSTNAME && this.props.children}
      </Fragment>
    );
  }
}

export default windowSize(Ad);