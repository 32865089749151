import React from "react";
import { Tooltip } from "react-tippy";
import { cloudinaryUrl } from "../services/common";

export default (props) => {
  let style = { ...props.iconStyle };
  let cloudinaryTransform = [];

  if (props.width) {
    style.width = `${props.width}px`;
    cloudinaryTransform.push(`w_${props.width}`);
  }
  if (props.height) {
    style.height = `${props.height}px`;
    cloudinaryTransform.push(`h_${props.height}`);
  }

  const Icon = ({ onClick }) => (
    <img
      alt={props.name}
      src={`${cloudinaryUrl(cloudinaryTransform)}/api/${
        props.tww_version || process.env.REACT_APP_TWWSTATS_LATEST_VERSION
      }/${props.icon}`}
      style={{
        ...props.iconStyle,
        width: `${props.width}px`,
        height: `${props.height}px`,
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
    />
  );

  if (props.noTooltip) {
    return <Icon />;
  }

  return (
    <Tooltip
      position="top"
      arrow="true"
      style={{ display: "inline-flex", position: "relative", ...props.style }}
      html={
        <div
          style={{
            fontFamily: '"Open Sans", sans-serif',
          }}
        >
          <span style={{ fontWeight: "bold" }}>{props.name}</span>
          <p style={{ margin: "0" }}>{props.desc}</p>
        </div>
      }
    >
      <Icon onClick={props.onClick} />
    </Tooltip>
  );
};
