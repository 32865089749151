import gql from "graphql-tag";
import ContactPhaseFragment from "./contactphase-fragment";

export default gql `
    fragment effect_bonus on effect_bonus {
        bonus_value_id
        value {
            __typename
            ... on agent_action {
                localised_action_name
            }
            ... on phase {
                ...contact_phase
            }
            ... on ability {
                key
                supercedes_ability
                requires_effect_enabling
                icon_name
                uniqueness
                is_unit_upgrade
                is_hidden_in_ui
                name
                tooltip
            }
            ... on attribute_effect {
                phase
                attribute
            }
        }
    }
    ${ContactPhaseFragment}
    `