import React from "react";
import { Tooltip } from "react-tippy";
import { cloudinaryUrl } from "../services/common";

export default (props) => {
  if (!props.parry) {
    return null;
  }

  let shield = "";

  //Gold Shield treshold: https://forums.totalwar.com/discussion/215316/are-there-any-gold-shields#latest
  /* https://steamcommunity.com/app/364360/discussions/0/1470841715951469948/ */
  /* https://www.reddit.com/r/totalwar/comments/4z7tt3/explanation_of_fire_damage_in_warhammer/ */
  if (props.parry > 75) {
    shield = "modifier_icon_shield3.webp";
  } else if (props.parry > 35) {
    shield = "modifier_icon_shield2.webp";
  } else if (props.parry > 0) {
    shield = "modifier_icon_shield1.webp";
  }

  return (
    <Tooltip
      position="top"
      arrow="true"
      // style={{display: 'flex'}}
      style={{}}
      html={
        <div
          style={{
            fontFamily: '"Open Sans", sans-serif',
            padding: "24px 4px 4px",
          }}
        >
          <span
            style={{ fontWeight: "bold", margin: "0 0 4px 0" }}
          >{`Parry Chance (${props.parry})`}</span>
          <p style={{ margin: "0" }}>
            {props.parry}% chance to block front missile attacks
          </p>
        </div>
      }
    >
      {props.value}
      <img
        alt={shield}
        src={`${cloudinaryUrl()}/api/${
          process.env.REACT_APP_TWWSTATS_LATEST_VERSION
        }/ui/skins/default/${shield.replace(".png", ".webp")}`}
        style={{ verticalAlign: "sub" }}
      />
    </Tooltip>
  );
};
