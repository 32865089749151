import React from "react";
import FontAwesome from "react-fontawesome";

const CardButton = ({ name, style, title, onClick }) => (
  <FontAwesome
    className="card_button"
    title={title}
    style={{
      opacity: ".5",
      cursor: "pointer",
      width: "30px",
      textAlign: "center",
      ...style,
    }}
    name={name}
    size="2x"
    onClick={onClick}
  ></FontAwesome>
);

export default CardButton;
