import gql from "graphql-tag";

export default gql`
  fragment contact_phase on phase {
    id
    onscreen_name
    duration
    effect_type
    requested_stance
    unbreakable
    cant_move
    freeze_fatigue
    fatigue_change_ratio
    # inspiration_aura_change_mod
    ability_recharge_change
    hp_change_frequency
    heal_amount
    damage_chance
    damage_amount
    max_damaged_entities
    resurrect
    mana_regen_mod
    mana_max_depletion_mod
    imbue_magical
    imbue_ignition
    phase_display
    imbue_contact {
      id
      onscreen_name
      duration
      effect_type
      requested_stance
      unbreakable
      cant_move
      freeze_fatigue
      fatigue_change_ratio
      # inspiration_aura_change_mod
      ability_recharge_change
      hp_change_frequency
      heal_amount
      damage_chance
      damage_amount
      max_damaged_entities
      resurrect
      mana_regen_mod
      mana_max_depletion_mod
      imbue_magical
      imbue_ignition
      phase_display
      stat_effects {
        phase
        stat
        value
        how
      }
      attribute_effects {
        phase
        attribute
        attribute_type
      }
    }
    stat_effects {
      phase
      stat
      value
      how
    }
    attribute_effects {
      phase
      attribute
      attribute_type
    }
  }
`;
