import React, { Fragment } from "react";
import _ from "lodash";
import GenericGrid from "../components/generic-grid";
import CampaignDifficultiesQuery from "../graphql/campaign-difficulties-query-hoc";
import VersionsCompare from "../components/versions-compare";
import GridControls from "./grid-controls-hoc";

// True = Player
// For the player: -3 = legendary, -2 = very hard, -1 = hard, 0 = normal, 1 = easy
// False = The AI
// For the AI: -1 = easy, 0 = normal, 1 = hard, 2 = very hard, 3 = legendary
// http://www.twcenter.net/forums/showthread.php?449790-campaign_difficulty_handicap_effects-Which-numbers-corresponds-to-which-difficulty-setting
const difficultyNames = {
  3: "legendary",
  2: "very hard",
  1: "hard",
  0: "normal",
  "-1": "easy",
};

const difficultyValue = {
  legendary: 3,
  "very hard": 2,
  hard: 1,
  normal: 0,
  easy: -1,
};

function createColumnDefs(data) {
  return [
    {
      headerName: "Difficulty",
      field: "campaign_difficulty_handicap",
      synonyms: ["diff"],
      pinned: "left",
      valueGetter: (params) =>
        difficultyNames[
          params.data.campaign_difficulty_handicap *
            (params.data.human ? -1 : 1)
        ],
      comparator: (a, b) => {
        const da = difficultyValue[a];
        const db = difficultyValue[b];
        return da === db ? 0 : da > db ? 1 : -1;
      },
      minWidth: 100,
      width: 100,
      maxWidth: 100,
    },
    {
      type: ["booleanColumn"],
      headerName: "Player?",
      field: "human",
      headerComponentParams: {
        icon: "ui/skins/default/icon_player.webp",
        falseIcon: "ui/skins/default/icon_ai.webp",
        headerTooltip: (props) => (
          <Fragment>
            <p>TRUE: The effect applies to the player</p>
            <p>FALSE: The effect applies to the AI</p>
          </Fragment>
        ),
      },
      minWidth: 70,
      width: 70,
      maxWidth: 70,
    },
    {
      headerName: "Effect",
      valueGetter: (params) => {
        let val = "" + (params.data.effect && params.data.effect.description);
        if (params.data.effect_value >= 0) {
          val = val.replace("%+n", `+${params.data.effect_value}`);
          val = val.replace("%n", `${params.data.effect_value}`);
        } else {
          val = val.replace("%+n", `${params.data.effect_value}`);
          val = val.replace("%n", `${params.data.effect_value}`);
        }

        return val;
      },
      headerComponentParams: {},
      minWidth: 500,
      width: 500,
      maxWidth: 500,
    },
    {
      headerName: "Scope",
      field: "effect_scope",
      headerComponentParams: {},
      minWidth: 120,
      width: 120,
      maxWidth: 120,
    },
    {
      type: ["integerColumn"],
      useInfinite: false,
      headerName: "Value",
      field: "effect_value",
      minWidth: 62,
      width: 62,
      maxWidth: 62,
    },
    // Not consistent enough accross effects to be shown
    // {
    //   type: ['booleanColumn'],
    //   headerName: "Positive or Negative?",
    //   // valueGetter: params => params.data.effect && (params.data.effect.is_positive_value_good ? params.data.effect_value >= 0 : params.data.effect_value <= 0),
    //   valueGetter: params => params.data.effect && params.data.effect.is_positive_value_good,
    //   headerComponentParams:{
    //     icon: 'ui/skins/default/unit_effect_both.webp',
    //     trueIcon: 'ui/skins/default/unit_effect_positive.webp',
    //     falseIcon: 'ui/skins/default/unit_effect_negative.webp'
    //   },
    //   minWidth: 216,
    //   width: 216,
    //   maxWidth: 216,
    // },
    {
      type: ["booleanColumn"],
      headerName: "Same on all difficulties?",
      field: "same_on_all_difficulties",
      synonyms: ["same"],
      headerComponentParams: {
        // icon: 'ui/skins/default/icon_player.webp',
        // falseIcon: 'ui/skins/default/icon_ai.webp',
        headerTooltip: (props) => (
          <Fragment>
            <p>[same]</p>
            <p>TRUE: This effect is the SAME on all difficulty levels</p>
            <p>FALSE: This effect is NOT the same on all difficulty levels</p>
          </Fragment>
        ),
      },
      minWidth: 242,
      width: 242,
      maxWidth: 242,
    },
    {
      headerName: "Optional Campaign Key",
      field: "optional_campaign_key",
      headerComponentParams: {},
      minWidth: 216,
      width: 216,
      maxWidth: 216,
    },
  ];
}

function dataLoaded(data) {
  _.each(data, (o) => {
    const difficulties = o.human ? [-3, -2, -1, 0, 1] : [3, 2, 1, 0, -1];
    o.same_on_all_difficulties = _.every(difficulties, (diff) => {
      return _.some(data, (d) => {
        return (
          d.human === o.human &&
          d.campaign_difficulty_handicap === diff &&
          d.effect.effect === o.effect.effect &&
          d.effect_value === o.effect_value
        );
      });
    });
  });

  return data;
}

const Grid = (props) => (
  <VersionsCompare
    {...props}
    query={CampaignDifficultiesQuery}
    dataKey={"campaign_difficulty_handicap_effects"}
    rowKey={"campaign_difficulty_handicap"}
    dataLoaded={dataLoaded}
    sortData={(data) =>
      _.orderBy(
        data,
        [
          (o) => o.campaign_difficulty_handicap * (o.human ? -1 : 1),
          (o) => o.human,
        ],
        ["desc", "desc"]
      )
    }
    createColumnDefs={createColumnDefs}
    WrappedComponent={GenericGrid}
  />
);

const CampaignDifficulties = (props) => (
  <GridControls {...props} WrappedComponent={Grid} />
);

export default CampaignDifficulties;
