import _ from "lodash";
import React, { Fragment } from "react";
import FontAwesome from "react-fontawesome";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tippy";
import AbilityIcon from "../../components/AbilityIcon";
import AbilityTypeIcon from "../../components/abilitytypeicon";
import AttributeIcon from "../../components/attributeicon";
import CategoryIcon from "../../components/categoryicon";
import FlamingAttackIcon, {
  FlamingAttackIconOnly,
  FlamingAttackTooltip,
} from "../../components/flamingattackicon";
import GenericIcon from "../../components/genericicon";
import MagicAttackIcon, {
  MagicAttackIconOnly,
  MagicAttackTooltip,
} from "../../components/magicattackicon";
import MeleeAttackTooltip from "../../components/meleeattack";
import OvercastIcon from "../../components/overcasticon";
import PhaseIcon, {
  PhaseIconOnly,
  PhaseTooltip,
} from "../../components/phaseicon";
import ShieldIcon from "../../components/shieldicon";
import { cloudinaryUrl, iconRegex, RoundFloat } from "../../services/common";

let Renderers = {};

Renderers.FactionsRenderer = class extends React.Component {
  render() {
    const factions = this.props.value;
    return factions == null
      ? ""
      : factions.map((f) => (
          <GenericIcon
            key={f.screen_name}
            name={f.screen_name}
            height={24}
            width={24}
            icon={f.flags_url + "/mon_24.webp"}
            tww_version={this.props.tww_version || this.props.data.tww_version}
            onClick={
              this.props.onClickFaction
                ? () =>
                    this.props.onClickFaction({
                      version: this.props.tww_version,
                      faction: f,
                    })
                : null
            }
          />
        ));
  }
};

Renderers.AbilitiesRenderer = class extends React.Component {
  render() {
    const abilities = this.props.value;

    return abilities == null ? null : (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ flexGrow: "1", lineHeight: "14px" }}>
          {abilities.map((a) => (
            <AbilityIcon
              key={a.key}
              ability={a}
              tww_version={this.props.data.tww_version}
              width={32}
              height={32}
              iconStyle={{ position: "relative", marginTop: "0px" }}
            />
          ))}
        </div>
        <div style={{ flexGrow: "0", flexShrink: "0", flexBasis: "36px" }}>
          <Link
            to={`/abilities/?right=${
              this.props.data.tww_version
            }&q=key%3D%3D%28${abilities.reduce(
              (prev, val) => `${prev}${val.key}%2C${val.key}_upgraded%2C`,
              ""
            )}%29`}
          >
            <Tooltip
              position="top"
              arrow="true"
              html={
                <div style={{ fontFamily: '"Open Sans", sans-serif' }}>
                  <p style={{ fontWeight: "bold" }}>View in Abilities Grid</p>
                </div>
              }
            >
              <FontAwesome
                className="btn"
                title="info"
                name="info"
              ></FontAwesome>
            </Tooltip>
          </Link>
        </div>
      </div>
    );
  }
};

Renderers.AbilityTypeRenderer = class extends React.Component {
  render() {
    return (
      <AbilityTypeIcon
        icon={this.props.data.type_icon}
        name={this.props.value}
        desc={""}
        tww_version={this.props.data.tww_version}
      />
    );
  }
};

Renderers.AttributesRenderer = class extends React.Component {
  render() {
    const attributes = this.props.value;
    return attributes == null
      ? ""
      : attributes.map((a) => <AttributeIcon attribute={a} key={a.key} />);
  }
};

Renderers.ArmourAndShieldRenderer = class extends React.Component {
  render() {
    let shield = "";
    const parry_chance = this.props.data.parry_chance;

    //Gold Shield treshold: https://forums.totalwar.com/discussion/215316/are-there-any-gold-shields#latest
    /* https://steamcommunity.com/app/364360/discussions/0/1470841715951469948/ */
    /* https://www.reddit.com/r/totalwar/comments/4z7tt3/explanation_of_fire_damage_in_warhammer/ */
    if (parry_chance > 75) {
      shield = "modifier_icon_shield3.webp";
    } else if (parry_chance > 35) {
      shield = "modifier_icon_shield2.webp";
    } else if (parry_chance > 0) {
      shield = "modifier_icon_shield1.webp";
    }

    return (
      <Tooltip
        position="top"
        arrow="true"
        html={
          <div
            style={{
              fontFamily: '"Open Sans", sans-serif',
            }}
          >
            <p style={{ fontWeight: "bold" }}>Armour: {this.props.value}</p>
            <p>{`Non Armor-Piercing Damage reduced by ${
              this.props.value / 2
            }% to ${this.props.value}%${
              this.props.value > 100 ? " (capped at 100%)" : ""
            }`}</p>
            {(parry_chance && (
              <Fragment>
                <p
                  style={{ fontWeight: "bold" }}
                >{`Parry Chance: ${parry_chance}`}</p>
                <p>{`${parry_chance}% chance to avoid missile damage coming from the front`}</p>
              </Fragment>
            )) ||
              null}
          </div>
        }
      >
        {(parry_chance && (
          <img
            alt={shield}
            src={
              cloudinaryUrl() +
              "/api/" +
              process.env.REACT_APP_TWWSTATS_LATEST_VERSION +
              "/ui/skins/default/" +
              shield.replace(".png", ".webp")
            }
            style={{ verticalAlign: "sub" }}
          />
        )) ||
          null}
        {this.props.valueFormatted}
      </Tooltip>
    );
  }
};

Renderers.ShieldRenderer = class extends React.Component {
  render() {
    return (
      <span>
        <ShieldIcon
          parry={this.props.data.parry_chance}
          value={this.props.valueFormatted}
        />
      </span>
    );
  }
};

Renderers.StringWithIconsRenderer = class extends React.Component {
  render() {
    let val = this.props.value;

    const replaceString = `<img src='${cloudinaryUrl()}/api/${
      this.props.data.tww_version
    }/ui/skins/default/icon_$1.webp'/>`;
    val = val && val.replace(iconRegex, replaceString);
    return <span dangerouslySetInnerHTML={{ __html: val }}></span>;
  }
};

Renderers.PhaseRenderer = class extends React.Component {
  render() {
    const phase = this.props.value;
    return <span>{phase && <PhaseIcon phase={phase} />}</span>;
  }
};

Renderers.PhasesRenderer = class extends React.Component {
  render() {
    return (
      <Fragment>
        {this.props.value &&
          this.props.value.map((phase, idx) => (
            <span key={idx}>
              {
                <PhaseIcon
                  phase={phase}
                  // label={phase.name}
                />
              }
            </span>
          ))}
      </Fragment>
    );
  }
};

Renderers.TraitLevelEffectsRenderer = class extends React.Component {
  render() {
    return (
      <div>
        {this.props.value &&
          this.props.value.map((tle, idx) => {
            let val = tle.effect.description;
            if (tle.value >= 0) {
              val = val.replace("%+n", `+${tle.value}`);
              val = val.replace("%n", `${tle.value}`);
            } else {
              val = val.replace("%+n", `${tle.value}`);
              val = val.replace("%n", `${tle.value}`);
            }

            const replaceString = `<img src='${cloudinaryUrl()}/api/${
              this.props.data.tww_version
            }/ui/campaign%20ui/effect_bundles/$1.webp'/>`;
            val = val && val.replace(iconRegex, replaceString);

            return (
              <p style={{ margin: 0 }} key={idx}>
                {val}
              </p>
            );
          })}
      </div>
    );
  }
};

Renderers.MeleeContactPhaseRenderer = class extends React.Component {
  render() {
    return (
      <Tooltip
        position="top"
        arrow="true"
        html={
          <div
            style={{
              fontFamily: '"Open Sans", sans-serif',
            }}
          >
            <MeleeAttackTooltip {...this.props} />
            {this.props.data.primary_melee_weapon.is_magical ? (
              <MagicAttackTooltip />
            ) : null}
            {this.props.data.primary_melee_weapon.ignition_amount ? (
              <FlamingAttackTooltip />
            ) : null}
            {this.props.data.primary_melee_weapon.phase ? (
              <PhaseTooltip
                phase={this.props.data.primary_melee_weapon.phase}
              />
            ) : null}
          </div>
        }
      >
        {this.props.data.primary_melee_weapon.is_magical ? (
          <MagicAttackIconOnly />
        ) : null}
        {this.props.data.primary_melee_weapon.ignition_amount ? (
          <FlamingAttackIconOnly />
        ) : null}
        {this.props.data.primary_melee_weapon.phase ? (
          <PhaseIconOnly phase={this.props.data.primary_melee_weapon.phase} />
        ) : null}
        {this.props.valueFormatted}
      </Tooltip>
    );
  }
};

Renderers.MissileContactPhaseRenderer = class extends React.Component {
  render() {
    // TODO make that more robust
    const field = this.props.colDef.field.replace(".damage", "");
    const missile_weapon = this.props.data[field];
    if (!missile_weapon) {
      return null;
    }

    const phase = missile_weapon.phase;
    const magic = missile_weapon.is_magical;
    const flaming = missile_weapon.ignition_amount;

    return (
      <Tooltip
        position="top"
        arrow="true"
        html={
          <div
            style={{
              fontFamily: '"Open Sans", sans-serif',
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              {this.props.colDef.headerName}: {this.props.value}
            </p>
            {magic ? <MagicAttackTooltip /> : null}
            {flaming ? <FlamingAttackTooltip /> : null}
            {phase ? <PhaseTooltip phase={phase} /> : null}
          </div>
        }
      >
        <span>
          {magic ? <MagicAttackIconOnly /> : null}
          {flaming ? <FlamingAttackIconOnly /> : null}
          {phase ? <PhaseIconOnly phase={phase} /> : null}
          {this.props.valueFormatted}
        </span>
      </Tooltip>
    );
  }
};

Renderers.BooleanRenderer = class extends React.Component {
  render() {
    let title = `${this.props.colDef.headerName}: `;
    title += this.props.value ? "Yes [1]" : "No [0]";

    const icon =
      (this.props.value &&
        ((this.props.colDef.headerComponentParams &&
          (this.props.colDef.headerComponentParams.trueIcon ||
            this.props.colDef.headerComponentParams.icon)) ||
          `ui/skins/default/icon_check_green.webp`)) ||
      (this.props.colDef.headerComponentParams &&
        this.props.colDef.headerComponentParams.falseIcon) ||
      null;

    if (!icon) {
      return (
        <Tooltip
          position="top"
          arrow="true"
          style={{ display: "inline-flex" }}
          html={
            <div
              style={{
                fontFamily: '"Open Sans", sans-serif',
              }}
            >
              <span style={{ fontWeight: "bold" }}>{title}</span>
            </div>
          }
        >
          <span>0</span>
        </Tooltip>
      );
    }

    return <GenericIcon height={32} width={32} name={title} icon={icon} />;
  }
};

Renderers.IntegerRenderer = class extends React.Component {
  render() {
    const value = `${this.props.valueFormatted || this.props.value || 0}`;
    let title = `${
      this.props.colDef.headerName ||
      this.props.colDef.headerComponentParams.title
    }: ${value}`;
    if (this.props.colDef.useInfinite) {
      title = `${
        this.props.colDef.headerName ||
        this.props.colDef.headerComponentParams.title
      }: ${this.props.value === -1 ? "infinite [-1]" : value}`;
    }

    return (
      <Tooltip
        position="top"
        arrow="true"
        style={{ display: "inline-flex" }}
        html={
          <div
            style={{
              fontFamily: '"Open Sans", sans-serif',
            }}
          >
            {this.props.colDef.headerComponentParams &&
            this.props.colDef.headerComponentParams.tooltipDesc ? (
              <this.props.colDef.headerComponentParams.tooltipDesc
                {...this.props}
              />
            ) : (
              <p style={{ fontWeight: "bold" }}>{title}</p>
            )}
          </div>
        }
      >
        <span>
          {this.props.colDef.useInfinite
            ? value.replace(/(-1$|-1 )/, "∞")
            : value}
        </span>
      </Tooltip>
    );
  }
};

Renderers.DeltaStatusRenderer = ({ value, colDef, ...props }) => {
  let deltas = [];
  for (const property in props.data) {
    if (
      property.startsWith("delta_") &&
      property !== "delta_status" &&
      property !== "delta_side"
    ) {
      deltas.push(property);
    }
  }

  return (
    <Tooltip
      position="top"
      arrow="true"
      style={{ display: "inline-flex" }}
      html={
        <div style={{ fontFamily: '"Open Sans", sans-serif' }}>
          {deltas.map((d) => (
            <p key={d}>
              <span>{d}: </span>
              <span>{props.data[d]}</span>
            </p>
          ))}
        </div>
      }
    >
      <span>{value}</span>
    </Tooltip>
  );
};

Renderers.DefaultHeaderRenderer = class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ascSort: "inactive",
      descSort: "inactive",
      noSort: "inactive",
    };

    this.onSortRequested = this.onSortRequested.bind(this);
    this.onSortChanged = this.onSortChanged.bind(this);
  }

  componentDidMount() {
    this.onSortChanged();
    this.props.column.addEventListener("sortChanged", this.onSortChanged);
  }

  componentWillUnmount() {
    this.props.column.removeEventListener("sortChanged", this.onSortChanged);
  }

  onSortChanged() {
    this.setState({
      ascSort: this.props.column.isSortAscending() ? "active" : "inactive",
      descSort: this.props.column.isSortDescending() ? "active" : "inactive",
      noSort:
        !this.props.column.isSortAscending() &&
        !this.props.column.isSortDescending()
          ? "active"
          : "inactive",
    });
  }

  onSortRequested(event) {
    this.props.progressSort(event.shiftKey);
  }

  render() {
    let sort = null;
    if (this.props.enableSorting) {
      sort = (
        <div style={{ display: "inline-block", marginLeft: "2px" }}>
          <div className={`customSortDownLabel ${this.state.descSort}`}>
            <i className="fa fa-sort-down" />
          </div>
          <div className={`customSortUpLabel ${this.state.ascSort}`}>
            <i className="fa fa-sort-up" />
          </div>
        </div>
        // <span ref="eFilter" class="ag-header-icon ag-filter-icon ag-hidden" aria-hidden="true"><i class="fa fa-filter"></i></span>
        // <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden" aria-hidden="true"></span>
        // <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden" aria-hidden="true"><i class="fa fa-sort-up"></i></span>
        // <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden" aria-hidden="true"><i class="fa fa-sort-down"></i></span>
        // <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden" aria-hidden="true"><span class="ag-icon ag-icon-none"></span></span>
      );
    }

    return (
      <Tooltip
        position="top"
        arrow={true}
        distance={8}
        html={
          <div
            style={{
              fontFamily: '"Open Sans", sans-serif',
            }}
          >
            <span style={{ fontWeight: "bold" }}>
              {this.props.title || this.props.displayName}
            </span>
            {this.props.headerTooltip ? (
              <this.props.headerTooltip {...this.props} />
            ) : null}
          </div>
        }
        style={{ width: "100%" }}
      >
        <div
          className="ag-cell-label-container ag-header-cell-sorted-none"
          role="presentation"
          onTouchEndCapture={this.onSortRequested}
          onClick={this.onSortRequested}
          style={{ cursor: "pointer" }}
        >
          <div
            ref="eLabel"
            className="ag-header-cell-label"
            role="presentation"
            style={{ justifyContent: "center" }}
          >
            {this.props.icon ? (
              <span
                ref="eText"
                className="ag-header-cell-text"
                role="columnheader"
              >
                {this.props.icon_text_left}
                <img
                  alt={this.props.icon}
                  src={
                    cloudinaryUrl() +
                    "/api/" +
                    process.env.REACT_APP_TWWSTATS_LATEST_VERSION +
                    "/" +
                    this.props.icon.replace(".png", ".webp")
                  }
                />
                {this.props.icon2 && (
                  <img
                    alt={this.props.icon2}
                    src={
                      cloudinaryUrl() +
                      "/api/" +
                      process.env.REACT_APP_TWWSTATS_LATEST_VERSION +
                      "/" +
                      this.props.icon2.replace(".png", ".webp")
                    }
                  />
                )}
                {this.props.icon_text_right}
              </span>
            ) : this.props.fa ? (
              <span
                ref="eText"
                className="ag-header-cell-text"
                role="columnheader"
              >
                {this.props.icon_text_left}
                <FontAwesome name={this.props.fa} color={this.props.facolor} />
                {this.props.icon2 && (
                  <img
                    alt={this.props.icon2}
                    src={
                      cloudinaryUrl() +
                      "/api/" +
                      process.env.REACT_APP_TWWSTATS_LATEST_VERSION +
                      "/" +
                      this.props.icon2.replace(".png", ".webp")
                    }
                  />
                )}
                {this.props.icon_text_right}
              </span>
            ) : (
              <span
                ref="eText"
                className="ag-header-cell-text"
                role="columnheader"
              >
                {this.props.title || this.props.displayName}
              </span>
            )}
            {sort}
          </div>
        </div>
      </Tooltip>
    );
  }
};

Renderers.ColumnGroupHeaderRenderer = class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandState: "collapsed",
    };

    this.expandOrCollapse = this.expandOrCollapse.bind(this);
    this.syncExpandButtons = this.syncExpandButtons.bind(this);
  }

  componentDidMount() {
    this.syncExpandButtons();
    this.props.columnGroup
      .getOriginalColumnGroup()
      .addEventListener("expandedChanged", this.syncExpandButtons);
  }

  componentWillUnmount() {
    this.props.columnGroup
      .getOriginalColumnGroup()
      .removeEventListener("expandedChanged", this.syncExpandButtons);
  }

  expandOrCollapse() {
    let currentState = this.props.columnGroup
      .getOriginalColumnGroup()
      .isExpanded();
    this.props.setExpanded(!currentState);
  }

  syncExpandButtons() {
    this.setState({
      expandState: this.props.columnGroup.getOriginalColumnGroup().isExpanded()
        ? "expanded"
        : "collapsed",
    });
  }

  render() {
    return (
      <div className={"group-header"}>
        <span
          ref="eText"
          className={"ag-header-cell-text"}
          role="columnheader"
          title={this.props.title}
          onTouchEndCapture={this.expandOrCollapse}
          onClick={this.expandOrCollapse}
          style={{ cursor: "pointer" }}
        >
          {this.state.expandState === "expanded" ? (
            <FontAwesome name={"minus-circle"}></FontAwesome>
          ) : (
            <FontAwesome name={"plus-circle"}></FontAwesome>
          )}
        </span>
      </div>
    );
  }
};

Renderers.UnitCardRenderer = (props) => {
  if (!props.value) {
    return null;
  }
  let icon = props.value;
  // SUPER CHEESY ALERT (working but incomplete and very annoying to maintain...)
  // if(props.value.variant.name.indexOf('wh2_dlc09_tmb_cha') !== -1){
  //   if(/arkhan|khalida|khatep|settra/i.test(props.data.key)) {
  //     icon = `ui/portraits/units/wh2_dlc09_tmb_tomb_kings/${props.value.variant.name.replace('wh2_dlc09_tmb_cha', 'tmb_ch')}.webp`;
  //   }
  //   else{
  //     // hero
  //     icon = `ui/portraits/units/wh2_dlc09_tmb_tomb_kings/${props.value.variant.variant.replace('wh2_dlc09_', '')}_campaign_01_0.webp`;
  //   }
  // }
  // else if(/red_duke/i.test(props.data.key)){
  //   icon = `ui/portraits/units/wh_main_vmp_vampire_counts/vmp_the_red_duke_0.webp`;
  // }
  // else if(/_alberic_/i.test(props.data.key)){
  //   icon = `ui/portraits/units/wh_main_brt_bretonnia/brt_alberic_bordeleaux_0.webp`;
  // }
  // else if(/fay_enchantress/i.test(props.data.key)){
  //   icon = `ui/portraits/units/wh_main_brt_bretonnia/brt_ch_fay_enchantress_0.webp`;
  // }

  return (
    <GenericIcon
      name={props.value.onscreen_name}
      desc={props.value.short_description_text}
      icon={icon} // The Unit Card column would use {props.value} directly but we don't use it for now
      width={34}
      height={72}
      tww_version={props.data.tww_version}
    />
  );
};

Renderers.UiUnitGroupIconRenderer = class extends React.Component {
  render() {
    return (
      <CategoryIcon
        name={this.props.value}
        desc={this.props.data.category_tooltip}
        special_category={this.props.data.special_category}
        icon={this.props.data.category_icon}
        tww_version={this.props.data.tww_version}
      />
    );
  }
};

Renderers.SpecialCategoryRenderer = class extends React.Component {
  render() {
    return (
      <CategoryIcon
        name={this.props.value}
        special_category={this.props.value}
        tww_version={this.props.data.tww_version}
      />
    );
  }
};

Renderers.AbilityIconRenderer = class extends React.Component {
  render() {
    return (
      <GenericIcon
        name={this.props.data.name}
        desc={this.props.data.tooltip}
        height={38}
        width={38}
        icon={`ui/battle%20ui/ability_icons/${this.props.value}.webp`}
        tww_version={this.props.data.tww_version}
      />
    );
  }
};

Renderers.TraitIconRenderer = class extends React.Component {
  render() {
    return (
      <GenericIcon
        name={this.props.data.onscreen_name}
        desc={this.props.data.colour_text}
        height={26}
        width={26}
        icon={this.props.value}
        tww_version={this.props.data.tww_version}
      />
    );
  }
};

Renderers.SpellTargetsIconsRenderer = class extends React.Component {
  render() {
    if (!this.props.value) {
      return null;
    }

    return (
      <Fragment>
        {this.props.value.indexOf("self") !== -1 && (
          <GenericIcon
            key={"self"}
            name={"Self"}
            height={26}
            width={26}
            icon={"ui/skins/default/icon_ability_target_self.webp"}
          />
        )}
        {this.props.value.indexOf("friends") !== -1 && (
          <GenericIcon
            key={"friends"}
            name={"Ally"}
            icon={"ui/skins/default/faction_icon_flag_ally.webp"}
            height={26}
            width={26}
          />
        )}
        {this.props.value.indexOf("ennemies") !== -1 && (
          <GenericIcon
            key={"ennemies"}
            name={"Enemy"}
            height={26}
            width={26}
            icon={"ui/skins/default/faction_icon_flag_enemy.webp"}
          />
        )}
        {this.props.value.indexOf("ground") !== -1 && (
          <GenericIcon
            key={"ground"}
            name={"Ground"}
            height={26}
            width={26}
            icon={"ui/skins/default/icon_target.webp"}
          />
        )}
      </Fragment>
    );
  }
};

Renderers.AbilityGroupIconRenderer = class extends React.Component {
  render() {
    return (
      <Fragment>
        {this.props.value &&
          this.props.value.map((group) => (
            <GenericIcon
              key={group.ability_group}
              name={group.button_name}
              desc={""}
              icon={group.icon_path.toLowerCase()}
              tww_version={this.props.data.tww_version}
            />
          ))}
      </Fragment>
    );
  }
};

Renderers.PhaseEffectTypeRenderer = class extends React.Component {
  render() {
    if (!this.props.value) {
      return null;
    }

    const effect_type_icon = `ui/skins/default/unit_effect_${this.props.value}.webp`;

    return (
      <GenericIcon
        name={this.props.value}
        icon={effect_type_icon}
        tww_version={this.props.data.tww_version}
      />
    );
  }
};

Renderers.EntitySizeRenderer = (props) => {
  const desc = `This unit will receive splash damage from units with splash_attack_target_size '${props.value}' or bigger`;

  return (
    <Tooltip
      position="top"
      arrow="true"
      style={{ display: "inline-flex" }}
      html={
        <div
          style={{
            fontFamily: '"Open Sans", sans-serif',
          }}
        >
          <span style={{ fontWeight: "bold" }}>Entity Size: {props.value}</span>
          <p style={{ margin: "0" }}>{desc}</p>
        </div>
      }
    >
      {props.value}
    </Tooltip>
  );
};

Renderers.GroundStatEffectGroupRenderer = (props, contentOnly = false) => {
  const effectsGroups =
    props.value && _.groupBy(props.value.stat_effects, "ground_type");
  const groupName = props.value
    ? `[${props.value.group_name}] ${props.value.onscreen_name}`
    : "";

  const content = (
    <div
      style={{
        fontFamily: '"Open Sans", sans-serif',
      }}
    >
      <p style={{ fontWeight: "bold" }}>
        Ground Type Effect Group: {groupName}
      </p>
      {effectsGroups && props.value.group_name ? (
        _.map(effectsGroups, (val, key) => (
          <Fragment key={key}>
            <p style={{ fontWeight: "bold", marginBottom: "0" }}>{key}</p>
            {val.map((o) => (
              <p style={{ margin: "0" }} key={o.affected_stat}>
                {o.affected_stat}: {RoundFloat(o.multiplier)}
              </p>
            ))}
          </Fragment>
        ))
      ) : (
        <p>This unit probably has the "Strider" attribute.</p>
      )}
    </div>
  );

  if (contentOnly) {
    return content;
  }

  return (
    <Tooltip
      position="top"
      arrow="true"
      style={{ display: "inline-flex" }}
      html={content}
    >
      {groupName}
    </Tooltip>
  );
};

export default function getFrameworkComponents() {
  return {
    FactionsRenderer: Renderers.FactionsRenderer,
    AbilitiesRenderer: Renderers.AbilitiesRenderer,
    AttributesRenderer: Renderers.AttributesRenderer,
    BooleanRenderer: Renderers.BooleanRenderer,
    IntegerRenderer: Renderers.IntegerRenderer,
    IconHeaderRenderer: Renderers.IconHeaderRenderer,
    DefaultHeaderRenderer: Renderers.DefaultHeaderRenderer,
    AbilityIconRenderer: Renderers.AbilityIconRenderer,
    TraitIconRenderer: Renderers.TraitIconRenderer,
    UiUnitGroupIconRenderer: Renderers.UiUnitGroupIconRenderer,
    ArmourAndShieldRenderer: Renderers.ArmourAndShieldRenderer,
    ShieldRenderer: Renderers.ShieldRenderer,
    ColumnGroupHeaderRenderer: Renderers.ColumnGroupHeaderRenderer,
    MeleeContactPhaseRenderer: Renderers.MeleeContactPhaseRenderer,
    MissileContactPhaseRenderer: Renderers.MissileContactPhaseRenderer,
    PhaseRenderer: Renderers.PhaseRenderer,
    PhasesRenderer: Renderers.PhasesRenderer,
    StringWithIconsRenderer: Renderers.StringWithIconsRenderer,
    TraitLevelEffectsRenderer: Renderers.TraitLevelEffectsRenderer,
    MagicAttackIcon: MagicAttackIcon,
    FlamingAttackIcon: FlamingAttackIcon,
    AbilityTypeRenderer: Renderers.AbilityTypeRenderer,
    AbilityGroupIconRenderer: Renderers.AbilityGroupIconRenderer,
    OvercastRenderer: OvercastIcon,
    SpellTargetsIconsRenderer: Renderers.SpellTargetsIconsRenderer,
    PhaseEffectTypeRenderer: Renderers.PhaseEffectTypeRenderer,
    UnitCardRenderer: Renderers.UnitCardRenderer,
    EntitySizeRenderer: Renderers.EntitySizeRenderer,
    GroundStatEffectGroupRenderer: Renderers.GroundStatEffectGroupRenderer,
    SpecialCategoryRenderer: Renderers.SpecialCategoryRenderer,
    DeltaStatusRenderer: Renderers.DeltaStatusRenderer,
  };
}
