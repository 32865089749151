import React from "react";
import { Tooltip } from "react-tippy";
import { cloudinaryUrl } from "../services/common";

export default (props) => {
  const title = props.data.is_overcast
    ? "Is an Overcast [2]"
    : props.data.has_overcast
    ? "Has an Overcast Available [1]"
    : "No Overcast [0]";
  const icon = props.data.is_overcast
    ? "ui/skins/default/icon_spell_browser_charge2.webp"
    : "ui/skins/default/icon_spell_browser_charge1.webp";

  return (
    <Tooltip
      position="top"
      arrow="true"
      style={{ display: "flex" }}
      html={
        <div
          style={{
            fontFamily: '"Open Sans", sans-serif',
          }}
        >
          <span style={{ fontWeight: "bold" }}>{title}</span>
        </div>
      }
    >
      {props.data.is_overcast || props.data.has_overcast ? (
        <img
          alt="overcast_level"
          src={`${cloudinaryUrl()}/api/${
            process.env.REACT_APP_TWWSTATS_LATEST_VERSION
          }/${icon.replace(".png", ".webp")}`}
          style={{ verticalAlign: "sub" }}
        />
      ) : (
        <span>0</span>
      )}
    </Tooltip>
  );
};
