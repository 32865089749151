import React from "react";
import { NavLink } from "react-router-dom";

export default ({ onClose }) => (
  <div className={"menu"}>
    {/* <NavLink exact={true} to="/" onClick={onClose} onMouseUp={onClose}>Home</NavLink> */}
    <NavLink
      exact={true}
      to="/unitscards"
      onClick={onClose}
      onMouseUp={onClose}
    >
      Units Cards
    </NavLink>
    <NavLink exact={true} to="/units" onClick={onClose} onMouseUp={onClose}>
      Units Grid
    </NavLink>
    {/* <NavLink exact={true} to="/unitsgraph" onClick={onClose} onMouseUp={onClose}>Units Graph</NavLink> */}
    <NavLink
      exact={true}
      to="/abilitiescards"
      onClick={onClose}
      onMouseUp={onClose}
    >
      Abilities Cards (BETA)
    </NavLink>
    <NavLink exact={true} to="/abilities" onClick={onClose} onMouseUp={onClose}>
      Abilities Grid
    </NavLink>
    <NavLink
      exact={true}
      to="/character_traits"
      onClick={onClose}
      onMouseUp={onClose}
    >
      Warlord Traits Grid
    </NavLink>
    <NavLink
      exact={true}
      to="/fatigueeffects"
      onClick={onClose}
      onMouseUp={onClose}
    >
      Fatigue Effects
    </NavLink>
    <NavLink
      exact={true}
      to="/campaigndifficulties"
      onClick={onClose}
      onMouseUp={onClose}
    >
      Campaign Difficulties
    </NavLink>
    <NavLink exact={true} to="/kv/rules" onClick={onClose} onMouseUp={onClose}>
      _kv_rules
    </NavLink>
    <NavLink exact={true} to="/kv/morale" onClick={onClose} onMouseUp={onClose}>
      _kv_morale
    </NavLink>
    <NavLink
      exact={true}
      to="/kv/fatigue"
      onClick={onClose}
      onMouseUp={onClose}
    >
      _kv_fatigue
    </NavLink>
    <NavLink
      exact={true}
      to="/kv/key_buildings"
      onClick={onClose}
      onMouseUp={onClose}
    >
      _kv_key_buildings
    </NavLink>
    <NavLink
      exact={true}
      to="/kv/unit_ability_scaling_rules"
      onClick={onClose}
      onMouseUp={onClose}
    >
      _kv_unit_ability_scaling_rules
    </NavLink>
    <NavLink
      exact={true}
      to="/kv/winds_of_magic_params"
      onClick={onClose}
      onMouseUp={onClose}
    >
      _kv_winds_of_magic_params
    </NavLink>
    {/* <NavLink exact={true} to="/info-and-mechanics" onClick={onClose}>Info & Mechanics</NavLink> */}
    <NavLink
      exact={true}
      to="/changelogs"
      onClick={onClose}
      onMouseUp={onClose}
    >
      Changelogs
    </NavLink>
  </div>
);
