import Phase from "./phase";
import buildModel from '../services/buildModel';
import {
    globalSettings,
  } from "../services/twwstats-services";

export default function Explosion(data, tww_version, cuc, unitSize) {
    const stats_scaling = cuc.unit_stat_to_size_scaling_values
        ?.filter(s => s.size === globalSettings().unit_size)
        .reduce((result, row) => ({...result, [row.stat]: unitSize === 1 ? row.single_entity_value : row.multi_entity_value}), {});
    
    // Metadata
    this.tww_version = tww_version;

    this.base_damage = Math.round(data.detonation_damage * (stats_scaling?.scalar_missile_damage_base ?? 1));
    this.ap_damage = Math.round(data.detonation_damage_ap * (stats_scaling?.scalar_missile_damage_ap ?? 1));
    this.is_magical = data.is_magical;
    this.ignition_amount = data.ignition_amount;
    this.detonation_radius = Math.round(data.detonation_radius * 100) / 100;
    this.phase = buildModel(Phase, data.contact_phase_effect, tww_version, cuc);
}