import React, { Fragment } from "react";

const InfoItem = (props) => <li>
    {props.icon && <img alt={props.icon} src={props.icon} />}{props.name}
    <ul>
        {React.Children.map(props.children, (c) => <li>{c}</li>)}
    </ul>
</li>

const InfoBit = (props) => <Fragment><span className="bit-content">{props.content}</span><span className="bit-source">- source: <a target='_blank' rel="noopener noreferrer" href={props.src || '#'}>{props.srcLabel || props.src}</a></span></Fragment>

export default () => (
    <div className={'info-and-mechanics'}>
        <InfoItem
            name="Hit Points"
            icon=""
        >
            <InfoBit content="" srcLabel='General Knowledge'/>
        </InfoItem>
    </div>
);