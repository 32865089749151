import gql from "graphql-tag";

export default gql`
query FatigueEffectsQuery($tww_version: String!) {
  tww(tww_version:$tww_version) {
    tww_version
    fatigue_effects {
      key
      fatigue_threshold
      fatigue_level
      scalar_speed
      stat_melee_attack
      stat_reloading
      stat_armour
      stat_charge_bonus
      stat_melee_defence
      stat_melee_damage_ap
    }
  }
}`