import gql from "graphql-tag";

export default gql`
query CharacterTraitsLevels($tww_version: String!) {
  tww(tww_version:$tww_version) {
    tww_version
    character_trait_levels{
      key
    	onscreen_name
      level
      threshold_points
      colour_text
      explanation_text
      removal_text
      trait_level_effects {
        effect {
          effect
          icon
          priority
          description
          icon_negative
          category
          is_positive_value_good
        }
        value
        effect_scope
      }
      character_trait{
        key
        no_going_back_level
        hidden
        precedence
        category {
          category
          icon_path
        }
        author
        comment
        ui_priority
        antitrait
      }
    }
  }
}`