import _ from "lodash";

export const iconRegex = /\[\[img:icon_(.*?)\]\]\[\[\/img\]\]/g;
const imgRegex = /\[\[img:(.*?)\]\]\[\[\/img\]\]/g;
export const replaceIcons = (
  text,
  tww_version,
  icon_mappings,
  icons_size = 16
) =>
  text.replace(imgRegex, (match, p1, offset, string) => {
    const mapping = icon_mappings.find((m) => m.key === p1);
    const url = (mapping && mapping.image_path) || p1;
    return `<img style='max-height: ${icons_size}px; max-width: ${icons_size}px;' src='${cloudinaryUrl()}/api/${tww_version}/${url.replace(
      ".png",
      ".webp"
    )}'/>`;
  });

const colorRegex = /\[\[col:(.*)\]\](.*?)\[\[\/col\]\]/g;
export const convertColorTagToSpan = (text) =>
  text.replace(
    colorRegex,
    (match, p1, p2, offset, string) => `<span class="color-${p1}">${p2}</span>`
  );

const boldRegex = /\[\[b\]\](.*?)\[\[\/b\]\]/g;
export const convertBoldTag = (text) =>
  text.replace(boldRegex, (match, p1, offset, string) => `<b>${p1}</b>`);

export const processTemplatedText = (
  text,
  tww_version,
  icon_mappings,
  icons_size = 16
) => {
  return convertColorTagToSpan(
    convertBoldTag(replaceIcons(text, tww_version, icon_mappings, icons_size))
  ).replace(/\|\|/g, "<br/>");
};

export const removeTemplates = (text) => text.replace(/\[\[.*?\]\]/g, "");

export const isStatPercentage = (stat) =>
  [
    // "scalar_miscast_chance",
    "stat_miscast_additional",
    "stat_missile_block_chance",
    // "stat_resistance_all",
    // "stat_resistance_flame",
    // "stat_resistance_magic",
    // "stat_resistance_missile",
    // "stat_resistance_physical",
    "stat_shield_defence",
    "stat_weakness_flame",
  ].includes(stat);

// Changes to these properties are ignored when comparing two versions
export const ignoredProperties = [
  "__typename",
  "tww_version",
  "delta_side",
  "has_deltas",
  "delta_tww_version",
  "delta_value",
  "delta_status",
  "tooltip",
  "name",
  "special_category",
  "onscreen_name",
  "category_icon",
  "category_tooltip",
];

export function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

export function isInt(value) {
  if (isNaN(value)) {
    return false;
  }
  var x = parseFloat(value);
  return !isNaN(x);
}

const deltaProps = (root, prefix, l, r) => {
  const props =
    (r && Object.getOwnPropertyDescriptors(r)) ||
    (l && Object.getOwnPropertyDescriptors(l));
  _.each(props, (p, k) => {
    if (ignoredProperties.indexOf(k) !== -1) {
      return;
    }

    let v = r && r[k];
    let v2 = l && l[k];

    if (isInt(v) || isInt(v2)) {
      v = v || 0;
      v2 = v2 || 0;

      // Quesiton to past self: Why parseint!?!
      const left =
        v2 === Infinity
          ? Infinity
          : v2 === -Infinity
          ? -Infinity
          : Math.round(v2 * 100, 10) / 100;
      const right =
        v === Infinity
          ? Infinity
          : v === -Infinity
          ? -Infinity
          : Math.round(v * 100, 10) / 100;

      // Special case, from/to infinity
      if (left === -1 && right !== -1) {
        root.has_deltas = true;
        root["delta_" + prefix + k] = "from -1";
      } else if (right === -1 && left !== -1) {
        root.has_deltas = true;
        root["delta_" + prefix + k] = `from ${left}`;
      } else if (left === Infinity && right !== Infinity) {
        root.has_deltas = true;
        root["delta_" + prefix + k] = "from Infinity";
      } else if (right === Infinity && left !== Infinity) {
        root.has_deltas = true;
        root["delta_" + prefix + k] = `from ${left}`;
      } else if (left === Infinity && right === Infinity) {
        // Nothing to do
      } else if (left === -Infinity && right !== -Infinity) {
        root.has_deltas = true;
        root["delta_" + prefix + k] = `from ${left}`;
      } else if (right === -Infinity && left !== -Infinity) {
        root.has_deltas = true;
        root["delta_" + prefix + k] = "from Infinity";
      } else if (left === -Infinity && right === -Infinity) {
        // Nothing to do
      } else {
        let delta = right - left;
        delta = Math.round(delta * 100) / 100;
        if (delta !== 0) {
          root.has_deltas = true;
          root["delta_" + prefix + k] = delta;
        }
      }
    } else if (
      v === true ||
      v === false ||
      v === "true" ||
      v === "false" ||
      v2 === true ||
      v2 === false ||
      v2 === "true" ||
      v2 === "false"
    ) {
      if (v !== v2) {
        root.has_deltas = true;
        root["delta_" + prefix + k] = v2 ? "yes" : "no";
      }
    } else if (
      (typeof v === "string" || typeof v2 === "string") &&
      (v && v.trim().replace("\\n", "")) !==
        (v2 && v2.trim().replace("\\n", ""))
    ) {
      root.has_deltas = true;
      root["delta_" + prefix + k] = v2;
    } else if (
      (v !== null || v2 !== null) &&
      (typeof v === "object" || typeof v2 === "object")
    ) {
      if (
        (v && v.constructor.name === "Array") ||
        (v2 && v2.constructor.name === "Array")
      ) {
        // nothing to do
      } else {
        deltaProps(root, prefix + k + ".", v2, v);
      }
    }
  });
};

export function deltaObject(l, r) {
  if (l === undefined) {
    r.delta_status = "new";
    return r;
  } else if (r === undefined) {
    l.delta_status = "removed";
    return l;
  } else {
    r.has_deltas = false;
    deltaProps(r, "", l, r);
    r.delta_status = r.has_deltas ? "modified" : "unchanged";
  }
  return r;
}

export function deltaObjects(left, right) {
  const lUnits = left.map((u) => {
    u.delta_side = "left";
    return u;
  });
  const rUnits = right.map((u) => {
    u.delta_side = "right";
    return u;
  });

  const all = [...lUnits, ...rUnits];

  const groups = _.groupBy(all, "key");

  const deltas = _.map(groups, (v, k) => {
    let l = _.find(v, (o) => o.delta_side === "left");
    let r = _.find(v, (o) => o.delta_side === "right");
    return deltaObject(l, r);
  });

  return _.filter(deltas, (o) => o.delta_status !== "unchanged");
}

export const additionalIcons = [
  "ui/skins/default/button_lore_beasts_active.webp",
  "ui/skins/default/button_lore_big_waaagh_active.webp",
  "ui/skins/default/button_lore_dark_magic_active.webp",
  "ui/skins/default/button_lore_death_active.webp",
  "ui/skins/default/button_lore_death_active.webp",
  "ui/skins/default/button_lore_fire_active.webp",
  "ui/skins/default/button_lore_heavens_active.webp",
  "ui/skins/default/button_lore_high_magic_active.webp",
  "ui/skins/default/button_lore_life_active.webp",
  "ui/skins/default/button_lore_light_active.webp",
  "ui/skins/default/button_lore_lil_waaagh_active.webp",
  "ui/skins/default/button_lore_metal_active.webp",
  "ui/skins/default/button_lore_nehekhara_active.webp",
  "ui/skins/default/button_lore_plague_active.webp",
  "ui/skins/default/button_lore_ruin_active.webp",
  "ui/skins/default/button_lore_shadow_active.webp",
  "ui/skins/default/button_lore_vampire_active.webp",
  "ui/skins/default/button_lore_wild_active.webp",
];

// Adding the transform to every image increases the number of "transformations" but it decreases
// the bandwith usage which is much more important (the transforms are one-off, the bandwith is constant)
export function cloudinaryUrl(cloudinaryTransforms) {
  if (process.env.REACT_APP_ENABLE_CLOUDINARY) {
    // cloudinaryTransforms = cloudinaryTransforms || [];

    // // Add some default transforms for performances and bandwitsh usage
    // cloudinaryTransforms.push("f_auto,q_auto:low");

    // const transforms = cloudinaryTransforms.join(",");
    // return transforms
    //   ? `${process.env.REACT_APP_TWWSTATS_CLOUDINARY_AUTO_UPLOAD}/${transforms}/${process.env.REACT_APP_TWWSTATS_CLOUDINARY_AUTO_UPLOAD_FOLDER}`
    //   : `${process.env.REACT_APP_TWWSTATS_CLOUDINARY_AUTO_UPLOAD}/${process.env.REACT_APP_TWWSTATS_CLOUDINARY_AUTO_UPLOAD_FOLDER}`;

    // Due to cloudinary new pricing, conversion to webp is now done locally. No transformation should be done on cloudinary anymore
    return `${process.env.REACT_APP_TWWSTATS_CLOUDINARY_AUTO_UPLOAD}/${process.env.REACT_APP_TWWSTATS_CLOUDINARY_AUTO_UPLOAD_FOLDER}`;
  } else {
    return process.env.PUBLIC_URL;
  }
}

export const casteToGroupParentMap = {
  lord: {
    icon: "commander",
    key: "commander",
    order: 1,
    mp_cap: 0,
  },
  hero: {
    icon: "hero",
    key: "heroes_agents",
    order: 2,
    mp_cap: 2,
  },
};

// From https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
export function array_move(arr, old_index, new_index) {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing purposes
}

const parseFatigue = (idx) => {
  return [
    "threshold_fresh",
    "threshold_active",
    "threshold_winded",
    "threshold_tired",
    "threshold_very_tired",
    "threshold_exhausted",
  ][idx];
};

export function applyFatigue(fatigue, key, base, commonUnitConstants) {
  if (!commonUnitConstants) {
    return base;
  }
  if (!fatigue) {
    return base;
  }

  const f = parseFatigue(fatigue);

  if (key === "stat_morale") {
    const morale_effects = _.find(commonUnitConstants.fatigue_morale_effects, {
      key: f.replace("threshold_", "ume_concerned_"),
    });
    return morale_effects ? Math.round(base + morale_effects.value) : base;
  } else {
    const effects = _.find(commonUnitConstants.fatigue_effects, {
      fatigue_level: f,
    });
    return effects[key] ? Math.round(effects[key] * base) : base;
  }
}

export const RoundFloat = (val) => Math.round(val * 100) / 100;

export default {};
