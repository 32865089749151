import { processTemplatedText } from "../services/common";

export default function Attribute(data, tww_version, cuc) {
  // metadata
  this.tww_version = tww_version;

  this.key = data.key;
  this.bullet_text = data.bullet_text;
  this.imbued_effect_text = data.imbued_effect_text;

  let [name, desc] = this.bullet_text.split("||");

  this.name = name;
  this.desc = processTemplatedText(
    desc,
    this.tww_version,
    cuc.ui_tagged_images,
    20
  );
}
