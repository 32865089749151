import gql from "graphql-tag";

export default gql`
    fragment battle_entity on battle_entity {
        key
        type
        run_speed
        mass
        hit_points
        fly_speed
        charge_speed
        flying_charge_speed
        acceleration
        deceleration
        size
        radius
        height
        hit_reactions_ignore_chance
        knock_interrupts_ignore_chance
        charge_distance_commence_run
        charge_distance_adopt_charge_pose
        charge_distance_pick_target
        combat_reaction_radius
    }
    `