import React from "react";
import { Tooltip } from "react-tippy";
import { cloudinaryUrl } from "../services/common";

const AbilityTypeIcon = ({
  name,
  desc,
  tww_version,
  icon,
  size,
  className,
}) => {
  return (
    <Tooltip
      className={className}
      position="top"
      arrow="true"
      style={{ display: "flex" }}
      html={
        <div
          style={{
            fontFamily: '"Open Sans", sans-serif',
          }}
        >
          <span style={{ fontWeight: "bold" }}>{name}</span>
          <p style={{ margin: "0" }}>{desc}</p>
        </div>
      }
    >
      <img
        alt={name}
        width={size}
        height={size}
        src={`${cloudinaryUrl()}/api/${tww_version}/${icon.replace(
          ".png",
          ".webp"
        )}`}
      />
    </Tooltip>
  );
};

export default AbilityTypeIcon;
