import gql from "graphql-tag";

export default gql`
query CampaignDifficultues($tww_version: String!) {
  tww(tww_version:$tww_version) {
    tww_version
    campaign_difficulty_handicap_effects {
      key
      campaign_difficulty_handicap
      human
      effect {
        effect
        icon
        priority
        description
        icon_negative
        category
        is_positive_value_good
      }
      effect_scope
      effect_value
      optional_campaign_key
    }
  }
}`