import React, {Component} from 'react';

export default class CardCell extends Component {
    constructor(props) {
      super(props);
  
      this.card = React.createRef();
    }
  
    componentDidMount() {
      this.props.registerCard && this.props.registerCard(this.card);
    }
  
    componentWillUnmount() {
      this.props.unregisterCard && this.props.unregisterCard(this.card);
    }
  
    render() {
      return (
        <div
          ref={this.card}
          className={`card_cell ${this.props.className || ''}`}
          style={{
            gridRow: `${this.props.row} / span 1`,
            gridColumn: this.props.col,
            position: 'relative',
            alignSelf: 'start',
            justifySelf: 'stretch',
            marginBottom: '4px',
            padding: '0px 8px',
            ...this.props.style,
          }}>
          {this.props.children}
        </div>
      );
    }
  }