import React, { useEffect, useState } from "react";
import AbilityTooltip from "./AbilityTooltip";
import TwwTooltip from "./tww-tooltip";
import styled from "styled-components";

const AbilityTooltipContainer = ({ className, overpower, ...props }) => {
  console.log("props", props);

  return (
    <div className={className}>
      <TwwTooltip>
        <Column>
          <AbilityTooltip {...props} />
        </Column>
      </TwwTooltip>
      {overpower ? (
        <TwwTooltip>
          <Column>
            <AbilityTooltip {...props} right={overpower} />
          </Column>
        </TwwTooltip>
      ) : null}
    </div>
  );
};

const Row = styled.div`
  display: flex;
  margin-bottom: 8px;
  line-height: 16px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  box-sizing: border-box;
  font-family: serif;
  color: rgba(255, 248, 215);
  font-size: 16px;
`;

export default AbilityTooltipContainer;
