import { RoundFloat } from "../services/common";

import Projectile from "./projectile";

import buildModel from "../services/buildModel";

// Over 10s
function missileDamage(projectile, reload) {
  let dmg = projectile.base_damage + projectile.ap_damage;
  if (projectile.explosion) {
    dmg += projectile.explosion.base_damage + projectile.explosion.ap_damage;
  }
  const reload_time = exactReloadTime(projectile, reload);
  const dps =
    (dmg *
      projectile.shots_per_volley *
      projectile.projectile_number *
      projectile.burst_size *
      10) /
    reload_time;
  // This used to be Math.ceil but Ancient Stegadon in Kings Shillings showed 612 instead of 611 as in the game so I changed it to round()
  return Math.round(dps);
}

function exactReloadTime(projectile, reload) {
  return projectile.base_reload_time * (1.0 - reload / 100);
}

export default function MissileWeapon(
  data,
  tww_version,
  primary_ammo,
  secondary_ammo,
  accuracy,
  reload,
  cuc,
  unitSize
) {
  if (!data) return null;

  // Metadata
  this.tww_version = tww_version;

  // PROJECTILE
  //
  this.projectile = buildModel(
    Projectile,
    data.default_projectile,
    tww_version,
    cuc,
    unitSize
  );

  // COMPUTED
  //
  const ammo = data.use_secondary_ammo_pool ? secondary_ammo : primary_ammo;
  Object.defineProperty(this, "damage", {
    get: () => missileDamage(this.projectile, reload),
    enumerable: true,
  });
  Object.defineProperty(this, "reload_time", {
    get: () => RoundFloat(exactReloadTime(this.projectile, reload)),
    enumerable: true,
  });
  Object.defineProperty(this, "total_accuracy", {
    get: () => accuracy + this.projectile.marksmanship_bonus,
    enumerable: true,
  });
  this.ammo = Math.round(
    this.projectile.shots_per_volley === 0
      ? ammo
      : ammo / this.projectile.shots_per_volley
  );
  this.phase =
    this.projectile.phase ||
    this.projectile.overhead_phase ||
    (this.projectile.explosion && this.projectile.explosion.phase);
  this.is_magical =
    this.projectile.is_magical ||
    (this.projectile.explosion && this.projectile.explosion.is_magical);
  this.ignition_amount =
    this.projectile.ignition_amount ||
    (this.projectile.explosion && this.projectile.explosion.ignition_amount);
}
