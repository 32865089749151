import Bombardment from "./bombardment";
import Explosion from "./explosion";
import Phase from "./phase";
import Projectile from "./projectile";
import SpawnedUnit from "./spawnedUnit";
import Vortex from "./vortex";
import InvalidUsageFlag from "./InvalidUsageFlag";

import buildModel from "../services/buildModel";

function getTargetTypes(data) {
  let res = [];
  if (data.target_friends) {
    res.push("Ally");
  }
  if (data.target_enemies) {
    res.push("Enemy");
  }
  if (data.target_ground) {
    res.push("Ground");
  }
  if (data.affect_self) {
    res.push("Self");
  }
  return res;
}

export default function SpecialAbility(data, tww_version, cuc) {
  this.tww_version = tww_version;

  this.key = data.key;
  this.active_time = data.active_time;
  this.recharge_time = data.recharge_time;
  this.num_uses = data.num_uses;
  this.effect_range = data.effect_range;
  this.affect_self = data.affect_self;
  this.num_effected_friendly_units = data.num_effected_friendly_units;
  this.num_effected_enemy_units = data.num_effected_enemy_units;
  this.num_targets =
    this.num_effected_friendly_units + this.num_effected_enemy_units;
  this.initial_recharge = data.initial_recharge;
  this.target_friends = data.target_friends;
  this.target_enemies = data.target_enemies;
  this.target = getTargetTypes(data);
  this.target_ground = data.target_ground;
  this.target_intercept_range = data.target_intercept_range;
  this.assume_specific_behaviour = data.assume_specific_behaviour;
  this.clear_current_order = data.clear_current_order;
  this.wind_up_time = data.wind_up_time;
  this.passive = data.passive;
  // this.unique_id = data.unique_id;
  this.wind_up_stance = data.wind_up_stance;
  this.mana_cost = data.mana_cost;
  this.min_range = data.min_range;
  this.miscast_chance = data.miscast_chance;
  this.ai_usage = data.ai_usage;
  this.additional_melee_cp = data.additional_melee_cp;
  this.additional_missile_cp = data.additional_missile_cp;
  this.mp_cost = Math.round(
    data.additional_melee_cp + data.additional_missile_cp
  );
  this.targetting_aoe = data.targetting_aoe;
  this.passive_aoe = data.passive_aoe;
  this.active_aoe = data.active_aoe;
  this.activation_effect = data.activation_effect;
  this.special_ability_display = data.special_ability_display;
  this.parent_ability = data.parent_ability;
  this.invalid_targets = data.invalid_targets;
  this.invalid_usages = data.invalid_usages;
  this.auto_deactivate_flags = data.auto_deactivate_flags?.map(
    (f) => new InvalidUsageFlag(f, tww_version, cuc)
  );
  this.update_targets_every_frame = data.update_targets_every_frame;

  // BOMBARDMENT
  //
  this.bombardment = buildModel(
    Bombardment,
    data.bombardment,
    tww_version,
    cuc
  );

  // PROJECTILE
  //
  this.projectile =
    buildModel(Projectile, data.activated_projectile, tww_version, cuc) ||
    this.bombardment?.projectile;

  // VORTEX
  //
  this.vortex = buildModel(Vortex, data.vortex, tww_version, cuc);

  // Spawned Unit
  this.spawned_unit = buildModel(SpawnedUnit, data.spawned_unit, tww_version);

  // Miscast EXPLOSION
  //
  this.miscast_explosion = buildModel(
    Explosion,
    data.miscast_explosion,
    tww_version,
    cuc
  );

  // PHASES
  //
  this.phases = data.phases?.map((v) => new Phase(v, tww_version, cuc));
}
