import { InMemoryCache, IntrospectionFragmentMatcher  } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import introspectionQueryResultData from '../generated/fragmentTypes.json';

const twwstats_broker_url = process.env.REACT_APP_TWWSTATS_BROKER_URL || "http://localhost:2000/graphql";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

function setupApollo() {
    const cache = new InMemoryCache({
      fragmentMatcher,
      addTypename: true,
      dataIdFromObject: o => null
    });
  
    return new ApolloClient({
      link: new HttpLink({
        uri: twwstats_broker_url,
        headers: { 'Cache-Control': 'max-age=86400' }
      }),
      cache,
    });
  }

  export default setupApollo();