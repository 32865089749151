import React from "react";
import _ from "lodash";
import GenericGrid from "./generic-grid"
import CharacterTraitLevelsQuery from "../graphql/character-trait-levels-query-hoc";
import VersionsCompare from "./versions-compare"
import GridControls from "./grid-controls-hoc";

function createColumnDefs(data) {
  return [
    {
      headerName: 'Name',
      field: 'onscreen_name',
      pinned: 'left',
      maxWidth: 150,
      width: 150,
      minWidth: 150,
    },
    {
      headerName: "Level",
      type: ['integerColumn'],
      field: 'level'
    },
    {
      headerName: "Category",
      field: 'character_trait.category.icon_path',
      cellRenderer: 'TraitIconRenderer'
    },
    {
      headerName: "Explanation",
      type: ['stringColumn'],
      maxWidth: 200,
      width: 200,
      minWidth: 200,
      cellRenderer: 'StringWithIconsRenderer',
      field: 'explanation_text'
    },
    {
      headerName: "Effects",
      maxWidth: 600,
      width: 600,
      minWidth: 600,
      field: 'trait_level_effects',
      cellRenderer: 'TraitLevelEffectsRenderer'
    },
    {
      headerName: "Fluff",
      type: ['stringColumn'],
      maxWidth: 300,
      width: 300,
      minWidth: 300,
      field: 'colour_text'
    },
    {
      headerName: "Comment",
      type: ['stringColumn'],
      field: 'character_trait.comment'
    },
    {
      headerName: "Trait Level Key",
      type: ['keyColumn'],
      field: 'key',
    },
    {
      headerName: "Trait Key",
      type: ['keyColumn'],
      field: 'character_trait.key',
    },
    {
      headerName: "Antitrait Key",
      type: ['keyColumn'],
      field: 'character_trait.antitrait'
    },
  ]
}

function dataLoaded(data) {
  return data;
}

const Grid = (props) => (
    <VersionsCompare
      {...props}
      rowHeight={160}
      query={CharacterTraitLevelsQuery}
      dataKey={'character_trait_levels'}
      dataLoaded={dataLoaded}
      sortData={(data) => _.orderBy(data, [o => o.key], ['asc'])}
      createColumnDefs={createColumnDefs}
      WrappedComponent={GenericGrid}
    />
  )

const CharacterTraitLevels = (props) => (<GridControls {...props} WrappedComponent={Grid} />)

export default CharacterTraitLevels