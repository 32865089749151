import Attribute from "./attribute";
import MeleeWeapon from "./melee-weapon";
import MissileWeapon from "./missile-weapon";

import _ from "lodash";
import {
  factionsSortValue,
  casteSortValue,
  globalSettings,
} from "../services/twwstats-services";
import { applyFatigue, RoundFloat } from "../services/common";

import buildModel from "../services/buildModel";

const unitSizeMultiplier = {
  small: 0.25,
  medium: 0.5,
  large: 0.75,
  ultra: 1,
};

function unitHealth(data) {
  const lu = data.land_unit;

  const caste = data.caste.toLowerCase();

  const engine_count = lu.num_engines;
  const engine_hp = lu.engine !== null ? lu.engine.battle_entity.hit_points : 0;
  const engine_type = lu.engine !== null ? lu.engine.battle_entity.type : null;

  const av_hp = lu.articulated_vehicle_entity
    ? lu.articulated_vehicle_entity.hit_points
    : 0;

  const mount_count = lu.num_mounts;
  const mount_hp = lu.mount !== null ? lu.mount.battle_entity.hit_points : 0;

  const unit_count = data.num_men;
  const unit_hp = lu.battle_entity.hit_points;
  const unit_type = lu.battle_entity.type;

  const bonus = lu.bonus_hit_points;

  let hp = 0;
  if (engine_count) {
    // I hope someday I can sit down with a CA dev that explains to me how in hell this is actually computed in the game :P
    // Tests and experimentations in https://docs.google.com/spreadsheets/d/16MUdJds1PM7poKy6bUrzpbcNRbmV68y9NonT5h_TJlE/edit#gid=1621620210
    if (
      caste === "war machine" &&
      unit_type === "man" &&
      engine_type !== "chariot"
    ) {
      // - M1 from the spreadsheet
      // - As of Paunch & Warden no unit matching this had a mount or articulated_vehicle so I exclude them from the equation here
      // but it's possible they need to be taken into account if such a case ever arise
      hp = unit_count * (unit_hp + bonus) + engine_count * (engine_hp + bonus);
    } else if (caste === "chariot") {
      // - M4 from the spreadsheet
      hp =
        unit_count * unit_hp +
        mount_count * engine_count * mount_hp +
        engine_count * (engine_hp + bonus) +
        engine_count * av_hp;
    } else {
      // Lord, Hero and "weird" War Machines
      // - M3 from the spreadsheet
      hp =
        unit_count * unit_hp +
        mount_count * mount_hp +
        engine_count * (engine_hp + bonus) +
        engine_count * av_hp;
    }
  } else if (mount_count) {
    if (
      caste === "melee cavalry" ||
      caste === "missile cavalry" ||
      caste === "war beast"
    ) {
      // Using unit_count for mounts as well as it appears some units like squig hoppers have less mounts than units strangely and using mount_count
      // gives invalid value in this case. It might actually be JUST the squig hoppers and their ROR
      hp = unit_count * unit_hp + unit_count * (mount_hp + bonus);
    } else {
      hp = unit_count * unit_hp + mount_count * (mount_hp + bonus);
    }
  } else {
    hp = unit_count * (unit_hp + bonus);
  }

  return Math.round(hp * unitSizeMultiplier[globalSettings().unit_size]);
}

function missileWeapon(lu, primary) {
  const engineWeapon = lu.engine && lu.engine.missile_weapon;
  const luWeapon = lu.primary_missile_weapon;

  const officerWeapon =
    lu.officers &&
    lu.officers.additionnal_personalities &&
    lu.officers.additionnal_personalities
      .map(
        (p) =>
          p.battle_entity_stats && p.battle_entity_stats.primary_missile_weapon
      )
      .find((w) => w);

  if (engineWeapon && engineWeapon.use_secondary_ammo_pool === !primary) {
    return engineWeapon;
  }
  if (luWeapon && luWeapon.use_secondary_ammo_pool === !primary) {
    return luWeapon;
  }
  if (officerWeapon && officerWeapon.use_secondary_ammo_pool === !primary) {
    return officerWeapon;
  }
  return null;
}

function nameAndMount(data) {
  let name_and_mount = data.land_unit.onscreen_name;
  if (data.mount_name !== null) {
    name_and_mount += ` on ${data.mount_name}`;
  }
  return name_and_mount;
}

function unitSize(data) {
  const lu = data.land_unit;
  const caste = data.caste.toLowerCase();

  let size = 0;
  if (lu.engine) {
    size = lu.num_engines;
  } else if (lu.mount) {
    if (
      caste === "melee cavalry" ||
      caste === "missile cavalry" ||
      caste === "war beast"
    ) {
      size = data.num_men;
    } else {
      size = lu.num_mounts;
    }
  } else {
    size = data.num_men;
  }

  return Math.ceil(size * unitSizeMultiplier[globalSettings().unit_size]);
}

function mainBattleEntity(data) {
  const lu = data.land_unit;
  if (lu.mount !== null) {
    return lu.mount.battle_entity;
  } else if (lu.engine !== null) {
    return lu.engine.battle_entity;
  } else {
    return lu.battle_entity;
  }
}

function walkSpeed(data) {
  return mainBattleEntity(data).walk_speed * 10;
}

function runSpeed(data) {
  return mainBattleEntity(data).run_speed * 10;
}

function flySpeed(data) {
  return mainBattleEntity(data).fly_speed * 10;
}

function entitySize(data) {
  return mainBattleEntity(data).size;
}

function unitMass(data) {
  let lu = data.land_unit;

  let engine_count = lu.num_engines;
  let engine_mass =
    engine_count > 0 && lu.engine !== null ? lu.engine.battle_entity.mass : 0;

  // Each engine receives the number of mounts specified (i.e. chariots)
  let mount_count =
    engine_count > 0 ? lu.num_mounts * engine_count : lu.num_mounts;
  let mount_mass =
    mount_count > 0 && lu.mount !== null ? lu.mount.battle_entity.mass : 0;

  // Thx to rdpmatt from discord for this find!
  let articulated_vehicle_mass = lu.articulated_vehicle_entity
    ? lu.articulated_vehicle_entity.mass
    : 0;

  let unit_mass = lu.battle_entity.mass;

  let mass =
    (articulated_vehicle_mass ?? 0) ||
    (engine_mass ?? 0) ||
    (mount_mass ?? 0) ||
    (unit_mass ?? 0);

  return Math.round(mass);
}

function unitMassOld(data) {
  let lu = data.land_unit;

  let engine_count = lu.num_engines;
  let engine_mass =
    engine_count > 0 && lu.engine !== null ? lu.engine.battle_entity.mass : 0;

  // Each engine receives the number of mounts specified (i.e. chariots)
  let mount_count =
    engine_count > 0 ? lu.num_mounts * engine_count : lu.num_mounts;
  let mount_mass =
    mount_count > 0 && lu.mount !== null ? lu.mount.battle_entity.mass : 0;

  // Thx to rdpmatt from discord for this find!
  let articulated_vehicle_mass = lu.articulated_vehicle_entity
    ? lu.articulated_vehicle_entity.mass
    : 0;

  let unit_mass = lu.battle_entity.mass;

  let mass =
    (articulated_vehicle_mass ?? 0) +
    (engine_mass ?? 0) +
    (mount_mass ?? 0) +
    (unit_mass ?? 0);

  return Math.round(mass);
}

function adjustByRank(unit, base, key, expBonuses) {
  if (!expBonuses) {
    return base;
  }

  const bonuses = expBonuses.find((o) => o.stat === key);

  return expBonus(
    unit.caste,
    unit.ror,
    base,
    bonuses.growth_rate,
    bonuses.growth_scalar,
    unit.rank
  );
}

function multiplayerCost(unit, base, cuc) {
  const expBonuses = cuc && cuc.unit_stats_land_experience_bonuses;
  if (!expBonuses) {
    return base;
  }
  if (
    unit.caste.toLowerCase() === "lord" ||
    unit.caste.toLowerCase() === "hero"
  ) {
    return base;
  }
  if (unit.ror) {
    return base;
  }

  const rankBonuses = _.find(expBonuses, { xp_level: unit.rank });
  return (
    base * rankBonuses.mp_experience_cost_multiplier + rankBonuses.mp_fixed_cost
  );
}

function expBonus(caste, ror, base, growth, scalar, rank) {
  if (caste.toLowerCase() === "lord" || caste.toLowerCase() === "hero") {
    return base;
  }
  if (ror) {
    return base;
  }

  return Math.round(base + Math.pow(base, growth) * scalar * rank);
}

export const sortUnits = (units) =>
  _.orderBy(
    units,
    [(o) => factionsSortValue(o.factions), (o) => casteSortValue(o.caste)],
    ["desc", "asc"]
  );

// Model for main_units entries
export default function Unit(data, tww_version, cuc, version, rank, fatigue) {
  // METADATA
  //
  this.tww_version = tww_version;
  this.version_name = version && version.name;
  this.rank = (rank && parseInt(rank, 10)) || 0;
  this.fatigue = (fatigue && parseInt(fatigue, 10)) || 0;

  // MAIN UNIT
  //
  this.key = data.unit;
  this.special_category = data.unit_sets.reduce(
    (acc, cur) => acc || cur.special_category,
    ""
  );
  this.ror = this.special_category === "renown";
  this.elector = this.special_category === "elector_counts";
  this.blessed = this.special_category === "blessed_spawning";
  this.crafted = this.special_category === "crafted";
  this.tech = this.special_category === "tech_lab";
  this.factions = data.factions;
  this.caste = data.caste;
  this.category = data.ui_unit_group?.name;
  this.category_icon = data.ui_unit_group?.icon;
  this.category_tooltip = data.ui_unit_group?.tooltip;
  this.multiplayer_cost = Math.round(
    multiplayerCost(this, data.multiplayer_cost, cuc)
  );
  this.fatigue_modifier =
    cuc && cuc.unit_stats_land_experience_bonuses
      ? _.find(cuc.unit_stats_land_experience_bonuses, { xp_level: this.rank })
          .fatigue
      : 0;
  this.singleplayer_cost = data.recruitment_cost;
  this.singleplayer_upkeep = data.upkeep_cost;
  this.weight = data.weight;
  this.create_time = data.create_time;
  this.tier = data.tier;
  this.battle_mounts = data.battle_mounts;
  this.bullet_points = _.sortBy(data.bullet_points, ["sort_order"]);
  this.is_high_threat = data.is_high_threat;
  this.can_siege = data.can_siege;
  // This was wrong... it caught the abilties MODIFIED by the items but there doens't seem to be any item that GRANT an ability
  // Only use the first custom_battle_permissions otherwise you will get duplicate items for units that belong to multiple factions
  // this.items = null;
  // if (data.custom_battle_permissions && data.custom_battle_permissions.length && data.custom_battle_permissions[0].set_piece_character) {
  //   this.items = _.flattenDeep(data.custom_battle_permissions[0].set_piece_character.ancillaries.map(a => a.ancillary_effects.map(e => e.effect.abilities).filter(a => a.bonus_value_id === 'enabled')) || []);
  // }
  this.barrier_health = Math.round(
    data.barrier_health * unitSizeMultiplier[globalSettings().unit_size]
  );

  // COMPUTED PROPS
  //
  this.name = nameAndMount(data);
  this.unit_size = unitSize(data);
  this.health = unitHealth(data);
  this.mass = unitMass(data);
  Object.defineProperty(this, "health_per_entity", {
    get: () => Math.round(this.health / this.unit_size),
    enumerable: true,
  });
  Object.defineProperty(this, "walk_speed", {
    get: () =>
      Math.round(
        applyFatigue(this.fatigue, "scalar_speed", walkSpeed(data), cuc)
      ),
    enumerable: true,
  });
  Object.defineProperty(this, "run_speed", {
    get: () =>
      Math.round(
        applyFatigue(this.fatigue, "scalar_speed", runSpeed(data), cuc)
      ),
    enumerable: true,
  });
  Object.defineProperty(this, "fly_speed", {
    get: () =>
      Math.round(
        applyFatigue(this.fatigue, "stat_armour", flySpeed(data), cuc)
      ),
    enumerable: true,
  });
  Object.defineProperty(this, "speed", {
    get: () => this.fly_speed || this.run_speed,
    enumerable: true,
  });
  this.turn_speed = Math.round(mainBattleEntity(data).turn_speed * 10);
  this.charge_speed = Math.round(mainBattleEntity(data).charge_speed * 10);
  this.flying_charge_speed = Math.round(
    mainBattleEntity(data).flying_charge_speed * 10
  );
  // TODO #pack_assemblykit_discrepency
  // this.charge_distance_commence_run = mainBattleEntity(data).charge_distance_commence_run;
  // TODO #pack_assemblykit_discrepency
  // this.charge_distance_adopt_charge_pose = mainBattleEntity(data).charge_distance_adopt_charge_pose;
  // TODO #pack_assemblykit_discrepency
  // this.charge_distance_pick_target = mainBattleEntity(data).charge_distance_pick_target;
  this.acceleration = mainBattleEntity(data).acceleration * 10;
  this.deceleration = mainBattleEntity(data).deceleration * 10;
  // TODO #pack_assemblykit_discrepency
  // this.radius = RoundFloat(mainBattleEntity(data).radius)
  this.height = RoundFloat(mainBattleEntity(data).height);
  // To make the unit throw units around from collision during an animation (like when a dragon backs up and causes units behind it to fly away;
  // it's not actually attacking the units behind it), change the combat_reaction_radius under the battle_entities table.
  // A higher number means the unit will collide with more entities and knock them around.
  // SOURCE: http://www.twcenter.net/forums/showthread.php?737659-What-controls-how-many-units-a-single-unit-can-hit
  this.combat_reaction_radius = RoundFloat(
    mainBattleEntity(data).combat_reaction_radius
  );
  this.hit_reactions_ignore_chance =
    mainBattleEntity(data).hit_reactions_ignore_chance;
  this.knock_interrupts_ignore_chance =
    mainBattleEntity(data).knock_interrupts_ignore_chance;
  this.entity_size = entitySize(data);

  // LAND UNIT
  //
  const lu = data.land_unit;
  // this.land_unit = lu;
  this.short_description = lu.short_description_text;
  Object.defineProperty(this, "accuracy", {
    get: () =>
      adjustByRank(
        this,
        lu.accuracy,
        "stat_accuracy",
        cuc && cuc.unit_experience_bonuses
      ),
    enumerable: true,
  });
  Object.defineProperty(this, "reload", {
    get: () =>
      applyFatigue(
        this.fatigue,
        "stat_reloading",
        adjustByRank(
          this,
          lu.reload,
          "stat_reloading",
          cuc && cuc.unit_experience_bonuses
        ),
        cuc
      ),
    enumerable: true,
  });
  this.ground_stat_effect_group = lu.ground_stat_effect_group;
  // Damage computation = Damage * armor_save * Sum(all_applicable_resistances)
  // SOURCE: https://forums.totalwar.com/discussion/204356/armor-resistances-magical-physical-missile-ward-saves-question
  Object.defineProperty(this, "armour", {
    get: () =>
      applyFatigue(this.fatigue, "stat_armour", lu.armour.armour_value, cuc),
    enumerable: true,
  });
  this.parry_chance = lu.shield ? lu.shield.parry_chance : 0;
  Object.defineProperty(this, "leadership", {
    get: () =>
      applyFatigue(
        this.fatigue,
        "stat_morale",
        adjustByRank(
          this,
          lu.morale,
          "stat_morale",
          cuc && cuc.unit_experience_bonuses
        ),
        cuc
      ),
    enumerable: true,
  });
  this.is_large = !/small/i.test(this.entity_size); // Matches very_small and small
  Object.defineProperty(this, "melee_attack", {
    get: () =>
      applyFatigue(
        this.fatigue,
        "stat_melee_attack",
        adjustByRank(
          this,
          lu.melee_attack,
          "stat_melee_attack",
          cuc && cuc.unit_experience_bonuses
        ),
        cuc
      ),
    enumerable: true,
  });
  Object.defineProperty(this, "melee_defence", {
    get: () =>
      applyFatigue(
        this.fatigue,
        "stat_melee_defence",
        adjustByRank(
          this,
          lu.melee_defence,
          "stat_melee_defence",
          cuc && cuc.unit_experience_bonuses
        ),
        cuc
      ),
    enumerable: true,
  });
  Object.defineProperty(this, "charge_bonus", {
    get: () =>
      applyFatigue(this.fatigue, "stat_charge_bonus", lu.charge_bonus, cuc),
    enumerable: true,
  });
  // Resistance "Add up" and are hard capped between _kv_rules.ward_save_min_value and _kv_rules.ward_save_max_value
  // FYI flaming damage isn't a damage type. It's an extra bonus which is applied after the physical/magical split. So if a weapon is magical and deals fire damage first magical resistance is applied, then after that fire resistance is applied.
  // SOURCE: https://www.reddit.com/r/totalwar/comments/78ozz5/total_war_warhammer_armour_vs_damage_reduction/
  this.damage_mod_flame = lu.damage_mod_flame;
  this.damage_mod_magic = lu.damage_mod_magic;
  this.damage_mod_physical = lu.damage_mod_physical;
  this.damage_mod_missile = lu.damage_mod_missile;
  this.damage_mod_all = lu.damage_mod_all;
  this.abilities = lu.abilities;
  this.spells = lu.special_ability_groups
    ? [].concat.apply(
        [],
        lu.special_ability_groups.map((g) => g.abilities)
      )
    : [];
  this.attributes = lu.attributes
    ? lu.attributes.map((a) => new Attribute(a, tww_version, cuc))
    : [];
  this.can_skirmish = lu.can_skirmish;
  this.unit_card =
    (data.custom_battle_permissions &&
      data.custom_battle_permissions[0]?.general_portrait
        ?.toLowerCase()
        .replace("portholes", "units")
        .replace(".png", ".webp")) ||
    `ui/units/icons/${lu.variant?.unit_card_url || this.key}.webp`;

  // PRIMARY MELEE WEAPON
  //
  this.primary_melee_weapon = buildModel(
    MeleeWeapon,
    lu.primary_melee_weapon,
    tww_version,
    this.fatigue,
    cuc,
    this.unit_size
  );

  // PRIMARY MISSILE WEAPON
  //
  this.primary_missile_weapon = buildModel(
    MissileWeapon,
    missileWeapon(lu, true),
    tww_version,
    lu.primary_ammo,
    lu.secondary_ammo,
    this.accuracy,
    this.reload,
    cuc,
    this.unit_size
  );

  // SECONDARY MISSILE WEAPON
  //
  this.secondary_missile_weapon = buildModel(
    MissileWeapon,
    missileWeapon(lu, false),
    tww_version,
    lu.primary_ammo,
    lu.secondary_ammo,
    this.accuracy,
    this.reload,
    cuc,
    this.unit_size
  );
}
