import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from 'react-apollo'
import { deltaObjects, ignoredProperties, isInt } from "../services/common";
import _ from "lodash";
import commonUnitConstants from '../graphql/common-unit-constants-query-hoc';

const BATCH_SIZE = 500

class Dummy {
  constructor(raw, tww_version) {
    Object.assign(this, raw);
    this.tww_version = tww_version;
  }
}

const VersionCompare = ({tww_version_left, tww_version_right, location, history, columnTypes, rowHeight, defaultColDef, rowKey, queryVariables, query, settings, sortData, dataClass, createColumnDefs, quickFilterText, onCellClicked, dataLoaded, dataKey, WrappedComponent}) => {
  const [data, setData] = useState(null)
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])
  const [leftReady, setLeftReady] = useState(false)
  const [rightReady, setRightReady] = useState(false)
  const [same, setSame] = useState(false)

  const defaultSortData = (data) => {
    return data;
  }

  const actualSortData = sortData || defaultSortData
  const actualDataClass = dataClass || Dummy

  const defaultCreateColumnDefs = (data) => {
    let colDefs = [];

    if (!data) { return []; }

    var row = data[0];

    if (!row) { return []; }

    const names = Object.getOwnPropertyNames(row);
    _.each(names, (v, i) => {
      if (ignoredProperties.indexOf(v) === -1) {
        let colDef = {
          headerName: v,
          field: v,
        };

        if (v === 'key' || v === 'id') {
          colDef.pinned = 'left';
          colDef.type = ['keyColumn']
        }

        colDef.type = [];

        if (isInt(row[v])) {
          colDef.type.push('integerColumn');
        }

        colDefs.push(colDef);
      }
    });

    return colDefs;
  }

  const actualCreateColumnDefs = (data, version_left) => {
    let colDefs = (createColumnDefs && createColumnDefs()) || defaultCreateColumnDefs(data)
    if (version_left) {
      colDefs.splice(1, 0, {
        headerName: 'Status',
        type: ['deltaStatusColumn']
      });
    }
    return colDefs;
  }

  const qv = useMemo(() => { 
    let qv = {...queryVariables}
    if (qv.batch) {
      qv.offset = 0
      qv.size = BATCH_SIZE
    }
    return qv
  }, [queryVariables])

  const {data: leftData, error: leftError, loading: leftLoading, refetch: leftRefetch, variables: leftVariables} = useQuery(query, {
    skip: !tww_version_left,
    variables: {
      tww_version: tww_version_left,
      ...qv
    }
  })

  const {data: rightData, error: rightError, loading: rightLoading, refetch: rightRefetch, variables: rightVariables} = useQuery(query, {
    skip: !tww_version_right,
    variables: {
      tww_version: tww_version_right,
      ...qv
    }
  })

  const {data: cucLeftData, error: cucLeftError, loading: cucLeftLoading} = useQuery(commonUnitConstants, {
    skip: !tww_version_left,
    variables: {
      tww_version: tww_version_left
    }
  })

  const {data: cucRightData, error: cucRightError, loading: cucRightLoading} = useQuery(commonUnitConstants, {
    skip: !tww_version_right,
    variables: {
      tww_version: tww_version_right
    }
  })

  // Query error handling
  useEffect(() => {
    if(leftError) {
      console.error(leftError)
    }
    if(rightError) {
      console.error(rightError)
    }
  }, [leftError, rightError])

  // Reset right when version changes or queryVariables change
  useEffect(() => {
      setRight([])
      setRightReady(false)
      setData(null)
      setSame(false)
  }, [qv, tww_version_right])

  // Reset left when version changes
  useEffect(() => {
    setLeft([])
    setLeftReady(false)
    setData(null)
    setSame(false)
  }, [qv, tww_version_left])

  // Load right version data
  useEffect(() => {
    if(!rightReady && 
      rightData && 
      !rightLoading && 
      (!qv.batch || rightVariables.offset === right.length) && 
      rightData.tww.tww_version === tww_version_right) {

      const newData = rightData.tww[dataKey]
      setRight(prev => [...prev, ...newData])

      if(qv.batch && newData.length === BATCH_SIZE) {
        rightRefetch({
          tww_version: tww_version_right,
          ...qv,
          offset: rightVariables.offset + BATCH_SIZE
        })
      } else {
        setRightReady(true)
      }
    }
  }, [tww_version_right, rightData, rightLoading, rightReady, dataKey, qv, rightRefetch, right, rightVariables])

  // Load left version data
  useEffect(() => {
    if(!leftReady && 
      leftData && 
      !leftLoading && 
      (!qv.batch || leftVariables.offset === left.length) && 
      leftData.tww.tww_version === tww_version_left) {

      const newData = leftData.tww[dataKey]
      setLeft(prev => [...prev, ...newData])

      if(qv.batch && newData.length === BATCH_SIZE) {
        leftRefetch({
          tww_version: tww_version_left,
          ...qv,
          offset: leftVariables.offset + BATCH_SIZE
        })
      } else {
        setLeftReady(true)
      }
    }
  }, [tww_version_left, leftData, leftLoading, leftReady, dataKey, leftRefetch, qv, left, leftVariables])

  // Update the data once all required versions are ready
  useEffect(() => {
    if(!data) {
      if((!tww_version_left || leftReady) && rightReady) {
        if (tww_version_left && tww_version_right) {
          if (right != null && left != null) {
            if(!cucLeftLoading && cucLeftData && !cucRightLoading && cucRightData) {
              let d = actualSortData(deltaObjects(left.map(row => new actualDataClass(row, tww_version_left, cucLeftData.tww)), right.map(row => new actualDataClass(row, tww_version_right, cucRightData.tww))));
              d = dataLoaded ? dataLoaded(d) : d;
    
              setData(d)
              setSame(d.length === 0)
            }
          }
        } else {
          if (right != null) {
            if(!cucRightLoading && cucRightData) {
              let d = right.map(row => new actualDataClass(row, tww_version_right, cucRightData.tww))
              d = actualSortData(d);
              d = dataLoaded ? dataLoaded(d) : d;
    
              setData(d)
              setSame(false)
            }
          }
        }
      }
    }
  }, [rightReady, leftReady, right, left, actualDataClass, actualSortData, dataLoaded, data, tww_version_left, tww_version_right, cucLeftData, cucRightData])

  if (same) { return (<p>The data is the same in both versions. Nothing to display</p>); }

  return (
    <WrappedComponent
      location={location}
      history={history}
      columnTypes={columnTypes}
      rowHeight={rowHeight}
      defaultColDef={defaultColDef}
      rowKey={rowKey || 'key'}
      queryVariables={queryVariables}

      tww_version_left={tww_version_left}
      tww_version_right={tww_version_right}

      loading={!data}
      data={data || []}

      columnDefs={actualCreateColumnDefs(data, tww_version_left, tww_version_right)}
      quickFilterText={quickFilterText}
      onCellClicked={onCellClicked}
    >
    </WrappedComponent>
  )
}

export default VersionCompare