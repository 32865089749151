import _ from "lodash";
import buildModel from "../services/buildModel";
import { specialAbilityGroupSortValue } from "../services/twwstats-services";
import Phase from "./phase";
import SpecialAbility from "./specialAbility";

function filterSpecialAbilityGroups(groups) {
  if (!groups) {
    return null;
  }

  groups = _.filter(
    groups,
    (o) => o.icon_path.indexOf("wh2_main_lore_passive_combined") === -1
  );
  groups = _.sortBy(groups, (o) => o.button_name);
  groups = _.uniqBy(groups, "button_name");
  return groups;
}

function getDominantPhase(phases) {
  return phases.length ? phases.filter((o) => !/_buffer$/.test(o.id))[0] : null;
}

// TODO
export const sortAbilities = (abilities) =>
  _.orderBy(
    abilities,
    [
      (a) => specialAbilityGroupSortValue(a.special_ability_groups),
      (a) => a.name,
    ],
    ["desc", "asc"]
  );

export default function Ability(data, tww_version, cuc) {
  // metadata
  this.tww_version = tww_version;

  this.key = data.key;
  this.name = data.name;
  const level = /_([0-9]+)$/.exec(this.key);
  // Add number if the name does not already include it
  if (level && !/[0-9]$/.test(this.name)) {
    this.name = `${this.name} ${level[1]}`;
  }

  this.icon_name = data.icon_name;
  this.tooltip = data.tooltip;

  this.supercedes_ability = data.supercedes_ability;
  this.requires_effect_enabling = data.requires_effect_enabling;
  this.is_unit_upgrade = data.is_unit_upgrade;
  this.is_hidden_in_ui = data.is_hidden_in_ui;

  this.overpower_option = data.overpower_option
    ? new Ability(data.overpower_option, tww_version, cuc)
    : null;
  this.has_overcast = this.overpower_option ? true : false;
  this.is_overcast = /( Upgraded)$|(Master Rune)/.test(this.name)
    ? true
    : false;
  this.overcast_level = this.is_overcast ? 2 : this.has_overcast ? 1 : 0;
  this.special_ability_groups = filterSpecialAbilityGroups(
    data.special_ability_groups
  );
  this.uniqueness = data.uniqueness; // This could potentially be an object with info like color and flavor text
  this.rarity = data.uniqueness?.replace("wh_main_anc_group_", "");
  this.additional_ui_effects = data.additional_ui_effects
    ?.sort((a, b) => a.sort_order - b.sort_order)
    .map((e) => e.localised_text);

  // Ability Type
  this.type_key = data.type?.key;
  this.type_icon = data.type?.icon;
  this.type_onscreen_name = data.type?.onscreen_name;
  this.type_name = data.type?.onscreen_name.replace(/\[\[.*\]\]/, "");

  // PHASES
  //
  this.phases = data.phases?.map((v) => new Phase(v, tww_version, cuc));

  // SPECIAL ABILITY
  //
  this.special_ability = buildModel(
    SpecialAbility,
    data.unit_special_ability,
    tww_version,
    cuc
  );

  // Dominant Phase
  this.dominant_phase = getDominantPhase([
    ...(this.phases ?? []),
    ...(this.special_ability?.phases ?? []),
  ]);
}
