import gql from "graphql-tag";

export default gql`
query KVQuery($tww_version: String!, $name: String!) {
  tww(tww_version:$tww_version) {
    tww_version
    kv(name: $name) {
      key
      value
      description
    }
  }
}`