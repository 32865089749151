import React from "react";
import GenericGrid from "./generic-grid"
import unitsQuery from "../graphql/units-query-hoc";
import VersionsCompare from "./versions-compare"
import Unit, { sortUnits } from "../models/unit";
import createColumnDefs from "../colDefs/unit-coldefs";
import GridControls from "./grid-controls-hoc";

const Grid = props => <VersionsCompare
  {...props}
  query={unitsQuery}
  dataKey={'units'}
  sortData={sortUnits}
  dataClass={Unit}
  createColumnDefs={createColumnDefs}
  WrappedComponent={GenericGrid}
  columnTypes={{}}
  defaultColDef={{
    minWidth: 40,
    width: 40,
    maxWidth: 40,
  }}
  rowHeight={72}
/>

const UnitsGrid = (props) => (<GridControls {...props} WrappedComponent={Grid} />)

export default UnitsGrid