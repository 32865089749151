import gql from "graphql-tag";

export default gql`
  query CommonUnitConstantsQuery($tww_version: String!) {
    tww(tww_version: $tww_version) {
      tww_version
      unit_experience_bonuses {
        stat
        value
        growth_rate
        growth_scalar
      }
      unit_stat_to_size_scaling_values {
        stat
        size
        single_entity_value
        multi_entity_value
      }
      fatigue_effects {
        fatigue_threshold
        fatigue_level
        scalar_speed
        stat_melee_attack
        stat_reloading
        stat_armour
        stat_charge_bonus
        stat_melee_defence
        stat_melee_damage_ap
      }
      fatigue_morale_effects {
        key
        value
        description
      }
      unit_stats_land_experience_bonuses {
        xp_level
        fatigue
        mp_fixed_cost
        mp_experience_cost_multiplier
        additional_melee_cp
        additional_missile_cp
      }
      ui_tagged_images {
        key
        image_path
      }
      kv(name: "unit_ability_scaling_rules") {
        key
        value
      }
      unit_stat_localisations {
        stat_key
        onscreen_name
        tooltip_text
      }
      attributes {
        key
        bullet_text
        imbued_effect_text
      }
    }
  }
`;
