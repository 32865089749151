import React from 'react';
import FontAwesome from 'react-fontawesome';

const TwwAccordionTitle = ({ title, value, expanded, accordionChanged }) => (
    <h2 className="accordion__title" style={{ textAlign: 'center', padding: '8px 0px', margin: '0', cursor: 'pointer' }} onClick={accordionChanged}>
        {expanded ? <FontAwesome name='minus-circle' /> : <FontAwesome name='plus-circle' />}&nbsp;
        {title}{value ? `: ${value}` : ''}
    </h2>
);

export default TwwAccordionTitle