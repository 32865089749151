import AbilityTypeIcon from "./abilitytypeicon";
import { useDebounce } from "@react-hook/debounce";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { useGameVersions } from "../context/game-versions-context";
import { cloudinaryUrl, removeTemplates } from "../services/common";
import AccordionBodyContent from "./common/AcordionBodyContent";
import GameVersionTile from "./common/GameVersionTile";
import TwwAccordionTitle from "./common/TwwAccordionTitle";
import GenericButton from "./generic-button";
import GenericIcon from "./genericicon";
import styled from "styled-components";

const MIN_VERSION_SEARCH_LENGTH = 2;

const AbilityFinder = ({ defaultVersion, onConfirm }) => {
  const { versions } = useGameVersions();

  const [version, setVersion] = useState(defaultVersion);
  const [abilities, setAbilities] = useState(null);
  const [groups, setGroups] = useState(null);
  const [selectedAbilities, setSelectedAbilities] = useState([]);
  const [expanded, setExpanded] = useState(defaultVersion ? 1 : 0); // 0 = versions, 1 = groups/abilities
  const [versionAbilitiesSearch, setVersionAbilitiesSearch] = useDebounce(
    "",
    400
  );

  const [
    loadVersionGroups,
    {
      loading: groupsLoading,
      data: groupsData,
      refetch: refetchGroups,
      called: groupsCalled,
    },
  ] = useLazyQuery(specialAbilityGroupsQuery);

  const [
    loadVersionAbilities,
    {
      loading: versionAbilitiesLoading,
      data: versionAbilitiesData,
      refetch: refetchVersionAbilities,
      called: versionAbilitiesCalled,
    },
  ] = useLazyQuery(versionAbilitiesQuery);

  const selectVersion = (newVersion) => {
    setVersion(newVersion);
    setAbilities(null);
    setGroups(null);
    setSelectedAbilities([]);
    setExpanded(1);
  };

  const selectAbility = (ability) => {
    setSelectedAbilities((prev) => {
      const match = prev.find((a) => a.key === ability.key);
      if (match) {
        return prev.filter((a) => a.key !== ability.key);
      } else {
        return [...prev, ability];
      }
    });
  };

  const addSelectedAbilities = (close, e) => {
    onConfirm(version, selectedAbilities, close, e);
    setSelectedAbilities([]);
  };

  useEffect(() => {
    // 3 is pretty arbitrary but it's to ensure we don't request all the units by typing just a letter
    if (
      version &&
      versionAbilitiesSearch &&
      versionAbilitiesSearch.length >= MIN_VERSION_SEARCH_LENGTH
    ) {
      const variables = {
        tww_version: version.id,
        query: versionAbilitiesSearch,
      };
      if (!versionAbilitiesCalled) {
        loadVersionAbilities({ variables });
      } else {
        refetchVersionAbilities(variables);
      }
    }
  }, [
    versionAbilitiesSearch,
    loadVersionAbilities,
    refetchVersionAbilities,
    versionAbilitiesCalled,
    version,
  ]);

  useEffect(() => {
    // Clear abilities if user clears the search
    if (!versionAbilitiesSearch) {
      setAbilities(null);
    }
  }, [versionAbilitiesSearch]);

  // Store the loaded abilities in state
  useEffect(() => {
    if (
      versionAbilitiesSearch &&
      !versionAbilitiesLoading &&
      versionAbilitiesData
    ) {
      setAbilities(versionAbilitiesData.tww.abilities);
    }
  }, [versionAbilitiesSearch, versionAbilitiesLoading, versionAbilitiesData]);

  // Load the groups when the user selects a version
  useEffect(() => {
    if (version) {
      const variables = {
        tww_version: version.id,
      };
      if (!groupsCalled) {
        loadVersionGroups({ variables });
      } else {
        refetchGroups(variables);
      }
    }
  }, [loadVersionGroups, refetchGroups, groupsCalled, version]);

  // Store the loaded groups in state
  useEffect(() => {
    if (!groupsLoading && groupsData) {
      setGroups(groupsData.tww.special_ability_groups);
    }
  }, [version, groupsLoading, groupsData]);

  useEffect(() => {
    switch (expanded) {
      case 0: // Versions
        setVersion(null);
        setGroups(null);
        setVersionAbilitiesSearch("");
        setAbilities(null);
        break;
      case 1: // Abilities
        setVersionAbilitiesSearch("");
        break;
      default:
        break;
    }
  }, [expanded, setVersionAbilitiesSearch]);

  return (
    <>
      <div
        style={{
          height: "100%",
          overflow: "auto",
        }}
      >
        <div uuid={"versions"}>
          <TwwAccordionTitle
            title={version ? "Change Version" : "Select Version"}
            expanded={expanded === 0}
            accordionChanged={() => setExpanded(0)}
          />
          <div
            style={{
              display: expanded === 0 ? "block" : "none",
            }}
          >
            <h3
              style={{
                margin: "16px 0px 4px",
                width: "100%",
                textAlign: "center",
              }}
            >
              Core Game
            </h3>
            <div className="flex-row">
              {versions
                .filter((o) => /^\d+/.test(o.id))
                .map((o) => (
                  <GameVersionTile
                    key={o.id}
                    {...o}
                    onClick={(e) => selectVersion(o, e)}
                    selected={version && version.id === o.id}
                  />
                ))}
            </div>
            <h3
              style={{
                margin: "16px 0px 4px",
                width: "100%",
                textAlign: "center",
              }}
            >
              Mods WH3
            </h3>
            <div className="flex-row">
              {versions
                .filter((o) => !/^\d+/.test(o.id) && o.id.includes("_wh3"))
                .map((o) => (
                  <GameVersionTile
                    key={o.id}
                    {...o}
                    onClick={(e) => selectVersion(o, e)}
                    selected={version && version.id === o.id}
                  />
                ))}
            </div>
            <h3
              style={{
                margin: "16px 0px 4px",
                width: "100%",
                textAlign: "center",
              }}
            >
              Mods WH2
            </h3>
            <div className="flex-row">
              {versions
                .filter((o) => !/^\d+/.test(o.id) && !o.id.includes("_wh3"))
                .map((o) => (
                  <GameVersionTile
                    key={o.id}
                    {...o}
                    onClick={(e) => selectVersion(o, e)}
                    selected={version && version.id === o.id}
                  />
                ))}
            </div>
          </div>
        </div>
        {version && (
          <div uuid={"abilities"}>
            <TwwAccordionTitle
              title="Select Abilities(s)"
              value={`${version.game} - ${version.name}`}
              expanded={expanded === 1}
              accordionChanged={() => setExpanded(1)}
            />
            <div style={{ display: expanded === 1 ? "block" : "none" }}>
              <div className={"units-query"}>
                <Tip>
                  * Only spells are displayed by default but the search can
                  return ANY ability (bound spells, items, etc.)
                </Tip>
                <input
                  className={"units-query"}
                  id={"version-abilities-query"}
                  type="text"
                  onChange={(event) =>
                    setVersionAbilitiesSearch(event.target.value)
                  }
                  placeholder="Filter abilities* by name..."
                />
              </div>
              {groupsLoading ? "Loading available abilities groups..." : null}
              {groups ? (
                <AccordionBodyContent
                  className="flex-column"
                  style={{ paddingBottom: "80px" }}
                >
                  <AbilitiesGroupsTiles
                    query={versionAbilitiesSearch}
                    groups={groups}
                    version={version.id}
                    onClick={(ability) => selectAbility(ability)}
                    selected={selectedAbilities.map((a) => a.key)}
                  />
                </AccordionBodyContent>
              ) : null}
              {versionAbilitiesLoading
                ? "Loading available abilities..."
                : null}
              {abilities?.length ? (
                <AccordionBodyContent
                  className="flex-column"
                  style={{ paddingBottom: "80px" }}
                >
                  <h3
                    style={{
                      margin: "16px 0px 4px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Other Abilities
                  </h3>
                  <AbilitiesTiles
                    abilities={abilities}
                    version={version.id}
                    onClick={(ability) => selectAbility(ability)}
                    selected={selectedAbilities.map((a) => a.key)}
                  />
                </AccordionBodyContent>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {selectedAbilities.length > 0 && (
        <div key="confirm_button" className="flex-row confirm-button">
          <SelectionButton>
            <GenericButton
              title={`Add ${selectedAbilities.length} Selected`}
              onClick={(e) => addSelectedAbilities(false, e)}
            />
          </SelectionButton>
          <SelectionButton>
            <GenericButton
              title={`Add ${selectedAbilities.length} Selected and Close`}
              onClick={(e) => addSelectedAbilities(true, e)}
            />
          </SelectionButton>
        </div>
      )}
    </>
  );
};

const AbilitiesGroupsTiles = ({ groups, version, onClick, selected, query }) =>
  groups
    .filter((g) => !g.is_composite_group)
    .map((g) => {
      if (!query) {
        return g;
      }

      if (
        query.split(" ").reduce(
          // Some mods don't define onscreen_name for certain abilities
          (acc, q) =>
            acc && (g.name || g.ability_group).toLowerCase().includes(q),
          true
        )
      ) {
        return g;
      }

      const filteredAbilities = g.abilities.filter((a) =>
        query.split(" ").reduce(
          // Some mods don't define onscreen_name for certain abilities
          (acc, q) =>
            acc && (a.onscreen_name || a.key).toLowerCase().includes(q),
          true
        )
      );

      return { ...g, abilities: filteredAbilities };
    })
    .filter((g) => g.abilities.length)
    .sort((a, b) => {
      if (a.is_composite_group && !b.is_composite_group) {
        return 1;
      } else if (!a.is_composite_group && b.is_composite_group) {
        return -1;
      }

      return a.sort_order - b.sort_order;
    })
    .map((g, i) => (
      <>
        <GroupName>
          <GenericIcon
            name={g.name}
            height={38}
            width={38}
            icon={g.icon_path.toLowerCase()}
            tww_version={version}
            noTooltip
          />
          {g.name}
          {g.is_composite_group ? (
            <GroupKey>({g.ability_group})</GroupKey>
          ) : null}
        </GroupName>
        <div className="flex-row">
          <AbilitiesTiles
            abilities={g.abilities}
            version={version}
            onClick={onClick}
            selected={selected}
            noTooltip
          />
        </div>
      </>
    ));

const AbilitiesTiles = ({
  abilities,
  version,
  onClick,
  selected,
  noTooltip,
}) => (
  <AbilitiesTilesContainer>
    {abilities.map((a, i) => (
      <AbilityTile
        key={a.key}
        ability={a}
        version={version}
        selected={selected.indexOf(a.key) !== -1}
        onClick={(_) => onClick(a)}
        noTooltip={noTooltip}
      />
    ))}
  </AbilitiesTilesContainer>
);

const AbilityTile = ({ version, ability, onClick, selected, noTooltip }) => {
  const hoverFrameUrl = selected
    ? `${cloudinaryUrl([
        "h_128",
        "w_128",
      ])}/api/${version}/ui/skins/default/build_city_chain_frame.webp`
    : `${cloudinaryUrl([
        "h_128",
        "w_128",
      ])}/api/${version}/ui/skins/default/build_city_chain_frame.webp`;

  const backFrameUrl = selected
    ? `${cloudinaryUrl([
        "h_128",
        "w_128",
      ])}/api/${version}/ui/skins/default/building_frame_plain.webp`
    : `${cloudinaryUrl([
        "h_128",
        "w_128",
      ])}/api/${version}/ui/skins/default/fe_frame_glow.webp`;

  return (
    <div
      style={{
        height: "auto",
        width: "128px",
        position: "relative",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "8px",
        textAlign: "center",
      }}
      className="selectable_tile"
      onClick={onClick}
    >
      {backFrameUrl && (
        <img
          alt={"selectable_tile_background"}
          src={backFrameUrl}
          width={128}
          style={{ position: "absolute", top: "0", left: "0", height: "100%" }}
        />
      )}
      {hoverFrameUrl && (
        <img
          className="selectable_tile_hover"
          alt={"selectable_tile_hover"}
          src={hoverFrameUrl}
          width={128}
          style={{ position: "absolute", top: "0", left: "0", height: "100%" }}
        />
      )}
      <div>
        <IconsContainer>
          <GenericIcon
            name={ability.name}
            desc={ability.key}
            height={38}
            width={38}
            icon={`ui/battle%20ui/ability_icons/${ability.icon_name.toLowerCase()}.webp`}
            tww_version={version}
            noTooltip={noTooltip}
          />
          <TypeIcon
            icon={ability.type.icon}
            name={removeTemplates(ability.type.onscreen_name)}
            size={16}
            desc={""}
            tww_version={version}
          />
        </IconsContainer>
      </div>
      <p
        style={{
          position: "relative",
          margin: "4px 8px",
          // fontWeight: isMain ? "600" : "default",
        }}
      >
        {ability.name}
      </p>
    </div>
  );
};

const SelectionButton = ({ children }) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: 335,
          height: 27,
          boxShadow: "0 0 12px 9px rgb(254, 255, 247)",
          position: "absolute",
          left: 15,
          top: 17,
          borderRadius: 15,
        }}
      ></div>
      {children}
    </div>
  );
};

export default AbilityFinder;

const GroupName = styled.h3`
  margin: 16px 0px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GroupKey = styled.span`
  font-size: 14px;
`;

const Tip = styled.span`
  color: #b29871;
  font-style: italic;
`;

const AbilitiesTilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const IconsContainer = styled.div`
  position: relative;
`;

const TypeIcon = styled(AbilityTypeIcon)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const specialAbilityGroupsQuery = gql`
  query SpecialAbilityGroupsQuery($tww_version: String!) {
    tww(tww_version: $tww_version) {
      tww_version
      special_ability_groups {
        ability_group
        icon_path
        name
        is_composite_group
        sort_order
        abilities {
          key
          icon_name
          name
          type {
            onscreen_name
            icon
          }
        }
      }
    }
  }
`;

const versionAbilitiesQuery = gql`
  query AbilitiesQuery($tww_version: String!, $query: String) {
    tww(tww_version: $tww_version) {
      tww_version
      abilities(offset: 0, size: 100, query: $query, noGroupsOnly: true) {
        key
        icon_name
        name
        type {
          onscreen_name
          icon
        }
      }
    }
  }
`;
