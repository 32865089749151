import React from "react";

export default (props) => (
  <div className={"help"}>
    <h1>Help & Information</h1>
    <h2>Units Grid Page</h2>
    <ul>
      <li>
        Use the dropdowns at the top-left to view or compare the data of
        different versions and/or games. More versions will get added as they
        come out :)
      </li>
      <li>
        Searching/Filtering
        <ul>
          <li>
            Type in any text in the textbox at the top to execute a quick
            filter. Quick filter will match against any Text column (name,
            faction, caste, abilities, etc.)
          </li>
          <li>
            You can do column-specific filtering using the column name and the{" "}
            <strong>
              =, !=, {">"}, {"<"}, {">"}=, {"<"}=
            </strong>{" "}
            operators. I've tried to make the system as forgiving as possible in
            regard to column names. For exapmle, all of the following will yield
            the same results:
            <ul>
              <li>
                <strong>melee attack = 30</strong> (use name of column directly)
              </li>
              <li>
                <strong>melee_attack = 30</strong> (use name of the field
                directly)
              </li>
              <li>
                <strong>attack = 30</strong> (part of the column name can
                cometimes be ommitted)
              </li>
              <li>
                <strong>ma = 30</strong> (common abbreviations are supported if
                they exist){" "}
              </li>
            </ul>
          </li>
          <li>
            Both quick filter and column-specific filtering can be combined
            using the <strong>&, |, (, )</strong> operators for richer queries.
            For example:
            <ul>
              <li>
                <strong>
                  SKAVEN & (MA &gt; 40 || MD &gt; 40) & TYPE != MONSTER
                </strong>{" "}
                (any unit matching "Skaven" [in name, faction, caste, etc.] AND
                (a Melee Attack above 40 OR a Melee Defence above 40) AND not of
                type Monster
              </li>
            </ul>
          </li>
          <li>
            When comparing versions, you can use the special "modified" value
            against any column to to view only units that got this propert
            modified between the two versions.
            <ul>
              <li>
                For example: "faction = empire & melee ap = modified" will
                return all empire unit with changes to their Melee
                Armor-Piercing Damage
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Click a column header to apply ordering. Hold shift and click multiple
        headers to order by multiple columns.
      </li>
      <li>
        Double-click on a row to pin a copy of it to the top of the grid. You
        can pin as many rows as you like.
      </li>
      <li>
        Double-click a pinned row to remove it. (pinned rows are copies; the
        original row remains available in the main grid)
      </li>
    </ul>
    <h2>Compare Versions Page (or Units Grid Page with 2 versions selected)</h2>
    <ul>
      <li>
        All the actions from the "Units Stats" page applies to this page as well
      </li>
      <li>
        Compares the units from the left dropdown game+version to the units of
        the right dropdown game+version. Only altered units will be listed.
      </li>
      <li>
        The stats shown are the ones from the version selected in the RIGHT
        dropdown
      </li>
      <li>
        The number in parenthesis is the "delta" between the current version
        (RIGHT) and the previous version (LEFT).
      </li>
      <li>
        Stats that have been modified between the two versions are highlighted
        with colors:
        <ul>
          <li>
            Green: Stat has been improved (usually it means the stat went up but
            in some cases like "Reload Time" or "Cost" it means it went down)
          </li>
          <li>
            Red: Stat has been worsened ((usually it means the stat went down
            but in some cases like "Reload Time" or "Cost" it means it went up)
          </li>
          <li>
            Yellow: Stat's value remained the same but there was changes to the
            stats used to compute it (e.g. damage remains the same but base
            damage reduced by 4 and ap damage increased by 4)
          </li>
        </ul>
      </li>
      <li>
        If the "name" column has a red glow on the left, it means that unit no
        longer exists in the selected version.
      </li>
      <li>
        If the "name" column has a green glow on the left, it means that unit is
        new in the selected version.
      </li>
    </ul>
    <h2>SFO: Grimhammer II</h2>
    <p>
      SFO: Grimhammer II is a whole game mod that changes every aspect of the
      TW: WH II to be closer to the lore and Warhammer universe. We are long
      fans of Warhammer and want to share this passion with all of you thanks to
      this mod.
    </p>
    <p>
      You can find more details on their{" "}
      <a
        href="https://steamcommunity.com/sharedfiles/filedetails/?id=1149625355"
        target="_blank"
        rel="noopener noreferrer"
      >
        official mod website
      </a>
    </p>
    <h2>Radious Total War Mod</h2>
    <p>
      From the award winning team and and from the creators of the most
      subscribed mods on steam workshop for TW games comes another major
      modification. This time for brand new Total War: Warhammer 2!
    </p>
    <p>
      You can find more details on their{" "}
      <a
        href="https://steamcommunity.com/sharedfiles/filedetails/?id=1149634389"
        target="_blank"
        rel="noopener noreferrer"
      >
        official mod website
      </a>
    </p>
    <h2>Boyz Will Be Boyz - Closer to Tabletop overhaul (CTT)</h2>
    <p>
      CTT aka Boyz will be Boyz is an overhaul intending to offer an alternate
      balance take: closer to tabletop balance, more unforgiving, with more
      focus on counter-play and loss management.
    </p>
    <p>
      You can find more details on their{" "}
      <a
        href="https://steamcommunity.com/sharedfiles/filedetails/?id=1214959308"
        target="_blank"
        rel="noopener noreferrer"
      >
        official mod website
      </a>
    </p>
    <h2>Discord</h2>
    <p>
      Join me on the official{" "}
      <a href="https://discord.gg/38b3HMK">Total War Warhammer Stats channel</a>{" "}
      on Discord if you have more questions or want to share your ideas!
    </p>
    <h2>Patreon</h2>
    <p>
      You can help me cover the cost of hosting and support my efforts on the
      site by becoming{" "}
      <a
        href="https://www.patreon.com/twwstats"
        target="_blank"
        rel="noopener noreferrer"
      >
        patron of twwstats
      </a>
      .
    </p>
    <h2>Cloudinary</h2>
    <p>
      Twwstats uses Cloudinary to transform and serve images to ensure the site
      is as fast and responsive as possible.
    </p>
    <h2>General Information</h2>
    <ul>
      <li>
        The following properties are based off my own understanding of the data.
        I THINK my equations are correct but feel free to report any
        inconsistencies between what you see on https://twwstats.com and what
        you see in game and I will gladly try to rectify the situation.
        <ul>
          <li>Unit Size</li>
          <li>Hit Points</li>
          <li>Speed</li>
          <li>Missile Damage (avg. over 10s)</li>
          <li>Reload Time</li>
          <li>
            Mass (Loosely based on the Hit Points formula. I assumed any "battle
            entity" that contributes to the Hit Points also contributes to the
            Mass.)
          </li>
        </ul>
      </li>
      <li>
        Some computed properties (e.g. Mass) cannot be confirmed in game and are
        not guaranteed to be accurate.
      </li>
      <li>
        The style (and some of the assets) are heavily inspired (and sometimes
        blatantly stolen) from the official{" "}
        <a
          href="https://whenc.totalwar.com/#/units"
          target="_blank"
          rel="noopener noreferrer"
        >
          Total War War Hammer Guide
        </a>
        . Hopefully they won't get mad at me :)
      </li>
      <li>
        All the data comes directly from the game's "database". I'm leveraging
        part of the{" "}
        <a
          href="https://sourceforge.net/projects/packfilemanager/"
          target="_blank"
          rel="noopener noreferrer"
        >
          PackFile Manager
        </a>{" "}
        project to retrieve the data directly from the data.pack and other
        files.
      </li>
      <li>
        Some units don't have a faction. They are usually summoned units,
        special campaign units (like ritual units) and other corner cases like
        that that are not associated to a Faction in the same way as 'normal"
        units are. If someone out there knows of a reliable way to figure out
        their factions please share it with me.
      </li>
    </ul>
  </div>
);
