import React, { Fragment } from "react";
import { Tooltip } from "react-tippy";
import { cloudinaryUrl } from "../services/common";

export const MagicAttackTooltip = (props) => (
  <Fragment>
    <p style={{ fontWeight: "bold" }}>{"Magical Attack"}</p>
    {/* https://steamcommunity.com/app/364360/discussions/0/1470841715951469948/ */}
    <p>Damage ignores Physical Resistance</p>
    <p>Damage reduced by Magical Resistance</p>
  </Fragment>
);

export const MagicAttackIconOnly = (props) => (
  <img
    alt={"Magical Attack"}
    src={`${cloudinaryUrl()}/api/${
      process.env.REACT_APP_TWWSTATS_LATEST_VERSION
    }/ui/skins/default/modifier_icon_magical.webp`}
    style={{ verticalAlign: "sub" }}
  />
);

export default (props) => {
  if (!props.value) {
    return null;
  }

  return (
    <Tooltip
      position="top"
      arrow="true"
      style={{ display: "inline" }}
      html={
        <div
          style={{
            fontFamily: '"Open Sans", sans-serif',
            padding: "24px 4px 4px",
          }}
        >
          <MagicAttackTooltip />
        </div>
      }
    >
      <MagicAttackIconOnly />
    </Tooltip>
  );
};
