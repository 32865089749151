import React from "react";
import styled from "styled-components";

export default ({ children }) => (
  <div className="simple-page">
    <div className="contour top-left" />
    <div className="contour top" />
    <div className="contour top-right" />
    <div className="contour left" />
    <div className="contour right" />
    <div className="contour bottom-left" />
    <div className="contour bottom" />
    <div className="contour bottom-right" />
    <div style={{ position: "relative" }}>{children}</div>
  </div>
);
