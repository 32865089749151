import React from "react";

export default (props) => {
    let settings = props.settings;

    return (
        <>
            <div className={"settings"}>
                <h1>Settings</h1>
                <label>Unit Size: </label>
                <select className="version-select" onChange={(e) => props.setSetting('unit_size', e.target.value)} value={settings.unit_size}>
                    <option value={'ultra'}>Ultra</option>
                    <option value={'large'}>Large</option>
                    <option value={'medium'}>Medium</option>
                    <option value={'small'}>Small</option>
                </select>
            </div >
            <div className={"settings"}>
                <label>Secondary Missile Weapon: </label>
                <select className="version-select" onChange={(e) => props.setSetting('show_secondary_missile_weapon', e.target.value)} value={settings.show_secondary_missile_weapon}>
                    <option value={'false'}>Hide</option>
                    <option value={'true'}>Show</option>
                </select>
                <p>This adds the Secondary Missile Weapon to the Units Grid and Units Cards. There is very few units with secondary missile weapons, it makes the cards super long and I can't validate the data in-game so I'm putting that as a setting for now.</p>
            </div>
            <div className={"settings"}>
                <label>JSON Data: </label>
                <select className="version-select" onChange={(e) => props.setSetting('show_raw_json', e.target.value)} value={settings.show_raw_json}>
                    <option value={'false'}>No</option>
                    <option value={'true'}>Yes</option>
                </select>
                <p>When enabled, Grids pages will display the data as a JSON string instead of a grid. This is mostly for other developers or curious people. Note that while most of the data is a single field in the pack files and should be 100% accurate, some data requires choosing between multiple fields and/or combining the values of multiple fields (e.g. missile damage, unit health, etc.) and I can't guarantee they 100% reflect the in-game values (but I am constantly fine-tuning them with the hope to get there someday :) )</p>
            </div>
        </>
    )
}