import gql from "graphql-tag";
import ContactPhaseFragment from "./contactphase-fragment";

export default gql`
    fragment explosion on explosion {
        key
        detonation_damage
        detonation_damage_ap
        is_magical
        ignition_amount
        detonation_radius
        detonation_duration
        detonation_speed
        shrapnel_amount
        distance_from_target
        detonation_force

        shrapnel_type

        contact_phase_effect{
            ...contact_phase
        }
    }
    ${ContactPhaseFragment}
    `