import React from "react";
import _ from "lodash";
import GenericGrid from "../components/generic-grid";
import FatigueEffectsQuery from "../graphql/fatigue-effects-query-hoc";
import VersionsCompare from "../components/versions-compare";
import GridControls from "./grid-controls-hoc";

function createColumnDefs(data) {
  return [
    {
      headerName: "Fatigue Treshold",
      field: "fatigue_threshold",
      synonyms: ["threshold"],
      pinned: "left",
    },
    {
      headerName: "Fatigue Level",
      field: "fatigue_level",
      synonyms: ["level"],
      minWidth: 160,
      width: 160,
    },
    {
      type: ["integerColumn"],
      headerName: "Armour",
      field: "stat_armour",
      headerComponentParams: {
        icon: "ui/skins/default/icon_stat_armour.webp",
      },
    },
    {
      type: ["integerColumn"],
      headerName: "Speed",
      field: "scalar_speed",
      headerComponentParams: {
        icon: "ui/skins/default/icon_stat_speed.webp",
      },
    },
    {
      type: ["integerColumn"],
      headerName: "Melee Attack [MA]",
      field: "stat_melee_attack",
      synonyms: ["ma", "attack"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_stat_attack.webp",
      },
    },
    {
      type: ["integerColumn"],
      headerName: "Melee Defence [MD]",
      field: "stat_melee_defence",
      synonyms: ["defence", "md"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_stat_defence.webp",
      },
    },
    {
      type: ["integerColumn"],
      headerName: "Charge Bonus",
      field: "stat_charge_bonus",
      synonyms: ["cb", "charge"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_stat_charge_bonus.webp",
      },
    },
    {
      type: ["integerColumn"],
      headerName: "Reloading",
      field: "stat_reloading",
      synonyms: ["reload"],
      headerComponentParams: {
        icon: "ui/skins/default/icon_cooldown.webp",
      },
    },
    {
      type: ["integerColumn"],
      headerName: "AP Damage",
      field: "stat_melee_damage_ap",
      synonyms: ["ap"],
      headerComponentParams: {
        icon: "ui/skins/default/modifier_icon_armour_piercing.webp",
      },
    },
  ];
}

const Grid = (props) => (
  <VersionsCompare
    {...props}
    query={FatigueEffectsQuery}
    dataKey={"fatigue_effects"}
    rowKey={"fatigue_level"}
    sortData={(data) => _.orderBy(data, [(o) => o.fatigue_threshold], ["asc"])}
    createColumnDefs={createColumnDefs}
    WrappedComponent={GenericGrid}
  />
);

const FatigueEffects = (props) => (
  <GridControls {...props} WrappedComponent={Grid} />
);

export default FatigueEffects;
