import React from "react";
import { cloudinaryUrl } from "../../services/common";

const GameVersionTile = ({ game, id, name, onClick, selected }) => {
  const hoverFrameUrl = selected
    ? `${cloudinaryUrl([
        "h_150",
        "w_164",
      ])}/api/7020588859072995689/ui/skins/default/build_city_chain_frame.webp`
    : `${cloudinaryUrl([
        "h_150",
        "w_164",
      ])}/api/7020588859072995689/ui/skins/default/build_city_chain_frame.webp`;

  const backFrameUrl = selected
    ? `${cloudinaryUrl([
        "h_150",
        "w_164",
      ])}/api/7020588859072995689/ui/skins/default/building_frame_plain.webp`
    : `${cloudinaryUrl([
        "h_150",
        "w_164",
      ])}/api/7020588859072995689/ui/skins/default/fe_frame_glow.webp`;

  return (
    <div
      style={{
        height: "150px",
        width: "164px",
        textAlign: "center",
        position: "relative",
        cursor: "pointer",
        justifyContent: "space-around",
      }}
      className="flex-column selectable_tile"
      onClick={onClick}
    >
      {backFrameUrl && (
        <img
          alt={"selectable_tile_background"}
          src={backFrameUrl}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            height: "150px",
            width: "164px",
          }}
        />
      )}
      {hoverFrameUrl && (
        <img
          className="selectable_tile_hover"
          alt={"selectable_tile_hover"}
          src={hoverFrameUrl}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            height: "150px",
            width: "164px",
          }}
        />
      )}
      <img
        alt={`${game} - ${name}`}
        style={{
          position: "relative",
          margin: "24px 0 0 0",
          maxWidth: "128px",
        }}
        src={`${cloudinaryUrl([
          "w_128",
        ])}/api/${id}/ui/skins/default/fe_logo.webp`}
      />
      <h3 style={{ position: "relative", margin: "0px 4px", fontSize: "18px" }}>
        {name}
      </h3>
    </div>
  );
};

export default GameVersionTile;
