import queryString from "query-string";
import React, { useEffect, useState } from "react";
import FontAwesome from "react-fontawesome";
import Modal from "react-modal";
import AbilityCard from "./AbilityCard";
import AbilityFinder from "./AbilityFinder";
import SimpleDialog from "./simple-dialog";

const parseLocationSearch = (search) => {
  const parsed = queryString.parse(search);
  let abilities = parsed.abilities || [];
  if (!Array.isArray(abilities)) {
    abilities = [abilities];
  }
  return abilities.map((a) => queryString.parse(a));
};

const AbilitiesCards = ({ location, history }) => {
  const [abilities, setAbilities] = useState(() =>
    parseLocationSearch(location.search)
  );
  const [showFinder, setShowFinder] = useState(abilities.length === 0);
  const [selectedVersion, setSelectedVersion] = useState(null);

  useEffect(() => {
    setAbilities(parseLocationSearch(location.search));
  }, [location.search]);

  const addCards = (version, abilities, closeDialog) => {
    let parsed = queryString.parse(location.search);
    parsed.abilities = parsed.abilities || [];

    if (!Array.isArray(parsed.abilities)) {
      parsed.abilities = [parsed.abilities];
    }

    abilities.forEach((ability) => {
      let meta = {
        v: version.id,
        k: ability.key,
        u: false, // Upgraded
      };
      parsed.abilities.push(queryString.stringify(meta));
    });

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(parsed),
    });

    setSelectedVersion(version);
    setShowFinder(parsed.abilities.length === 0 || !closeDialog);
  };

  const clearAbilities = () => {
    if (
      window.confirm("This will removes all Abilities Cards. Are you sure?")
    ) {
      const parsed = queryString.parse(location.search);
      parsed.abilities = [];

      history.push({
        pathname: location.pathname,
        search: queryString.stringify(parsed),
      });
    }
  };

  const onRemoveCard = (idx) => {
    let parsed = queryString.parse(location.search);
    if (!Array.isArray(parsed.abilities)) {
      parsed.abilities = [parsed.abilities];
    }

    parsed.abilities.splice(idx, 1);

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(parsed),
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button onClick={() => setShowFinder(true)}>
          <FontAwesome name="plus" /> Add Abilities
        </button>
        {abilities.length > 0 && (
          <button onClick={clearAbilities}>
            <FontAwesome name="trash-alt" /> Clear All
          </button>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {abilities.map((a, i) => (
          <AbilityCard
            key={a.k}
            index={i}
            right={a}
            onRemoveCard={onRemoveCard}
          />
        ))}
      </div>
      <Modal
        className={{
          base: "twwstats_unit_finder",
          afterOpen: "after-open",
          beforeClose: "before-close",
        }}
        ariaHideApp={false}
        isOpen={showFinder}
        onRequestClose={() => setShowFinder(false)}
        contentLabel="Ability Finder"
        closeTimeoutMS={300}
      >
        <SimpleDialog onClose={() => setShowFinder(false)}>
          <AbilityFinder
            onConfirm={addCards}
            defaultVersion={selectedVersion}
          />
        </SimpleDialog>
      </Modal>
    </div>
  );
};

export default AbilitiesCards;
