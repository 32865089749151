import React from "react";
import FontAwesome from 'react-fontawesome';

// TODO These are always wrapped into <Modal> elements. Create a <TwwModal> that contains this 
// structure instead
export default ({children, onClose}) => (
    <div className="simple-dialog">
        <div className="contour top-left" />
        <div className="contour top" />
        <div className="contour top-right" />
        <div className="contour left" />
        <div className="contour right" />
        <div className="contour bottom-left" />
        <div className="contour bottom" />
        <div className="contour bottom-right" />
        <FontAwesome className='btn' name={'times'} onClick={onClose} style={{float: 'right', marginTop: '-42px', position: 'relative'}} />
        <div className="dialog-content">
            {children}
        </div>
    </div>
)