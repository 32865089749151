import React from "react";
import GenericGrid from "../components/generic-grid"
import abilitiesQuery from "../graphql/abilities-query-hoc";
import VersionsCompare from "../components/versions-compare"
import Ability, { sortAbilities } from "../models/ability";
import createColumnDefs from "../colDefs/abilities-coldefs";
import GridControls from "./grid-controls-hoc";

function dataLoaded(data) {
    // Remove abilities with a 'Parent Ability" because they are just duplicates associated to a different character and just make things confusing
    // Also remove abilities with no 'special ability' as they would essentially have no data to display (only one such ability as of Asrai Resurgent Update)
    return data.filter(o => !o.special_ability?.parent_ability);
}

const Grid = (props) => (<VersionsCompare
                {...props}
                query={abilitiesQuery}
                dataKey={'abilities'}
                sortData={sortAbilities}
                dataLoaded={dataLoaded}
                dataClass={Ability}
                createColumnDefs={createColumnDefs}
                WrappedComponent={GenericGrid}
                columnTypes={{}}
                defaultColDef={{
                    minWidth: 40,
                    width: 40,
                    maxWidth: 40,
                }}
                rowHeight={72}
            />)

const AbilitiesQuery = (props) => (<GridControls {...props} WrappedComponent={Grid} />)

export default AbilitiesQuery