import Projectile from "./projectile"
import buildModel from '../services/buildModel';

export default function Bombardment(data, tww_version, cuc) {
    this.tww_version = tww_version;
    this.arrival_window = data.arrival_window;
    this.bombardment_key = data.bombardment_key;
    this.num_projectiles = data.num_projectiles;
    this.radius_spread = data.radius_spread;
    this.start_time = data.start_time;
    this.launch_source = data.launch_source;
    this.launch_height = data.launch_height;
    this.launch_height_underground = data.launch_height_underground;
    
    // PROJECTILE
    //
    this.projectile = buildModel(Projectile, data.projectile_type, tww_version, cuc)
}