export default function SpawnedUnit(data, tww_version) {
  if (!data) {
    return null;
  }

  this.tww_version = tww_version;

  this.key = data.key;
  this.short_description_text = data.short_description_text;
  this.onscreen_name = data.onscreen_name;
  this.unit_card = `ui/units/icons/${data.variant?.unit_card_url}.webp`;
}
