import React from "react";
import _ from "lodash"
import GenericGrid from "../components/generic-grid"
import kvQuery from "../graphql/kv-query-hoc";
import VersionsCompare from "../components/versions-compare"
import GridControls from "./grid-controls-hoc";

function createColumnDefs(data) {
  return [
    {
      headerName: "Key",
      field: 'key',
      pinned: 'left',
      minWidth: 360,
    },
    {
      headerName: "Value",
      field: 'value',
      minWidth: 90,
      width: 90
    },
    {
      headerName: "Description",
      field: 'description',
      minWidth: 900,
      width: 900,
      cellClass: 'left-aligned',
    },
  ]
}

function dataLoaded(data) {
  _.each(data, o => {
    if (o.value) { o.value = Math.round(o.value * 1000) / 1000 }
  });
  return data;
}

const Grid = (props) => (
<VersionsCompare
  {...props}
  query={kvQuery}
  dataLoaded={dataLoaded}
  dataKey={'kv'}
  createColumnDefs={createColumnDefs}
  WrappedComponent={GenericGrid}
/>
)

const KVQuery = (props) => (<GridControls {...props} WrappedComponent={Grid} />)

export default KVQuery