import gql from "graphql-tag";
import ContactPhaseFragment from "./contactphase-fragment";
import ExplosionFragment from "./explosion-fragment";

export default gql`
  fragment projectile on projectile {
    key
    category
    shot_type
    explosion {
      ...explosion
    }
    projectile_number
    effective_range
    minimum_range
    marksmanship_bonus
    damage
    ap_damage
    collision_radius
    base_reload_time
    bonus_v_cavalry
    bonus_v_infantry
    bonus_v_large
    calibration_distance
    calibration_area
    max_elevation
    overhead_stat_effect {
      ...contact_phase
    }
    contact_stat_effect {
      ...contact_phase
    }
    projectile_penetration {
      key
      entity_size_cap
      max_penetration
      description
    }
    burst_size
    burst_shot_delay
    ignition_amount
    is_magical
    shots_per_volley
    can_damage_buildings
  }
  ${ContactPhaseFragment}
  ${ExplosionFragment}
`;
