import Phase from "./phase";
import Explosion from "./explosion";
import { globalSettings } from "../services/twwstats-services";

import { RoundFloat } from "../services/common";

export default function Projectile(data, tww_version, cuc, unitSize) {
  const stats_scaling = cuc.unit_stat_to_size_scaling_values
    ?.filter((s) => s.size === globalSettings().unit_size)
    .reduce(
      (result, row) => ({
        ...result,
        [row.stat]:
          unitSize === 1 ? row.single_entity_value : row.multi_entity_value,
      }),
      {}
    );

  this.tww_version = tww_version;

  this.category = data.category;
  this.base_reload_time = data.base_reload_time;
  this.range = data.effective_range;
  this.base_damage = Math.round(
    data.damage * (stats_scaling?.scalar_missile_damage_base ?? 1)
  );
  this.ap_damage = Math.round(
    data.ap_damage * (stats_scaling?.scalar_missile_damage_ap ?? 1)
  );
  this.shots_per_volley = data.shots_per_volley;
  this.projectile_number = data.projectile_number;
  this.marksmanship_bonus = data.marksmanship_bonus;
  this.collision_radius = RoundFloat(data.collision_radius);
  this.overhead_stat_effect = data.overhead_stat_effect || null;
  this.burst_size = data.burst_size;
  this.burst_shot_delay = data.burst_shot_delay;
  this.ignition_amount = data.ignition_amount ? true : false;
  this.is_magical = data.is_magical;
  this.bonus_v_infantry = data.bonus_v_infantry;
  this.bonus_v_large = data.bonus_v_large;
  this.calibration_distance = RoundFloat(data.calibration_distance);
  this.calibration_area = RoundFloat(data.calibration_area);
  this.max_elevation = data.max_elevation;
  this.ap_ratio = RoundFloat(
    this.ap_damage / (this.base_damage + this.ap_damage)
  );
  this.can_damage_buildings = data.can_damage_buildings;

  // PRIMARY MISSILE WEAPON PROJECTILE PENETRATION
  //
  if (data.projectile_penetration) {
    this.penetration_entity_size_cap =
      data.projectile_penetration.entity_size_cap;
    this.penetration_max_penetration =
      data.projectile_penetration.max_penetration;
    this.penetration_description = data.projectile_penetration.description;
  }

  // PRIMARY MISISLE WEAPON PROJECTILE CONTACT PHASE
  //
  this.phase =
    data.contact_stat_effect &&
    new Phase(data.contact_stat_effect, tww_version, cuc);

  // PRIMARY MISSILE WEAPON PROJECTILE OVERHEAD CONTACT PHASE
  //
  this.overhead_phase =
    data.overhead_stat_effect &&
    new Phase(data.overhead_stat_effect, tww_version, cuc);

  // PRIMARY MISSILE WEAPON PROJECTILE EXPLOSION
  //
  this.explosion =
    data.explosion && new Explosion(data.explosion, tww_version, cuc, unitSize);
}
