import _ from "lodash";

export function stringComparator(a, b) {
  return a === b ? 0 : !a && b ? -1 : a && !b ? 1 : a > b ? 1 : -1;
}

export function factionsSortValue(factions) {
  return _.max(_.map(factions, (_) => _.name_group)) || "";
}

export function factionsComparator(valueA, valueB) {
  let a = factionsSortValue(valueA);
  let b = factionsSortValue(valueB);
  if (a === b) {
    return 0;
    // Code below works (i.e. groups units by subfactions)
    // but it makes it weird when you sort by Caste as well
    // if(valueA.length === valueB.length){
    //   return 0;
    // }
    // else{
    //   return valueA.length - valueB.length;
    // }
  } else if (a > b) {
    return 1;
  } else {
    return -1;
  }
}

export function specialAbilityGroupSortValue(groups) {
  return groups && groups.length
    ? _.sortBy(groups, (_) => _.button_name).pop().button_name
    : "";
}

export function specialAbilityGroupsComparator(valueA, valueB) {
  let a = specialAbilityGroupSortValue(valueA);
  let b = specialAbilityGroupSortValue(valueB);
  if (a === b) {
    return 0;
  } else if (a > b) {
    return 1;
  } else {
    return -1;
  }
}

export function casteSortValue(caste) {
  const order = [
    "Lord",
    "Hero",
    "Melee Infantry",
    "Missile Infantry",
    "Melee Cavalry",
    "Missile Cavalry",
    "Chariot",
    "War Beast",
    "Monstrous Infantry",
    "Monstrous Cavalry",
    "Monster",
    "War Machine",
    "Generic",
  ];
  return order.indexOf(caste);
}

export function sizeSortValue(size) {
  const order = [
    "very_large",
    "large",
    "medium",
    "small",
    "very_small",
    "",
    null,
    undefined,
  ];
  return order.indexOf(size);
}

export function weightSortValue(size) {
  const order = [
    "super_heavy",
    "very_heavy",
    "heavy",
    "medium",
    "light",
    "",
    null,
    undefined,
  ];
  return order.indexOf(size);
}

export function casteComparator(valueA, valueB) {
  var idxA = casteSortValue(valueA);
  var idxB = casteSortValue(valueB);
  if (idxA === idxB) {
    return 0;
  } else if (idxA > idxB) {
    return 1;
  }

  return -1;
}

export function sizeComparator(valueA, valueB) {
  var idxA = sizeSortValue(valueA);
  var idxB = sizeSortValue(valueB);
  if (idxA === idxB) {
    return 0;
  } else if (idxA > idxB) {
    return -1;
  }

  return 1;
}

export function weightComparator(valueA, valueB) {
  var idxA = weightSortValue(valueA);
  var idxB = weightSortValue(valueB);
  if (idxA === idxB) {
    return 0;
  } else if (idxA > idxB) {
    return -1;
  }

  return 1;
}

export function phaseComparator(valueA, valueB) {
  const a = valueA && valueA.name;
  const b = valueB && valueB.name;
  if (a === b) {
    return 0;
  } else if (!b || a > b) {
    return 1;
  }

  return -1;
}

export function phasesComparator(valueA, valueB) {
  const countA = (valueA && valueA.length) || 0;
  const countB = (valueB && valueB.length) || 0;

  if (countA > countB) {
    return 1;
  } else if (countA < countB) {
    return -1;
  } else if (countA === countB) {
    const a = valueA && valueA[0].name;
    const b = valueB && valueB[0].name;
    if (a === b) {
      return 0;
    } else if (!b || a > b) {
      return 1;
    }
  }

  return -1;
}

export function numberComparator(a, b) {
  a = a === undefined ? -1 : a === -1 ? 99999 : a;
  b = b === undefined ? -1 : b === -1 ? 99999 : b;
  if (a === b) {
    return 0;
  } else if (a > b) {
    return 1;
  } else {
    return -1;
  }
}

export function groundStatEffectGroupComparator(a, b) {
  return stringComparator(a && a.group_name, b && b.group_name);
}

export function isInvertedDelta(field) {
  const tokens = field.split(".");
  switch (tokens[tokens.length - 1]) {
    case "reload_time":
    case "multiplayer_cost":
    case "singleplayer_cost":
    case "singleplayer_upkeep":
    case "melee_attack_interval":
    case "collision_attack_max_targets_cooldown":
    case "sa_miscast_chance":
    case "sa_mana_cost":
    case "sa_mp_cost":
    case "sa_recharge_time":
    case "sa_initial_recharge":
    case "sa_wind_up_time":
    case "sa_min_range":
    case "calibration_area":
    case "fatigue_modifier":
      return true;
    default:
      break;
  }

  return false;
}

export const LOCAL_STORAGE_SETTINGS_KEY = "twwstats_settings";
export const globalSettings = () =>
  JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_SETTINGS_KEY)) || {
    unit_size: "ultra",
  };

export default {};
