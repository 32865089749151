import React from "react";
import { Tooltip } from "react-tippy";
import { cloudinaryUrl } from "../services/common";

export const PhaseTooltip = ({ phase }) => {
  if (!phase) {
    return null;
  }

  const effect_type_icon = `${cloudinaryUrl()}/api/${
    phase.tww_version
  }/ui/skins/default/unit_effect_${phase.effect_type}.webp`;

  const damageTooltip = (phase) => {
    if (phase.damage_amount) {
      return (
        <p>
          Each entity of the affected unit(s) has a {phase.damage_chance * 100}%
          chance of taking {phase.damage_amount} damages when the spell is cast
          and every {phase.hp_change_frequency}s
          {phase.duration > 0
            ? ` during ${phase.duration}s (${phase.ticks} times in total)`
            : ""}
          . The check is made against every entity in the unit but a maximum of{" "}
          {phase.max_damaged_entities}{" "}
          {phase.max_damaged_entities > 1 ? "entities" : "entity"}/unit will
          receive damage each tick.{" "}
        </p>
      );
    }
  };

  const healingTooltip = (phase) => {
    if (phase.heal_amount) {
      if (
        phase.max_damaged_entities === -1 ||
        phase.max_damaged_entities === Infinity
      ) {
        return (
          <p>
            Each harmed entity in the affected unit(s) will heal for{" "}
            {phase.heal_amount} HP when the spell is cast and every{" "}
            {phase.hp_change_frequency}s
            {phase.duration > 0
              ? ` during ${phase.duration}s (${phase.ticks} times in total)`
              : ""}
            .
          </p>
        );
      } else if (phase.max_damaged_entities === 0) {
        return (
          <p>
            One harmed entity in the affected unit(s) will heal for{" "}
            {phase.heal_amount} HP when the spell is cast and every{" "}
            {phase.hp_change_frequency}s
            {phase.duration > 0
              ? ` during ${phase.duration}s (${phase.ticks} times in total)`
              : ""}
            .
          </p>
        );
      } else {
        // This does not exist in the BD at the moment but just in case for the future
        return (
          <p>
            Each harmed entity in the affected unit(s) will heal for{" "}
            {phase.heal_amount} HP when the spell is cast and every{" "}
            {phase.hp_change_frequency}s
            {phase.duration > 0
              ? ` during ${phase.duration}s (${phase.ticks} times in total)`
              : ""}
            . A maximum of {phase.max_damaged_entities} entities/unit will be
            healed each tick.
          </p>
        );
      }
    }
  };

  return (
    <>
      <p style={{ fontWeight: "bold", lineHeight: "24px" }}>
        <img
          style={{ verticalAlign: "bottom" }}
          alt="effect_type"
          src={effect_type_icon}
        />
        {phase.name}
      </p>
      {phase.attributeEffects &&
        phase.attributeEffects.map((v, i, a) => (
          <p style={{ margin: "0" }} key={`attr-${i}`}>
            add attribute: {v.attribute}
          </p>
        ))}
      {phase.statEffects &&
        phase.statEffects.map((v, i, a) => (
          <p style={{ margin: "0" }} key={`stat-${i}`}>
            {v.stat}, {v.how}: {v.value}
          </p>
        ))}
      {phase.duration ? (
        <p>
          duration: {phase.duration === -1 ? "Constant" : `${phase.duration}s`}
        </p>
      ) : null}
      {damageTooltip(phase)}
      {healingTooltip(phase)}
      {phase.requested_stance ? (
        <p>requested_stance: {phase.requested_stance}</p>
      ) : null}
      {phase.unbreakable ? <p>unbreakable: Yes</p> : null}
      {phase.cant_move ? <p>cant_move: Yes</p> : null}
      {phase.freeze_fatigue ? <p>freeze_fatigue: Yes</p> : null}
      {phase.fatigue_change_ratio ? (
        <p>fatigue_change_ratio: {phase.fatigue_change_ratio}</p>
      ) : null}
      {phase.inspiration_aura_change_mode ? (
        <p>
          inspiration_aura_change_mode: {phase.inspiration_aura_change_mode}
        </p>
      ) : null}
      {phase.ability_recharge_change ? (
        <p>ability_recharge_change: {phase.ability_recharge_change}</p>
      ) : null}
      {/* {phase.hp_change_frequency ? <p>hp_change_frequency: {phase.hp_change_frequency}</p> : null}
        {phase.heal_amount ? <p>heal_amount: {phase.heal_amount}</p> : null}
        {phase.damage_chance ? <p>damage_chance: {phase.damage_chance}</p> : null}
        {phase.damage_amount ? <p>damage_amount: {phase.damage_amount}</p> : null}
        {phase.max_damaged_entities ? <p>max_damaged_entities: {phase.max_damaged_entities}</p> : null} */}
      {phase.resurrect ? <p>resurrect: Yes</p> : null}
      {phase.mana_regen_mod ? (
        <p>mana_regen_mod: {phase.mana_regen_mod}</p>
      ) : null}
      {phase.mana_max_depletion_mod ? (
        <p>mana_max_depletion_mod: {phase.mana_max_depletion_mod}</p>
      ) : null}
      {phase.imbue_magical ? <p>imbue_magical: Yes</p> : null}
      {phase.imbue_ignition ? <p>imbue_ignition: Yes</p> : null}
    </>
  );
};

export const PhaseIconOnly = ({ phase }) =>
  phase ? (
    <img
      alt={phase.id}
      src={`${cloudinaryUrl(["w_24", "h_24"])}/api/${phase.tww_version}/${
        phase.icon
      }`}
      style={{ verticalAlign: "bottom", height: "24px" }}
    />
  ) : null;

export default ({ phase }) => {
  if (!phase) {
    return null;
  }

  return (
    <Tooltip
      position="top"
      arrow="true"
      style={{ display: "inline" }}
      html={
        <div
          style={{
            fontFamily: '"Open Sans", sans-serif',
          }}
        >
          <PhaseTooltip phase={phase} />
        </div>
      }
    >
      <PhaseIconOnly phase={phase} />
    </Tooltip>
  );
};
