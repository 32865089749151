import React from "react";
import { Tooltip } from "react-tippy";
import {
  cloudinaryUrl,
  iconRegex,
  processTemplatedText,
} from "../services/common";

export default (props) => {
  return (
    <Tooltip
      position="top"
      arrow="true"
      style={{ display: "inline-flex" }}
      // trigger='click'
      html={
        <div
          style={{
            fontFamily: '"Open Sans", sans-serif',
          }}
        >
          <span style={{ fontWeight: "bold" }}>{props.attribute.name}</span>
          <p dangerouslySetInnerHTML={{ __html: props.attribute.desc }} />
        </div>
      }
    >
      <img
        alt={props.attribute.key}
        src={`${cloudinaryUrl(["h_32", "w_32"])}/api/${
          props.attribute.tww_version
        }/ui/battle%20ui/ability_icons/${props.attribute.key}.webp`}
        style={{ position: "relative", height: "32px", marginTop: "0px" }}
      />
    </Tooltip>
  );
};
