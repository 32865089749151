import React, { useContext } from 'react'
import { useQuery } from 'react-apollo'
import gql from "graphql-tag";

const GameVersionsContext = React.createContext({
  versions: []
})

const useGameVersions = () => {
  return useContext(GameVersionsContext)
}

const GameVersionsProvider = ({ children }) => {
    const {data, loading, error} = useQuery(gameVersionsQuery)
    
    if (error) return <p>There was an error trying to retrieve the game versions. Please refresh the page to try again.</p>;

    if (loading) return <p>Loading available Game Versions</p>

    const contextValue = {
        versions: data.versions
    }

    return (
        <GameVersionsContext.Provider value={contextValue}>
            {children}
        </GameVersionsContext.Provider>
    )
}

export const gameVersionsQuery = gql`
query GameVersionsQuery {
  versions{
    game
    id
    name
  }
}`

export { useGameVersions, GameVersionsProvider }