import React, { Fragment } from "react";
import { Tooltip } from "react-tippy";
import { cloudinaryUrl } from "../services/common";

export const FlamingAttackTooltip = (props) => (
  <Fragment>
    <p style={{ fontWeight: "bold" }}>{`Flaming Attack`}</p>
    {/* https://steamcommunity.com/app/364360/discussions/0/1470841715951469948/ */}
    {/* https://www.reddit.com/r/totalwar/comments/4z7tt3/explanation_of_fire_damage_in_warhammer/ */}
    <p>Damage reduced/increased by Fire Resistance/Weakness</p>
    <p>
      Good against units with regeneration (because most (all?) regeneration
      abilities include a "weakness to fire" effect)
    </p>
  </Fragment>
);

export const FlamingAttackIconOnly = (props) => (
  <img
    alt={"Flaming Attack"}
    src={`${cloudinaryUrl()}/api/${
      process.env.REACT_APP_TWWSTATS_LATEST_VERSION
    }/ui/skins/default/modifier_icon_flaming.webp`}
    style={{ verticalAlign: "sub" }}
  />
);

export default (props) => {
  if (!props.value) {
    return null;
  }

  return (
    <Tooltip
      position="top"
      arrow="true"
      style={{ display: "inline" }}
      html={
        <div
          style={{
            fontFamily: '"Open Sans", sans-serif',
            padding: "24px 4px 4px",
          }}
        >
          <FlamingAttackTooltip />
        </div>
      }
    >
      <FlamingAttackIconOnly />
    </Tooltip>
  );
};
