import React, { Component, Fragment } from "react";
import { ApolloProvider } from "react-apollo";
import FontAwesome from "react-fontawesome";
import Modal from "react-modal";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import "react-tippy/dist/tippy.css";
import "./App.css";
import AbilitiesCards from "./components/AbilitiesCards";
import Abilities from "./components/abilities-grid";
import AdSense from "./components/AdSense";
import CampaignDifficulties from "./components/campaign-difficulties-grid";
import Changelogs from "./components/changelogs";
import CharacterTraitLevels from "./components/character-trait-levels-grid";
import FatigueEffects from "./components/fatigue-effects-grid";
import GoogleAnalytics from "./components/ga-pageviews";
import Help from "./components/help";
import InfoAndMechanics from "./components/info-and-mechanics";
import Rules from "./components/kv-grid";
import Menu from "./components/menu";
import Settings from "./components/settings";
import SimpleDialog from "./components/simple-dialog";
import SimplePage from "./components/simple-page";
import UnitsCards from "./components/units-cards";
import Units from "./components/units-grid";
import { GameVersionsProvider } from "./context/game-versions-context";
import client from "./services/apollo-client";
import {
  globalSettings,
  LOCAL_STORAGE_SETTINGS_KEY,
} from "./services/twwstats-services";

const frontend_version = process.env.REACT_APP_TWWSTATS_FRONTEND_VERSION;
const enableAdds = process.env.REACT_APP_ENABLE_ADDS;

class App extends Component {
  componentDidMount() {
    this.client = client;
    this.batch = { batch: true };
    this.setState({
      ready: true,
      showHelp: false,
      showSettings: false,
      showMenu: false,
      settings: globalSettings(),
    });
  }

  setSetting = (key, value) => {
    let settings = this.state.settings || {};
    settings[key] = value;
    window.localStorage.setItem(
      LOCAL_STORAGE_SETTINGS_KEY,
      JSON.stringify(settings)
    );
    this.setState({
      settings,
    });
  };

  showMenu = () => this.setState({ showMenu: true });
  hideMenu = () => this.setState({ showMenu: false });

  showHelp = () => this.setState({ showHelp: true });
  hideHelp = () => this.setState({ showHelp: false });

  showSettings = () => this.setState({ showSettings: true });
  hideSettings = () => this.setState({ showSettings: false });

  render() {
    return this.client ? (
      <ApolloProvider client={this.client}>
        <GameVersionsProvider>
          <Router>
            <Fragment>
              <div className="App">
                <header className="Header">
                  <FontAwesome
                    className="btn"
                    title="Menu"
                    name="bars"
                    onClick={this.showMenu}
                    style={{ marginRight: "36px" }}
                  ></FontAwesome>
                  <Switch>
                    <Route
                      path="/unitscards"
                      render={({ match }) => (
                        <h1>
                          Units Cards{" "}
                          <span
                            className="clickable"
                            onClick={this.showSettings}
                          >{`(${this.state.settings.unit_size})`}</span>
                        </h1>
                      )}
                    />
                    <Route
                      path="/units"
                      render={({ match }) => (
                        <h1>
                          Units Grid{" "}
                          <span
                            className="clickable"
                            onClick={this.showSettings}
                          >{`(${this.state.settings.unit_size})`}</span>
                        </h1>
                      )}
                    />
                    <Route
                      path="/character_traits"
                      render={({ match }) => <h1>Warlord Traits Grid</h1>}
                    />
                    <Route
                      path="/kv/:table_name"
                      render={({ match }) => (
                        <h1>_kv_{match.params.table_name}</h1>
                      )}
                    />
                    <Route
                      path="/changelogs"
                      render={() => <h1>Changelogs</h1>}
                    />
                    <Route
                      path="/info-and-mechanics"
                      render={() => <h1>Info & Mechanics</h1>}
                    />
                    <Route
                      path="/abilities"
                      render={() => (
                        <h1>
                          Abilities Grid{" "}
                          <span
                            className="clickable"
                            onClick={this.showSettings}
                          >{`(${this.state.settings.unit_size})`}</span>
                        </h1>
                      )}
                    />
                    <Route
                      path="/abilitiescards"
                      render={() => (
                        <h1>
                          Abilities Cards{" "}
                          <span
                            className="clickable"
                            onClick={this.showSettings}
                          >{`(${this.state.settings.unit_size})`}</span>
                        </h1>
                      )}
                    />
                    <Route
                      path="/fatigueeffects"
                      render={() => <h1>Fatigue Effects</h1>}
                    />
                    <Route
                      path="/campaigndifficulties"
                      render={() => <h1>Campaign Difficulties</h1>}
                    />
                    <Route path="/" render={() => <h1>TWW Stats</h1>} />
                  </Switch>
                  <a
                    href="https://www.patreon.com/twwstats"
                    rel="noopener noreferrer"
                    title="Support twwstats on Patreon!"
                    target="_blank"
                    className="btn"
                    style={{ padding: "4px", width: "32px", height: "32px" }}
                  >
                    <img
                      height={32}
                      width={32}
                      src="./images/Patreon_Mark_White.png"
                      alt="Patreon"
                    />
                  </a>
                  <FontAwesome
                    className="btn"
                    title="Help"
                    name="question"
                    onClick={this.showHelp}
                  ></FontAwesome>
                  <FontAwesome
                    className="btn"
                    title="Settings"
                    name="cog"
                    onClick={this.showSettings}
                  ></FontAwesome>
                </header>
                <div
                  className="Content"
                  style={{ placeSelf: "stretch stretch" }}
                >
                  <Switch>
                    <Route
                      path="/unitscards"
                      render={(props) => (
                        <UnitsCards {...props} settings={this.state.settings} />
                      )}
                    />
                    <Route
                      path="/character_traits"
                      render={(props) => (
                        <CharacterTraitLevels
                          {...props}
                          client={this.client}
                          settings={this.state.settings}
                        />
                      )}
                    />
                    <Route
                      path="/kv/:table_name"
                      render={(props) => (
                        <Rules
                          {...props}
                          queryVariables={{
                            name: props.match.params.table_name,
                          }}
                          client={this.client}
                        />
                      )}
                    />
                    <Route
                      path="/changelogs"
                      render={() => (
                        <SimplePage>
                          <Changelogs />
                        </SimplePage>
                      )}
                    />
                    <Route
                      path="/info-and-mechanics"
                      render={() => (
                        <SimplePage>
                          <InfoAndMechanics />
                        </SimplePage>
                      )}
                    />
                    <Route
                      path="/abilities"
                      render={(props) => (
                        <Abilities
                          {...props}
                          queryVariables={this.batch}
                          client={this.client}
                          settings={this.state.settings}
                        />
                      )}
                    />
                    <Route
                      path="/abilitiescards"
                      render={(props) => (
                        <AbilitiesCards
                          {...props}
                          settings={this.state.settings}
                        />
                      )}
                    />
                    <Route
                      path="/fatigueeffects"
                      render={(props) => (
                        <FatigueEffects {...props} client={this.client} />
                      )}
                    />
                    <Route
                      path="/campaigndifficulties"
                      render={(props) => (
                        <CampaignDifficulties {...props} client={this.client} />
                      )}
                    />
                    <Route
                      path="/units"
                      render={(props) => (
                        <Units
                          {...props}
                          queryVariables={this.batch}
                          client={this.client}
                          settings={this.state.settings}
                        />
                      )}
                    />
                    <Route
                      path="/"
                      render={() => (
                        <SimplePage>
                          <Menu />
                        </SimplePage>
                      )}
                    />
                  </Switch>
                  <Modal
                    className={{
                      base: "twwstats-modal",
                      afterOpen: "after-open",
                      beforeClose: "before-close",
                    }}
                    closeTimeoutMS={300}
                    ariaHideApp={false}
                    isOpen={this.state.showHelp}
                    onRequestClose={this.hideHelp}
                    contentLabel="Help"
                  >
                    <SimpleDialog onClose={this.hideHelp}>
                      <Help />
                    </SimpleDialog>
                  </Modal>
                  <Modal
                    className={{
                      base: "twwstats-modal",
                      afterOpen: "after-open",
                      beforeClose: "before-close",
                    }}
                    closeTimeoutMS={300}
                    ariaHideApp={false}
                    isOpen={this.state.showSettings}
                    onRequestClose={this.hideSettings}
                    contentLabel="Settings"
                  >
                    <SimpleDialog onClose={this.hideSettings}>
                      <Settings
                        settings={this.state.settings}
                        setSetting={this.setSetting}
                      />
                    </SimpleDialog>
                  </Modal>
                  <Modal
                    className={{
                      base: "twwstats-menu",
                      afterOpen: "after-open",
                      beforeClose: "before-close",
                    }}
                    ariaHideApp={false}
                    isOpen={this.state.showMenu}
                    onRequestClose={this.hideMenu}
                    contentLabel="Menu"
                    closeTimeoutMS={300}
                  >
                    <SimpleDialog onClose={this.hideMenu}>
                      <Menu onClose={this.hideMenu} />
                    </SimpleDialog>
                  </Modal>
                </div>
                <footer className="Footer">
                  {/* <span className="important">
                    IMPORTANT: TWW2 mods have been temporarily removed from the
                    site while I address some bandwidth issues.{" "}
                  </span> */}
                  <Link
                    to="/changelogs"
                    title="Click to view changelogs"
                    id="frontend-version"
                  >
                    {frontend_version}
                  </Link>
                </footer>
                {enableAdds && (
                  <div className="AdSense">
                    <div className="banner banner1">
                      <AdSense
                        requiredWidth={400}
                        requiredHeight={700}
                        hidePortrait
                      >
                        <ins
                          className="adsbygoogle banner banner1"
                          style={{ display: "block" }}
                          data-ad-client="ca-pub-9561797966644837"
                          data-ad-slot="5268990434"
                          data-ad-format="auto"
                          data-full-width-responsive="true"
                        ></ins>
                      </AdSense>
                    </div>
                    <div className="banner banner2">
                      <AdSense
                        requiredWidth={970}
                        requiredHeight={700}
                        hidePortrait
                      >
                        <ins
                          className="adsbygoogle banner banner2"
                          style={{ display: "block" }}
                          data-ad-client="ca-pub-9561797966644837"
                          data-ad-slot="1769439560"
                          data-ad-format="auto"
                          data-full-width-responsive="true"
                        ></ins>
                      </AdSense>
                    </div>
                    <div className="banner banner3">
                      <AdSense
                        requiredWidth={1940}
                        requiredHeight={700}
                        hidePortrait
                      >
                        <ins
                          className="adsbygoogle banner banner3"
                          style={{ display: "block" }}
                          data-ad-client="ca-pub-9561797966644837"
                          data-ad-slot="9801013418"
                          data-ad-format="auto"
                          data-full-width-responsive="true"
                        ></ins>
                      </AdSense>
                    </div>
                  </div>
                )}
              </div>
              <GoogleAnalytics />
            </Fragment>
          </Router>
        </GameVersionsProvider>
      </ApolloProvider>
    ) : (
      <span>Initializing ApolloClient</span>
    );
  }
}

export default App;
