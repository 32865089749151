import gql from "graphql-tag";
import ContactPhaseFragment from "./contactphase-fragment";
import ProjectileFragment from "./projectile-fragment";
import ExplosionFragment from "./explosion-fragment";
import AbilityOverpowerTooltipFragment from "./ability-overpower-tooltip-fragment";

export default gql`
  fragment ability on ability {
    key
    supercedes_ability
    requires_effect_enabling
    icon_name
    is_unit_upgrade
    is_hidden_in_ui
    name
    tooltip
    uniqueness
    additional_ui_effects {
      localised_text
      sort_order
    }
    overpower_option {
      ...ability_overpower
    }
    type {
      key
      icon
      onscreen_name
    }
    unit_special_ability {
      key
      active_time
      recharge_time
      num_uses
      effect_range
      affect_self
      num_effected_friendly_units
      num_effected_enemy_units
      initial_recharge
      target_friends
      target_enemies
      target_ground
      target_intercept_range
      assume_specific_behaviour
      clear_current_order
      wind_up_time
      passive
      invalid_targets
      invalid_usages
      update_targets_every_frame
      auto_deactivate_flags {
        flag_key
        flag_description
        alt_description
      }
      activated_projectile {
        ...projectile
      }
      bombardment {
        arrival_window
        bombardment_key
        num_projectiles
        radius_spread
        start_time
        launch_source
        launch_height
        launch_height_underground
        projectile_type {
          ...projectile
        }
      }
      spawned_unit {
        key
        short_description_text
        onscreen_name
        variant {
          variant
          name
          unit
          unit_card_url
        }
      }
      wind_up_stance
      mana_cost
      min_range
      vortex {
        vortex_key
        change_max_angle
        damage
        damage_ap
        duration
        expansion_speed
        goal_radius
        infinite_height
        move_change_freq
        movement_speed
        start_radius
        ignition_amount
        is_magical
        detonation_force
        launch_source
        building_collision
        height_off_ground
        delay
        num_vortexs
        contact_effect {
          ...contact_phase
        }
      }
      miscast_chance
      miscast_explosion {
        ...explosion
      }
      # ai_usage
      additional_melee_cp
      additional_missile_cp
      parent_ability {
        key
      }
    }
    phases {
      ...contact_phase
    }
    special_ability_groups {
      ability_group
      icon_path
      special_edition_mask
      sort_order
      is_naval
      colour_r
      colour_g
      colour_b
      button_name
    }
  }
  ${ContactPhaseFragment}
  ${ProjectileFragment}
  ${ExplosionFragment}
  ${AbilityOverpowerTooltipFragment}
`;
