import Phase from "./phase";

import buildModel from '../services/buildModel';

export default function Vortex(data, tww_version, cuc) {
    if(!data) return null

    this.tww_version = tww_version;

    this.change_max_angle = data.change_max_angle;
    this.damage = data.damage;
    this.damage_ap = data.damage_ap;
    this.duration = data.duration;
    this.expansion_speed = data.expansion_speed; // In m/s, affects knockback effect
    this.goal_radius = data.goal_radius; // in m
    this.infinite_height = data.infinite_height;
    this.move_change_freq = data.move_change_freq;
    this.movement_speed = data.movement_speed;
    this.start_radius = data.start_radius;
    this.vortex_key = data.vortex_key;
    this.ignition_amount = data.ignition_amount;
    this.is_magical = data.is_magical;
    this.detonation_force = data.detonation_force;
    this.launch_source = data.launch_source;
    this.building_collision = data.building_collision;
    this.height_off_ground = data.height_off_ground;
    this.delay = data.delay;
    this.num_vortexs = data.num_vortexs;
    this.phase = buildModel(Phase, data.contact_effect, tww_version, cuc);
}
